import { reducer as formReducer } from 'redux-form';
import { debug } from './debug';
import user from './user';
import common from './common';
import network from "./network";
import course from './course';
import category from './category';
import wishlist from './wishlist';
import cached from './cached';
import failed from './failed';
import pins from './pins';
import subscription from './subscription';

/*
 * Note:
 *
 * shaderCacheReducer implements cache support for Types.CACHE_PROPERTY
 * among selected reducers:
 *
 * it has to add handler to the reducer:
 *   case Types.CACHE_PROPERTY + 'name': return sharedCacheReducer(state, action);
 *
 * They must also define state name value, which should not change.
 */

const rootReducer = {
  form: formReducer,
  user,
  debug,
  common,
  network,
  course,
  category,
  wishlist,
  cached,
  failed,
  pins,
  subscription,
};

export default rootReducer;
