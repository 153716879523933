import React, { PureComponent } from 'react';
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Keyboard, Linking, Platform,
} from 'react-native';
import {Alert, BackHandler} from "proxy";
import { withTranslation } from 'react-i18next';
import { Images } from 'app-assets';
import { HttpAPI } from 'app-api';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'proxy';
import styles from './styles';
import { setLoading } from '../../actions/common';
import {Routines} from "../../common";
import {Colors} from 'app-assets';
import {getViaWebLoginUrl, getDeepLink, VALUES} from "../../../setup/config";
import {InAppBrowser} from "react-native-inappbrowser-reborn";
import {CommonAppLayout, ContentMiddleLayout} from "../../component";
const isWeb = Platform.OS === "web";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
      isActivelyWaitingThirdParty: false,
    };

    const url = getViaWebLoginUrl('test');
    this.enabledThirdPartyLogin = typeof url === "string" && url.length > 0;
    this.processingThidParty = false;
  }

  async componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);

    // const { route, dispatch } = this.props
    // if (route.params?.status === 'success' && route.params?.token) {
    //   this.evaluateThirdPartyLoginSafely();
    // }
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }

  handleBackPress = () => {
    this.onBack();
    return true;
  };

  onRegister = () => {
    this.autoNavigate('RegisterScreen');
  };

  onLogin = async () => {
    const { t, dispatch } = this.props;
    Keyboard.dismiss();
    const { username, password } = this.state;
    if (!username || (typeof username !== "string") || username.length === 0) {
      Alert.alert('', t('loginScreen.usernameEmpty'));
      return;
    }
    if (!password || (typeof password !== "string") || password.length === 0) {
      Alert.alert('', t('loginScreen.passwordEmpty'));
      return;
    }

    dispatch(setLoading(true));

    try {
      const params = {
        username: username.trim(),
        password: password.trim(),
      };
      const response = await HttpAPI.login(params).then(r => r?.responseData);

      //todo response data is null if failed (!=200) we throw error info and messages away, fix -> misleading....
      if (response?.token) {
        await Routines.afterLogin(this.props, response);
        this.navigateAfterLogin();
      } else if (response?.code.includes('incorrect_password')) {
        this.alertWithNavigationOption('loginScreen.passwordNotCorrect',
            'loginScreen.invalidCredentials', 'ForgotScreen', 'loginScreen.forgotPassword');
      } else if (response?.code.includes('invalid_username')) {
        this.alertWithNavigationOption('loginScreen.usernameNotCorrect',
            'loginScreen.invalidCredentials', 'ForgotScreen', 'loginScreen.forgotPassword');
      } else {
        this.alertWithNavigationOption('loginScreen.notFound',
            'loginScreen.userNotFound');
      }
    } catch (e) {
      console.warn('Login aborted!', e);
      this.alertWithNavigationOption('loginScreen.notFound', 'loginScreen.userNotFound');
    } finally {
      dispatch(setLoading(false));
    }
  };

  alertWithNavigationOption(titleKey, descriptionKey, navigation='RegisterScreen', navigationText='register') {
    const { t } = this.props;
    Alert.alert(t(titleKey), t(descriptionKey, {domain: VALUES.DOMAIN_NAME}), [
      {
        text: t('alert.cancel'),
        onPress: () => {},
        style: 'cancel',
      },
      {
        text: t(navigationText),
        onPress: async () => this.autoNavigate(navigation),
      }
    ]);
  }

  async evaluateThirdPartyLogin() {
    if (this.processingThidParty) return;
    this.processingThidParty = true;

    try {
      const params = this.props.route?.params;
      if (params?.status === 'success' && params?.token) {
        const {navigation, dispatch} = this.props

        console.debug('Authentication attempt', params);

        const data = {...params};
        delete data.status;

        await Routines.afterLogin(this.props, data);
        this.setState({
          isActivelyWaitingThirdParty: false
        });

        // this.navigateAfterLogin();
        this.autoGoHome();
      } else {
        console.debug('Did not initiate. Data', params);
      }
    } catch (e) {
      console.warn('Login aborted!', e);
      Alert.alert(t('loginScreen.notFound'), t('loginScreen.userNotFound', {domain: VALUES.DOMAIN_NAME}));
    } finally {
      this.processingThidParty = false;
      this.props.dispatch(setLoading(false));
    }
  }

  async onThirdPartyLogin() {
    const deepLink = getDeepLink('third-party-login');
    const {t} = this.props;
    const url = getViaWebLoginUrl(deepLink),
         self = this;
    try {
      if (await InAppBrowser.isAvailable()) {
        InAppBrowser.openAuth(url, deepLink, {
          // iOS Properties
          ephemeralWebSession: false,
          // Android Properties
          showTitle: false,
          enableUrlBarHiding: true,
          enableDefaultShare: false
        }).then((response) => {
          console.debug('ThirdParty Session',response, ', coming from', deepLink);

          if (response.type === 'success' && response.url) {
            self.setState({
              isActivelyWaitingThirdParty: true
            });
            Linking.openURL(response.url);
          } else {
            Alert.alert(t('loginScreen.failedThirdPartyLogin', {domain: VALUES.DOMAIN_NAME}));
          }

        }).catch(e => {
          console.warn('InAppBrowser Failure', e);
          Alert.alert(t('loginScreen.failedThirdPartyLogin', {domain: VALUES.DOMAIN_NAME}));
        }); //todo catch?
      } else Linking.openURL(url);
    } catch (error) {
      Linking.openURL(url);
    }
  }

  navigateAfterLogin() {
    const { navigation, route } = this.props;

    if (route.params?.screen) {
      if (
          route.params?.screen === 'CoursesDetailsScreen' &&
          route.params?.courseId
      ) {
       this.autoReplace('CoursesDetailsScreen', {
         courseId: route.params.courseId,
       });
      } else {
        this.autoReplace(route.params.screen);
      }
    } else {
      this.autoGoHome();
    }
  }

  onBack = () => {
    this.autoGoBack();
  };

  evaluateThirdPartyLoginSafely() {
    const {dispatch} = this.props;
    dispatch(setLoading(true));
    this.evaluateThirdPartyLogin().catch(e => {
      // Show error and redirect the user to the Login page
      console.error('ThirdParty Evaluation Error', e);
      Alert.alert(t('loginScreen.failedThirdPartyLogin', {domain: VALUES.DOMAIN_NAME}));
      dispatch(setLoading(false));
      this.setState({
        isActivelyWaitingThirdParty: false
      });
    });
  }

  render() {
    const { username, password, isActivelyWaitingThirdParty } = this.state;
    const { t, navigation, route } = this.props;

    if (isActivelyWaitingThirdParty) {
     this.evaluateThirdPartyLoginSafely();
    }

    return (
      <CommonAppLayout
          edges={['right', 'left', 'top']}
          color={Colors.scholastik.white}>
        <KeyboardAwareScrollView
            contentContainerStyle={styles.container}
            keyboardShouldPersistTaps="always"
            showsVerticalScrollIndicator={Platform.OS === "web"}
        >
          <ScrollView
              showsVerticalScrollIndicator={false}
              bounces={false}
              keyboardShouldPersistTaps="handled"
          >
            <ContentMiddleLayout containerStyle={{ paddingHorizontal: 46,
              marginTop: 35, marginBottom: 80, height: "auto"}}>
              <TouchableOpacity
                  style={{ marginLeft: 10, width: 50, position: "absolute" }}
                  onPress={this.onBack}
                  hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
              >
                <Image source={Images.iconBack} style={styles.iconBack} />
              </TouchableOpacity>
              <View style={styles.viewLogo}>
                <Image source={Images.LogoSchool} style={styles.logo} />
                <Text style={styles.title}>{t('loginScreen.title')}</Text>
              </View>
              {
                  route.params?.message && (
                      <Text style={styles.loginMessage}>{route.params?.message}</Text>
                  )
              }
              <View
                  style={[
                    styles.viewInput,
                    username.length > 0
                        ? { borderWidth: 2, borderColor: '#000' }
                        : {},
                  ]}
              >
                <TextInput
                    ref={(ref) => {
                      this.username = ref;
                    }}
                    placeholder={t('loginScreen.usernamePlaceholder')}
                    placeholderTextColor="#9E9E9E"
                    style={[styles.textInput, isWeb && {outlineStyle: 'none'}]}
                    autoCapitalize="none"
                    autoCorrect={false}
                    onChangeText={(value) => this.setState({ username: value })}
                />
                {username.length > 0 && (
                    <Image source={Images.icEnterUsername} style={styles.icEnter} />
                )}
              </View>
              <View
                  style={[
                    styles.viewInput,
                    password.length > 0
                        ? { borderWidth: 2, borderColor: '#000', marginTop: 0 }
                        : { marginTop: 0 },
                  ]}
              >
                <TextInput
                    ref={(ref) => {
                      this.password = ref;
                    }}
                    textContentType="password"
                    autoCapitalize="none"
                    autoCorrect={false}
                    secureTextEntry={!this.state.showPassword}
                    placeholder={t('loginScreen.passwordPlaceholder')}
                    placeholderTextColor="#9E9E9E"
                    style={[styles.textInput, isWeb && {outlineStyle: 'none'}]}
                    value={password}
                    onChangeText={(value) => this.setState({ password: value })}
                />
                {password.length > 0 && (
                    <TouchableOpacity
                        onPress={() =>
                            this.setState({ showPassword: !this.state.showPassword })
                        }
                    >
                      <Image
                          source={Images.icEnterPassword}
                          style={styles.icEnter}
                      />
                    </TouchableOpacity>
                )}
              </View>

              <TouchableOpacity style={styles.btnSubmit} onPress={this.onLogin}>
                <Text style={styles.txtSubmit}>{t('loginScreen.btnLogin')}</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.btnRegister} onPress={this.onRegister}>
                <Text style={[styles.txtSubmit, {color: Colors.scholastik.purple}]}>{t('register')}</Text>
              </TouchableOpacity>


              {
                  this.enabledThirdPartyLogin &&
                  // <TouchableOpacity style={styles.btnApple}
                  //                                                onPress={this.onThirdPartyLogin.bind(this)}
                  // >
                  //   <View style={{flexDirection: "row", alignItems: "baseline"}}>
                  //     <IconM name={"google"} style={{paddingHorizontal: 2}}
                  //            size={19}
                  //            color={Colors.scholastik.darkGrayText}/>
                  //     <IconM name={"apple"} style={{paddingHorizontal: 2}}
                  //            size={25}
                  //            color={Colors.scholastik.darkGray}/>
                  //     <IconM name={"facebook"} style={{paddingHorizontal: 2}}
                  //            size={19.5}
                  //            color={Colors.other.facebook}/>
                  //   </View>
                  //   <Text style={{
                  //     fontFamily: "Poppins",
                  //     fontSize: 15, marginTop: 10, textAlign: "center",
                  //     color: Colors.scholastik.darkGrayText,
                  //     marginHorizontal: 15
                  //   }}>
                  //     {t('loginScreen.fuckinApple', {domain: VALUES.DOMAIN_NAME})}
                  //   </Text>
                  // </TouchableOpacity>

                  <TouchableOpacity style={styles.btnThirdPartyLoginSmall} onPress={this.onThirdPartyLogin.bind(this)}>
                    <Text style={styles.txtForgot}>{t('loginScreen.thirdPartyLogin')}</Text>
                  </TouchableOpacity>
              }


              {/*<TouchableOpacity*/}
              {/*    onPress={this.onThirdPartyLogin.bind(this)}*/}
              {/*>*/}
              {/*  <Text style={styles.txtForgot}>*/}
              {/*    {t('loginScreen.thirdPartyLoginScreen')}*/}
              {/*  </Text>*/}
              {/*</TouchableOpacity>*/}

              <TouchableOpacity
                  onPress={() => this.autoNavigate('ForgotScreen')}
              >
                <Text style={styles.txtForgot}>
                  {t('loginScreen.forgotPassword')}
                </Text>
              </TouchableOpacity>
              {/* <View style={styles.viewLine}>
              <View style={styles.line} />
              <Text>or</Text>
              <View style={styles.line} />
            </View>
            <View style={styles.viewButton}>
              <TouchableOpacity>
                <Image
                  source={Images.iconFacebook}
                  style={styles.iconFacebook}
                />
              </TouchableOpacity>
              <TouchableOpacity>
                <Image source={Images.iconTwitter} style={styles.iconTwitter} />
              </TouchableOpacity>
              <TouchableOpacity>
                <Image source={Images.iconGoogle} style={styles.iconGoogle} />
              </TouchableOpacity>
            </View> */}

            </ContentMiddleLayout>
          </ScrollView>
        </KeyboardAwareScrollView>
      </CommonAppLayout>
    );
  }
}
const mapStateToProps = ({ network, user }) => ({
  network, user
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
