import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { memo } from "react"
import {View} from "react-native";

export const SvgUnchecked = memo((props) => (
    <View style={{width: 35, minWidth: 35}}>
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={25}
            viewBox="3 3 38.26 33"
            style={{width: 35}}
            {...props}
        >
            <Path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19ZM31.2 5.1c1.6.9 3.142.42 3.11 4.315C34.27 14.422 34 13.5 34 21.7 34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1Z" />
            <Path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5s.373-8.758.19-8.695C29.023 9.862 30.7 9 19 9H8.9l.3 10.2Z" />
        </Svg>
    </View>
))

export const SvgChecked = memo((props) => (
    <View style={{width: 35, minWidth: 35}}>
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={25}
            viewBox="3 3 38.26 33"

            {...props}
        >
            <Path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19zM39 4.5c1 1.2.7 2.1-1.9 5-3 3.4-3.1 4-3.1 12.2C34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1 1.6.9 2.4.7 3.6-.5 2-2 2.6-2 4.2-.1z" />
            <Path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5-3.8 4.4c-2.1 2.4-4.3 4.2-4.8 4C18.8 26.2 10 16.4 10 15.3c0-.6.6-1.6 1.4-2.2 1.2-1 2.2-.5 5.2 2.9l3.7 4.1 4.4-4.3C31.1 9.5 30.7 9 19 9H8.9l.3 10.2z" />
        </Svg>
    </View>
))
