/* eslint-disable import/prefer-default-export */
import Types from './types';

export const saveCategory = (data: Object) => ({
  type: Types.SAVE_CATEGORY,
  data,
});


export const setSelectedCategory = (slug: string) => ({
  type: Types.SELECT_DEFAULT_CATEGORY,
  slug,
});

