import * as React from 'react';
import {Colors, Images} from "../../setup/assets";
import {Image, TouchableOpacity, View, Text, ScrollView, StyleSheet, ImageBackground, Dimensions, findNodeHandle } from "react-native";
import {TallHeader} from "./commonAppLayout";
import {safeReadMeta} from "../common";
import {Events} from "../common/util";


class CategoryHeaderImageBg extends React.Component {
    render() {
        const item = this.props.item || {};

        const imageUrl = safeReadMeta(item.meta, 'image', undefined);
        const hasImage = imageUrl && imageUrl !== '';
        const image = hasImage ? { uri: imageUrl } : undefined;
        const itemPremiumRequiredList = item.meta?.subscription;
        const hasDescription = item.description !== '';

        // Determine screen width
        const screenWidth = Dimensions.get('window').width;

        // Adjust the aspect ratio and container width based on screen width
        const aspectRatio = screenWidth > 580 ? 21 / 9 : 16 / 9;

        return (
            <View style={[styles.container, { position: 'relative' }]}>
                {this.props.item && (
                    <View>
                        {/* Blurred background image */}
                        <ImageBackground
                            source={image}
                            style={styles.imageBackground}
                            blurRadius={15} // Add blur to the background image
                        >
                            {/* Main image with correct aspect ratio */}
                            <Image
                                source={image}
                                style={[styles.mainImage, { aspectRatio }]}
                            />
                            <Image
                                source={Images.backgroundBanner}
                                style={styles.bannerImage}
                            />
                        </ImageBackground>
                    </View>
                )}
            </View>
        );
    }
}



class CategoryHeader extends React.Component {

    constructor(props) {
        super(props);

        if (!this.props.onSelect) {
            console.warn("onSelect handler not defined!");
        }

        this.scrollViewRef = React.createRef();
        this.categoryRefs = {};
        for (let category of (this.props.categories || [])) {
            this.categoryRefs[category.slug] = React.createRef();
        }
    }

    componentDidMount() {
        const {navigation, user} = this.props, self = this;
        Events.onCustom(this, 'navigationFocus', () =>
            navigation.addListener('focus', () => this.scrollToTarget()));
    }

    componentWillUnmount() {
        Events.off(this, 'navigationFocus');
    }

    scrollToTarget = (slug) => {
        const target = this.categoryRefs[slug || this.props.selectedCategory];
        console.log("TARGET", slug, this.props.selectedCategory)
        if (!target) return;
        console.log("GO")
        const scrollViewNode = findNodeHandle(this.scrollViewRef.current);
        const targetNode = findNodeHandle(target.current);
        const windowWidth = Dimensions.get('window').width;

        if (scrollViewNode && targetNode) {
            target.current.measureLayout(
                scrollViewNode,
                (x, y, width, height) => {
                    // Calculate the offset to center the element horizontally
                    const offsetX = x - (windowWidth / 2) + (width / 2);

                    this.scrollViewRef.current.scrollTo({
                        x: offsetX,
                        y: 0, // No vertical scrolling
                        animated: true,
                    });
                },
                (error) => console.log(error)
            );
        }
        this.setState({
            selectedCategory: slug
        });
    };

    render() {
        this.selectedCategory = this.props.selectedCategory;

        const categories = this.props.categories || [];
        const active = categories.find(c => c.slug === this.selectedCategory);
        return (
            <TallHeader
                backgroundProp={
                    <CategoryHeaderImageBg item={active}></CategoryHeaderImageBg>
                }
                onBack={this.props.onBack}
                goToHomePage={this.props.goToHomePage}
            >
                <Text numberOfLines={2} style={[styles.title, {marginHorizontal: 25}]}>
                    {this.props.title || "Title"}
                </Text>

                <ScrollView
                    ref={this.scrollViewRef}
                    style={styles.menu}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{paddingHorizontal: 25}}
                    horizontal={true}>
                    {
                        categories.map((pinCategory, i) => {
                            const active = pinCategory.slug === this.selectedCategory;
                            return <TouchableOpacity
                                ref={this.categoryRefs[pinCategory.slug]}
                                style={styles.btnTopMenu}
                                onPress={() => {
                                    this.scrollToTarget(pinCategory.slug);
                                    this.selectedCategory = pinCategory.slug;
                                    this.props.onSelect && this.props.onSelect(pinCategory.slug);
                                }}
                                key={i}
                                hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
                            >
                                <Text
                                    style={[
                                        styles.btnMenuText,
                                        {color: active ? Colors.scholastik.white : Colors.scholastik.darkGray},
                                    ]}
                                    numberOfLines={1} ellipsizeMode="tail"
                                >
                                    {pinCategory.name || "???"}
                                </Text>
                                {active && <View style={styles.lineButton}/>}
                            </TouchableOpacity>
                        })
                    }
                </ScrollView>
            </TallHeader>
        );
    }
}

const styles = StyleSheet.create({
    menu: {
        flexDirection: 'row',
        alignSelf: 'center',
    },
    btnTopMenu: {
        marginHorizontal: 10,
    },
    btnMenuText: {
        fontFamily: 'Poppins-Medium',
        fontWeight: '700',
        fontSize: 20,
        textTransform: 'uppercase',
        lineHeight: 24,
        marginTop: 18,
        paddingBottom: 8,
        paddingHorizontal: 5,
        maxWidth: 150
    },
    lineButton: {
        height: 3,
        width: '100%',
        backgroundColor: Colors.scholastik.background,
    },
    title: {
        fontFamily: 'Poppins-Medium',
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 36,
        color: Colors.scholastik.white,
        marginBottom: 20
    },

    container: {
        width: '100%',
        alignSelf: 'center',
        overflow: 'hidden',
    },
    imageBackground: {
        width: '100%',
        backgroundColor: Colors.scholastik.white,
        borderRadius: 6,
        overflow: 'hidden', // Ensure the content is clipped to the border radius
        justifyContent: 'center',
        alignItems: 'center',
    },
    mainImage: {
        width: '100%',
        borderRadius: 6,
        maxWidth: 750,
    },
    bannerImage: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '70%',
        borderRadius: 6,
    },
});


export {
    CategoryHeader
};
