import {createStackNavigator} from "@react-navigation/stack";
import React from "react";
import Home from "../../screens/home";
import CourseRouter from "./course/course-router";

const SubjectsStack = createStackNavigator();
export default function SubjectsRouter() {
    return (
        <SubjectsStack.Navigator
            initialRouteName="Subjects"
            screenOptions={{
                cardStyle: {flex: 1},
                gesturesEnabled: false,
                headerShown: false,
                header: null,
            }}>
            <SubjectsStack.Screen name="Subjects" component={Home} options={{headerShown: false, title: 'Kurzy'}} />
            <SubjectsStack.Screen name="CourseStackScreen" component={CourseRouter} options={{headerShown: false, title: 'Kurzy'}} />
        </SubjectsStack.Navigator>
    );
}