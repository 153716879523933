import { StyleSheet } from 'react-native';
import {Colors} from 'app-assets';

export default StyleSheet.create({
  header: {
    marginTop: 20,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Poppins',
    flex: 1,
  },
  iconHome: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },

  title: {
    fontSize: 28,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
  text: {
    fontSize: 15,
    color: Colors.scholastik.textPrimary,
  },
  fullname: {
    fontSize: 16,
    color: Colors.scholastik.textPrimary,
    lineHeight: 24,
    marginLeft: 7,
    fontWeight: '500',
    fontFamily: 'Poppins-Medium',
  },
  email: {
    fontSize: 13,
    color: '#929292',
    lineHeight: 19,
    fontFamily: 'Poppins',
  },
  overview: {
    paddingHorizontal: 30,
    paddingVertical: 20,
    borderRadius: 24,
    backgroundColor: Colors.scholastik.white,
    marginTop: 25,
    marginHorizontal: 16,
    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  overTitle: {
    fontSize: 14,
    color: Colors.scholastik.textPrimary,
    lineHeight: 21,
    fontFamily: 'Poppins',
  },
  viewItem: {
    flexDirection: 'row',
    marginBottom: 22,
    alignItems: 'center',
  },
  iconItem: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    marginRight: 13,
  },
  txtItem: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 10,
  },
  line: {
    marginTop: 4,
    width: '90%',
    height: 6,
    borderColor: '#000',
    borderWidth: 1,
  },
  progress: {
    height: 4,
  },
  subscriptionItem: {
    marginHorizontal: 16,
    marginTop: 12,
    marginBottom: 20,
    borderRadius: 7,
    backgroundColor: Colors.scholastik.green,
    position: "relative",
    flex: 1,
    shadowColor: Colors.scholastik.darkGray,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  txt1: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#929292',
  },
  viewList: {
    // paddingHorizontal: 16,
  },
  titleList: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 16,
    marginVertical: 25,
  },
  loginRegister: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loginRegisterText: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
  },
  loginRegisterIcon: {
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginHorizontal: 5,
  },
  titleDetails: {
    fontFamily: 'Poppins-Medium',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 36,
    marginRight: 24,
    flex: 1,
    textAlign: 'center',
    paddingBottom: 12,
    color: Colors.scholastik.white,
  },
  viewFilter: {
    backgroundColor: Colors.scholastik.white,
    // width: 64,
    height: 24,
    paddingHorizontal: 16,
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',

    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  txtFilter: {
    fontFamily: 'Poppins',
    fontSize: 10,
    lineHeight: 15,
    marginRight: 4,
  },
  viewModalFilter: {
    width: 127,
    // height: 131,
    paddingHorizontal: 18,
    paddingVertical: 16,
    borderRadius: 6,
    backgroundColor: Colors.scholastik.white,
    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  txtFilterItem: {
    paddingBottom: 10,
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#A9A9A9',
  },
  txtSearch: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#939393',
    flex: 1,
  },
  txtItemFilter: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#858585',
  },
});
