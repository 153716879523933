import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AppNavigator, {getAppLinker, mockAutoNavigation, navigationRef} from '../navigations';
import ErrorWrapper from "./errorWrapper";
import {NavigationContainer, DefaultTheme, CommonActions} from '@react-navigation/native';
import {setLoading, setShouldRedirect} from "../actions/common";
import Loading from "../component/common/loading";
import {getDeepLink, VALUES} from "../../setup/config";
import {Platform, Text} from "react-native";
import {Colors} from 'app-assets';
import {Cache, setHttpHandler, responseNok} from 'app-api';
import {store} from '../index';
import { Routines} from "../common";
import {Alert} from "proxy";
import {withTranslation} from "react-i18next";
import {SafeAreaProvider} from "react-native-safe-area-context";

const CustomizedTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: Colors.scholastik.white,
    },
};



class App extends PureComponent {

    constructor(props) {
        super(props);

        this.linker = getAppLinker();

        const _this = this;
        let handled401 = false;
        let handled404 = false;

        setInterval(() => handled404 = true, 120000);

        setHttpHandler(401, async (data, response, request) => {
            // await Routines.logout({dispatch: store.dispatch});
            await Routines.logout(_this.props);

            if (!handled401) {
                handled401 = true;
                Alert.alert(_this.props.t('errors.notLoggedIn.timeout'),
                    _this.props.t('errors.notLoggedIn.youWereLoggedOut'),
                    [{
                        text: _this.props.t('alert.btnLogin'),
                        onPress: () => {
                            handled401 = false;
                            _this.nav('autoNavigate', "LoginScreen",
                                { message: _this.props.t('errors.notLoggedIn.doLogin')});
                        },
                        style: 'cancel',
                    }]);
            }
            return responseNok(data, response, request, "HTTP Unauthorized");
        });
        setHttpHandler(404, async (data, response, request) => {
            if (!handled404) {
                _this.nav('autoNavigate', 'Screen404');
                handled404 = true;
            }
            return responseNok(data, response, request, "HTTP Not Found!");
        });
        setHttpHandler(403, async (data, response, request) => {
            //pages that do not redirect to 404
            if (request.url?.endsWith('token')
                || request.url?.endsWith('register')
                || request.url?.endsWith('subscription')) {
                return responseNok(data, response, request);
            }

            if (!handled404) {
                _this.nav('autoNavigate', 'Screen404', {message: 'Access denied!'});
                handled404 = true;
            }
            return responseNok(data, response, request, "HTTP Access Denied!");
        });
    }

    componentDidMount() {
        // in a browser, we redirect automatically to login since users might
        // be confused if they visit from the main page this app and suddenly loose
        // login status... but do this only when user cannot go back in nav (e.g. first visit,
        // unauthorized users can also browse content and refresh page)
        if (!this.props.user?.token
            && Platform.OS === "web") {

            const alreadyRedirectedOnceLogin = Cache.get('alreadyRedirectedOnceLogin',
                false, this.props, 'cached', VALUES.EXPIRY_TSTAMP.DAY);

            if (!alreadyRedirectedOnceLogin && !navigationRef.canGoBack()) {
                Cache.set('alreadyRedirectedOnceLogin', true, this.props, 'cached', VALUES.EXPIRY_TSTAMP.DAY);
                store.dispatch(setLoading(true));
                setTimeout(() => {
                    this.nav('autoNavigate', 'LoginScreen', {message: this.props.t('loginScreen.websiteNotAutoLoggedIn')});
                    store.dispatch(setLoading(false));
                }, 300);
            }
        }
    }

    nav(navigationType, ...args) {
        mockAutoNavigation(navigationRef, navigationType, ...args);
    }

    render() {

        const { common, dispatch } = this.props;

        if (common.redirectScreen) { //todo remove?
            console.log("Redirect screen:", common.redirectScreen, common.navigationParams);
            this.nav(common.navigationReplace ? 'autoReplace' : 'autoNavigate',
                common.redirectScreen, common.navigationParams || {})
            dispatch(setShouldRedirect(false));
        }

        return (
            <ErrorWrapper style={{ flex: 1, backgroundColor: Colors.scholastik.white }}>
                <NavigationContainer ref={navigationRef}
                                     linking={this.linker}
                                     fallback={<Text>Loading...</Text>}
                                     theme={CustomizedTheme}>
                    <AppNavigator uriPrefix={getDeepLink()} />
                </NavigationContainer>
                {common.loading && <Loading/>}
            </ErrorWrapper>
        );
    }
}

const mapStateToProps = ({ user, cached, common, wishlist, course }) => ({
  user, cached, common, wishlist, course,
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
