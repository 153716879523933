/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, {Component} from 'react';
import {
    Text,
    View,
    Image,
    ScrollView,
    DeviceEventEmitter,
    TextInput,
    FlatList,
    Dimensions, Platform, ActivityIndicator, TouchableOpacity, Linking
} from 'react-native';
import {Alert, BackHandler} from "proxy";

import {withTranslation} from 'react-i18next';
import {HttpAPI, Cache} from 'app-api';
import {Images} from 'app-assets';
import IconI from 'react-native-vector-icons/Ionicons';
import IconF from 'react-native-vector-icons/Feather';
import IconM from 'react-native-vector-icons/MaterialCommunityIcons';
import Modal from 'react-native-modal';
import Accordion from 'react-native-collapsible/Accordion';
import {connect} from 'react-redux';
import { ProgressCircle, RenderDataHTML} from 'app-component';
import styles from './styles';
import {setLoading, showLoading} from '../../actions/common';
import CountDown from '../../component/common/countdown';
import ReviewQuiz from '../../component/item-detail/review-quiz';
import {Colors} from 'app-assets';
import {getQuestionLetter, renderHtml, Routines} from "../../common";
import MathJax from "../../component/common/math-jax";
import Explanation from "../../component/explanation";
import {Events} from "../../common/util";
import {VALUES} from "../../../setup/config";
import InternetConnectionErrorView from "../../component/common/connection-error-view";
import PlainButton from "../../component/common/plainButton";
import ErrorView from "../../component/common/error-view";

import Hint from "../../component/common/hint";
import {SvgChecked, SvgUnchecked} from "../../component/common/svg-checked";
import {deleteQuestionStatus, saveQuestionStatus} from "../../actions/pins";
import {QStatus} from "../../common/util/qStatus";
import {CommonAppLayout, ContentMiddleLayout} from "../../component";
import {saveCourse} from "../../actions/course";
import Comments from "../../component/comments";

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;

const defaultState = {
    isShowMenu: false,
    pageActive: 0,
    data: null,
    activeSections: [],
    activeExplanations: [],
    isLesson: false,
    isQuiz: false,
    isStartQuiz: false,
    isSkipQuizStart: false,
    showReviewQuestionId: undefined,
    dataQuiz: null,
    itemQuestion: null,
    isAssignment: false,
    dataAssignment: null,
    evaluating: false,
    invalidItem: false,
    preventAutoStart: true, //for now we disabled autostarting
    percentil: undefined,
    preview: false,
}

/**
 * This component expects on start certain data, can be retrieved from Client._() server endpoint
 * item - item overview from course section items list
 * index - active page/item index, not used for now
 * questionIndex - active question to show, for quizzes only
 */
class Learning extends Component {
    constructor(props) {
        super(props);
        this.state = defaultState;
        this.item = null;
        this.type = null;
        this.isLessonFetcherActive = false;
        this.itemCheck = [];
        this.tapTime = 0;
        this.autoStartQuiz = false;
        this.lastViewedListCacheMaxSize = 5;
        this.lastViewedList = [];
    }

    updateLastViewedCache() {
        const {data} = this.state, item = this.item;
        const courseData = data?.assigned?.course;

        //do not record as last viewed if assigned course data not available
        if (!courseData) return;
        if (!courseData.name) {
            courseData.name = courseData.title;
        }

        let existingIndex = this.lastViewedList.findIndex(x => x.item.id === item.id);
        if (existingIndex === -1) {
            if (this.lastViewedList.length > this.lastViewedListCacheMaxSize + 1) {
                this.lastViewedList.pop();
            }
            this.lastViewedList.push({item, courseData, timestamp: Date.now()});
        } else {
            this.lastViewedList[existingIndex] = {item, courseData, timestamp: Date.now()};
        }

        this.lastViewedList.sort((x, y) => y.timestamp - x.timestamp);
        Cache.set('lastViewedCourseStackParams', this.lastViewedList,
            this.props, 'cached', VALUES.EXPIRY_TSTAMP.NEVER);
    }

    async componentDidMount() {
        setLoading(false);

        const {route} = this.props;
        //questionIndex, sectionId, sectionType, courseId
        const {sectionType, sectionId, questionIndex, courseId, start} = route.params;

        this.autoStartQuiz = !!start;

        await this.getLesson({
            courseId: courseId,
            type: sectionType,
            id: sectionId,
            questionIndex: questionIndex
        });
        //TODO fuixme
        // await this.setStateAsync({
        //     activeSections: [index || 0],
        // });

        if (!this.state.preview) {
            this.lastViewedList = Cache.get('lastViewedCourseStackParams', this.lastViewedList, this.props,
                'cached', VALUES.EXPIRY_TSTAMP.NEVER);
            if (!Array.isArray(this.lastViewedList)) {
                this.lastViewedList = [];
            }
            this.updateLastViewedCache();
        }

        // this.setState({ question: questionTemp });
        Events.on(this, 'reloadDataRetake', this.onReloadDataRetake);
        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);

        //TODO JUMP
        // if (Number.isInteger(questionIndex)) {
        //     const self = this;
        //     setTimeout(() => {
        //         const index = questionIndex;
        //         delete questionIndex;
        //         self.onJumpQuiz(index, true);
        //     }, 150);
        // }
    }

    get dataLesson() {
        const {course} = this.props;
        let dataTemp = [];
        course.data.sections.forEach((obj) => {
            dataTemp = [...dataTemp, ...obj.items];
        });
        return dataTemp;
    }

    componentWillUnmount() {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
        Events.off(this, 'reloadDataRetake');

        if (!this.state.preview) {
            this.updateLastViewedCache();
        }
    }

    handleBackPress = () => {
        this.autoGoBack();
        return true;
    };

    goBack = () => {
        this.autoGoBack();
    };

    onReloadDataRetake = async (data) => {
        await this.setState({isStartQuiz: false});
        await this.getLesson(this.item);
        // this.setState({
        //     isStartQuiz: true,
        //     dataQuiz: data.results,
        //     itemQuestion: data.results.questions[0],
        // });
    };

    getQuiz = async (item, forceStart = false) => {
        function checkQuizTimed(item) {
            return item.duration && (
                (typeof item.duration === "string" && item.duration !== "")
                || typeof item.duration === "number" && item.duration > 0);
        }

        const questionIndex = item.questionIndex;

        //string might come as description, not the same as data.duration, later we set item.duration to number
        // if we really know the test is timed we can simply avoid reading quiz answers (small optimization)
        let timed = checkQuizTimed(item);

        let response, offlineAnswers, data = {}, itemQuestion,
            running = false;

        let fetchNormal = async () => {
            console.debug("Fetching quiz:", item);
            [response, offlineAnswers] = await Promise.all([
                HttpAPI.quiz(item.id),
                !timed && HttpAPI.quizGetAnswers(item.id)
            ]);

            //populate this item with necessary metadata:
            response = response?.responseData;
            offlineAnswers = offlineAnswers?.responseData;
            data = {
                ...(response?.results),
                questions: response?.questions || []
            };

            /**
             * Fill-in existing answer data
             */
            let questionsWithAnswers = (data.questions || []).filter(q => q.options[0]?.hasOwnProperty("is_true"));

            this.itemCheck = questionsWithAnswers.map(q => ({
                id: q.id,
                result: response?.results?.results?.questions[q.id],
                explanation: q?.explanation || null,
                optionSolutions: q?.options,
            }));

            if (data.questions) {
                this.itemCheck.forEach(item => {
                    let q = data.questions.find(q => q.id === item.id);
                    if (q) q.answer = this.wrapUserAnswerHashes(q, item.result?.answered);
                });
            }

            item.duration = data.duration;
            item.status = data.status;
            if (typeof response?.results?.graduation === "string") {
                item.graduation = response?.results?.graduation;
            }
            if (typeof response?.results?.status === "string") {
                item.status = response?.results?.status;
            }
            if (typeof response?.duration === "number") {
                item.duration = response?.duration;
            }
            item.fetchMethod = "continue";
            timed = checkQuizTimed(item);

            if (!timed && data.questions) {
                if (questionsWithAnswers.length < 1) {console.warn("Quiz without answer data!");}

                //Fill-in offline answers for instant checking
                data.questions.forEach(q => {
                    const offAnswer = offlineAnswers[q.id];
                    if (offAnswer) {
                        q.options = offAnswer.options;
                        q.explanation = offAnswer?.explanation;
                        q.has_explanation = offAnswer?.explanation && offAnswer?.explanation !== "";
                    }
                });
            } else if (timed) {
                //Fill-in cached values with ongoing quiz (todo multiple ongoing!)
                let cachedData = Cache.get('active_timed_quiz', undefined, this.props, 'cached', VALUES.EXPIRY_TSTAMP.DAY,
                    () => response?.status !== 'started' && response?.results?.status !== 'started');
                if (Array.isArray(cachedData)) {
                    cachedData.forEach(cachedData => {
                        let question = data.questions.find(x => x.id === cachedData.id);
                        question && (question.answer = cachedData.answer);
                    })
                }
            }
        };

        let fetchWithStart = async () => {
            console.debug("Starting quiz: ", item);
            const _response = await HttpAPI.quizStart({id: item.id}),
                response = _response?.responseData;

            if (response?.status !== 'success') {
                console.warn("Quiz Start failed!", response);
            }
            await fetchNormal();
        };

        const startNewQuiz = item.graduation !== 'in-progress' &&
            (
                this.autoStartQuiz
                || forceStart && item.status !== 'started'
                || (!timed && item.status !== 'started' && !this.state.preventAutoStart)
            );
        if (startNewQuiz) {
            this.autoStartQuiz = false;
            await fetchWithStart();
            this.setState({percentil: undefined});
        } else {
            await fetchNormal();
        }

        let pageActive = questionIndex || 0;
        let search = !questionIndex && questionIndex !== 0;
        if (data?.questions) {
            do {
                itemQuestion = data.questions[pageActive++];
            } while (search
                && pageActive < data.questions.length
                && data.checked_questions?.some(x => x === itemQuestion.id)
            );
            if (pageActive > data.questions.length) {
                pageActive = 0;
                itemQuestion = data.questions[0];
            } else pageActive--;
        } else {
            itemQuestion = data.questions[0];
        }
        delete item.questionIndex;

        running = response?.status === 'started' || response?.results?.status === 'started';
        data.timed = (typeof item.duration === "string" && item.duration !== "")
            || (typeof item.duration === "number" && data.duration > 0);

        console.debug("Fetch Quiz Status:", item,
            "\nstatus", data?.results?.status || data?.status,
            "\ngraduation", data?.results?.graduation,
            "\nrunning:", running,
            "\nduration:", data?.timed, data?.duration,
            "\nelapsed:", data?.total_time,
            "\ncheck:", data?.instant_check,
            "\nqcount:", data?.questions?.length
        );

        const showReviewQuestionId =
            data?.results?.graduation && //some data in graduation available
            !running && //not an active instance
            !search //if we searched, we did not know which question to focus
                ? pageActive : undefined;

        this.updateUrl(pageActive);
        await this.setStateAsync({
            data: response,
            isLesson: false,
            isAssignment: false,
            isQuiz: true,
            isStartQuiz: running,
            showReviewQuestionId: showReviewQuestionId,
            dataQuiz: data,
            itemQuestion: itemQuestion,
            pageActive: pageActive,
            evaluating: false,
            invalidItem: false,
        });

        //Make sure we do not fire percentil getter when a quiz is running (or has data)
        if (!running || !itemQuestion || !data?.questions?.length) {
            const mark = data.result?.results?.user_mark || data.results?.user_mark;
            if (mark !== undefined) {
                this.fetchPercentil(this.item.id, mark);
            }
        }
    }

    updateUrl = (questionId) => {
        // Workaround: change the URL to reflect the state on web
        if (Platform.OS === "web") {
            const {courseId, type, id} = this.item;
            if (courseId && type) {
                const url = `/kurzy/${courseId}/${type}/${id}/${questionId}`;
                window.history.replaceState({}, '', url);
            }
        }
    }

    getCoursePreview = async (item) => {
        //todo if empty return with emssage
        //data come in as an object that holds IDs of quizes, each has key questions
        const {t} = this.props;
        let response = await HttpAPI.coursePreview(item.courseId).then(r => r?.responseData),
            data = {
                // ...(response?.results),
                questions: Object.values(response || {}).reduce(
                    (accumulator, currentValue) => accumulator.concat(currentValue?.questions || []),
                    []
                ),
                timed: false,
                instant_check: true,
                checked_questions: [],
                name: t('coursePreview.title')
        };

        if  (data.questions.length < 1) {
            Alert.alert(t('coursePreview.noAvailable'), t('coursePreview.noAvailableDesc'));
            this.goBack();
        }

        //hardcoded status
        item.graduation = "in-progress";
        item.status = "started";
        item.duration = "";
        item.fetchMethod = "continue";
        item.name = t('coursePreview.title');
        this.updateUrl(0);
        await this.setStateAsync({
            data: response,
            isLesson: false,
            isAssignment: false,
            isQuiz: true,
            isStartQuiz: true,
            dataQuiz: data,
            itemQuestion: data.questions[0] || {},
            pageActive: 0,
            evaluating: false,
            invalidItem: false,
            preview: true,
        });
    }

    //item: type
    getLesson = async (item) => {
        if (this.isLessonFetcherActive) return;

        const {dispatch} = this.props;
        this.isLessonFetcherActive = true;
        dispatch(showLoading(true));
        this.item = item;

        //NOTE: functions here MUST last execute await setStateAsync!
        try {
            if (item.type === 'lp_lesson') {
                const response = await HttpAPI.lessonWithId(item.id);
                await this.setStateAsync({
                    data: response?.responseData,
                    isLesson: true,
                    isQuiz: false,
                    isAssignment: false,
                });
            } else if (item.type === 'lp_quiz') {
                await this.getQuiz(item);
            } else if (item.type === 'lp_assignment') { //todo assignment not really programmed, tested
                const response = await HttpAPI.getAssignment(item.id);
                await this.setStateAsync({
                    dataAssignment: response?.responseData,
                    isAssignment: true,
                    isLesson: false,
                    isQuiz: false,
                    percentil: undefined,
                });
            } else if (item.type === "lp_course_preview") {
                //custom endpoint
                await this.getCoursePreview(item);
            }
        } finally {
            this.item.name = this.state.data?.title || this.state.data?.name;
            dispatch(showLoading(false));
            this.isLessonFetcherActive = false;
        }

    };

    openMenu = () => {
        this.setState({isShowMenu: true});
    };

    selectQuestion(item) {
        if (Platform.OS !== "web") {
            if (Date.now() - this.tapTime > 120) return;
        }

        const {itemQuestion} = this.state;

        if (itemQuestion.type === 'single_choice') {
            itemQuestion.answer = [item];
            this.forceUpdate();
        }
        if (itemQuestion.type === 'true_or_false') {
            itemQuestion.answer = item;
            this.forceUpdate();
        }
        if (itemQuestion.type === 'multi_choice') {
            if (itemQuestion?.answer) {
                const temp = itemQuestion?.answer.find((x) => x.value === item.value);
                if (temp) {
                    itemQuestion.answer = itemQuestion.answer.filter(
                        (x) => x.value !== item.value
                    );
                    this.forceUpdate();
                } else {
                    itemQuestion.answer = [...itemQuestion.answer, item];
                    this.forceUpdate();
                }
            } else {
                itemQuestion.answer = [item];
                this.forceUpdate();
            }
        }
    }

    alertCoursePreview(descriptionKey) {
        const {t, user} = this.props;
        if (!user?.token) {
            Alert.alert(t('coursePreview.title'),
                t('coursePreview.' + descriptionKey) + t('coursePreview.needToLogin'), [
                {
                    text: t('loginScreen.btnLogin'),
                    onPress: () => this.autoNavigate('LoginScreen', {
                        screen: 'CoursesDetailsScreen', courseId: this.item.courseId}
                    ),
                },
                {text: t('back'), onPress: () => {}, style: 'cancel'},
            ]);
        } else {
            Alert.alert(t('coursePreview.title'), t('coursePreview.' + descriptionKey), [
                {
                    text: t('coursePreview.wantThis'),
                    onPress: () => this.autoNavigate('CoursesDetailsScreen',
                        {courseId: this.item.courseId}),
                },
                {text: t('back'), onPress: () => {}, style: 'cancel'},
            ]);
        }
    }

    onCompleteLesson = async () => {
        const {t, dispatch, network} = this.props;

        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }

        if (!network.connection) {
            Alert.alert(t('errors.network.title', {domain: VALUES.DOMAIN_NAME}), t('errors.network.body'));
            return;
        }

        const param = {
            id: this.item.id,
        };

        await dispatch(showLoading(true));

        const response = await HttpAPI.completeLesson(param).then(r => r?.responseData),
            self = this;

        if (response.status === 'success') {
            Alert.alert(t('learningScreen.lesson.alert.title'), response.message, [
                {
                    text: t('learningScreen.lesson.alert.ok'),
                    onPress: () => {
                        //original next disabled
                        //this.onNext();
                        //DeviceEventEmitter.emit('loadCourseDetail');
                        //DeviceEventEmitter.emit('loadMyCourse');

                        self.refresh();
                    },
                },
            ]);
            this.item.graduation = '';
        } else {
            Alert.alert(response.message);
        }

        dispatch(showLoading(false));
    };

    async refresh() {
        const {dispatch} = this.props;
        dispatch(setLoading(true));
        await this.onReloadDataRetake();
        dispatch(setLoading(false));
    }

    renderLeftMenuAccordionHeader = (section, index, isActive) => {
        return (
            <View key={String(index)}>
                <View style={[styles.subTitle, {marginTop: 8, marginBottom: 11}]}>
                    <View style={styles.subTitle}>
                        <IconI name={isActive ? 'caret-up' : 'caret-down'} size={15}/>
                        <Text numberOfLines={1} style={styles.txtSubTitle}>
                            {section.title}
                        </Text>
                    </View>
                    <Text style={styles.txtLength}>{section.items.length}</Text>
                </View>
            </View>
        );
    };

    async onNavigateLearning(item) {
        const {t} = this.props;

        if (this.state.preview) {
            this.alertCoursePreview('unableToGeneral');
            return;
        }

        this.setState({isShowMenu: false});
        await this.getLesson(item);

        this.scrollView.scrollTo({y: 0, animated: true});
    }

    findAndNavigateNextLesson = async () => {
        const {dispatch, t} = this.props;
        let {course} = this.props;
        let sectionsList = course?.data?.sections || course?.sections;

        if (!sectionsList) {
            const cId = course?.id || this.item.courseId;
            if (cId) {
                dispatch(setLoading(true));
                const response = await HttpAPI.courseDetail(cId).then(r => r?.responseData || {});
                dispatch(saveCourse(response));
                course = response;
                sectionsList = course.data?.sections || course.sections;
            }
        }

        const handleNothingFound = () => {
            dispatch(setLoading(false));
            Alert.alert('', t('learningScreen.lesson.noQuizzesAvailable'), [
                {
                    text: t('learningScreen.lesson.alert.ok'),
                    onPress: () => {},
                    style: 'cancel'
                }, {
                    text: t('learningScreen.lesson.alert.returnBack'),
                    onPress: () => this.goBack(),
                    style: 'confirm'
                }
            ]);
        };

        if (!sectionsList) {
            console.warn("Failed to retrieve course to search for quiz to start from lesson.");
            handleNothingFound();
            return;
        }

        for (let section of sectionsList) {
            for (let item of section.items) {
                if (this.item.id == item.id) {

                    //find quiz
                    for (let item of section.items) {
                        //run any first quiz
                        if (item.type === 'lp_quiz' && item.id != this.item.id) {
                            //loading handled in switching
                            this.onNavigateLearning(item);
                            return;
                        }
                    }
                    handleNothingFound();
                    return;
                }
            }
        }
        handleNothingFound();
    }

    renderLeftMenuAccordionContent = (section) => {
        const {course} = this.props;

        const {items} = section;
        return (
            <View>
                {items.map((item, i) => (
                    <TouchableOpacity
                        key={String(i)}
                        onPressIn={this.onNavigateLearning.bind(this, item)}

                        onPress={() => Alert.alert('PREDL')}
                        onPressOut={() => Alert.alert('PREDL')}
                        style={[styles.subTitle, {marginBottom: 5, marginLeft: 24}]}
                        disabled={
                            false
                            // (course?.data?.course_data.status === 'finished' ||
                            //   course?.data?.course_data.status === '') &&
                            // !item.preview
                        }
                    >
                        <View style={styles.subTitle}>
                            {item.type === 'lp_lesson' && (
                                <IconF name="book" color="#4E4E4E" size={14}/>
                            )}
                            {item.type === 'lp_quiz' && (
                                <IconF name="help-circle" color="#4E4E4E" size={14}/>
                            )}
                            {item.type === 'lp_assignment' && (
                                <IconF name="file" color="#4E4E4E" size={14}/>
                            )}
                            <Text
                                numberOfLines={1}
                                style={[
                                    styles.txtItemLession,
                                    this.item.id === item.id && {
                                        color: '#000',
                                        fontWeight: '500',
                                    },
                                ]}
                            >
                                {item.title}
                            </Text>
                        </View>
                        {item.status === 'completed' ? (
                            <IconI name="checkmark-circle-outline" style={styles.iconPreview}/>
                        ) : item.status === 'failed' ? (
                            <IconI name="close-circle-outline" color="#FF0000" size={16}/>
                        ) : (
                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                {item.preview && (
                                    <IconI name="eye-outline" style={styles.iconPreview}/>
                                )}
                                {item.locked && (
                                    <IconI name="lock-closed" color="#4E4E4E" size={16}/>
                                )}
                                {item.duration !== '' && (
                                    <Text style={styles.totalHours}>{item.duration}</Text>
                                )}
                            </View>
                        )}
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    renderHeaderItem = ({item, index}) => {
        const {pageActive, dataQuiz} = this.state;
        // if (index > 5 && pageActive < 5)
        //   return <Text style={{ marginLeft: 3, marginTop: 3 }}>...</Text>;
        let answered = false;
        const itemQuestion = dataQuiz.questions[index];
        if (itemQuestion) {
            answered = dataQuiz.questions[index]?.answer !== undefined
                || this.itemCheck.some(x => x.id === itemQuestion.id);
        }

        return (
            <TouchableOpacity
                onPressIn={() => {
                    this.setState({
                        pageActive: index,
                        itemQuestion: item,
                    });
                    this.updateUrl(index);
                }}
                style={[
                    styles.btnPage,
                    {
                        backgroundColor: pageActive === index ? Colors.scholastik.purple :
                            (answered ? Colors.scholastik.softGray : Colors.scholastik.white),
                        borderColor: pageActive === index ? Colors.scholastik.purple :
                            (answered ? Colors.scholastik.darkGrayText : '#E4E4E4'),
                    },
                ]}
            >
                <Text style={[styles.txtPage, {color: pageActive === index ? Colors.scholastik.white : Colors.scholastik.textPrimary}]}>{index + 1}</Text>
            </TouchableOpacity>
        );
    };

    onNext = async () => {
        const index = this.dataLesson.findIndex((x) => x.id === this.item.id);
        if (index === this.dataLesson.length - 1) {
            await this.onNavigateLearning(this.dataLesson[this.dataLesson.length - 1]);
            return;
        }

        await this.onNavigateLearning(this.dataLesson[index + 1]);
    };

    onJumpQuiz = async (index, animated=true) => {
        const {dataQuiz} = this.state;
        if (dataQuiz.questions.length < index && index >= 0) {
            this.flatListRef.scrollToIndex({
                index: index,
                animated: animated,
            });
            this.setState({
                itemQuestion: dataQuiz.questions[index],
                pageActive: index,
            });
            this.updateUrl(index);
        }
    };

    onStartQuiz = async () => {
        if (this.state.preview) {
            this.alertCoursePreview('unableToGeneral');
            return;
        }
        const {dispatch} = this.props;
        dispatch(showLoading(true));
        this.item.graduation = "";
        await this.getQuiz(this.item, true);
        dispatch(showLoading(false));
    };

    onPrevQuiz = () => {
        const {pageActive, dataQuiz} = this.state;
        if (pageActive === 0) return;
        this.flatListRef.scrollToIndex({
            index: pageActive - 1,
            animated: true,
        });
        this.setState({
            itemQuestion: dataQuiz.questions[pageActive - 1],
            pageActive: pageActive - 1,
        });
        this.updateUrl(pageActive - 1);
    };

    onNextQuiz = () => {
        const {pageActive, dataQuiz} = this.state;
        if (dataQuiz.questions.length === pageActive + 1) {
            //Alert.alert('', t('learningScreen.quiz.noNextQuestion'));
            return;
        }
        this.flatListRef.scrollToIndex({
            index: pageActive + 1,
            animated: true,
        });
        this.setState({
            itemQuestion: dataQuiz.questions[pageActive + 1],
            pageActive: pageActive + 1,
        });
        this.updateUrl(pageActive + 1);
    };

    onFinish = async () => {
        const {t} = this.props;
        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }

        const {dispatch, network} = this.props;
        if (!network.connection) {
            Alert.alert(t('errors.network.title', {domain: VALUES.DOMAIN_NAME}), t('errors.network.body'));
            return;
        }

        dispatch(showLoading(true));

        try {
            console.debug('Finish quiz - prepare', this.item.id);

            //First submit all failures, then try to submit the quiz
            Routines.retryFailedRequestsFromCache(this, this._finishQuizImpl.bind(this));
        } catch (e) {
            console.error("Failed to submit quiz", e);
            Alert.alert(t('errors.network.title', {domain: VALUES.DOMAIN_NAME}), t('errors.network.body'));
            dispatch(showLoading(false));
        }
    };

    async _finishQuizImpl() {
        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }
        console.debug('Finish quiz - send', this.item.id);
        const {dispatch} = this.props;
        const {dataQuiz} = this.state;
        const itemTemp = {};

        dataQuiz.questions.forEach((x) => {
            if (this.itemCheck.find((y) => y.id === x.id)) return;

            let answer = this.extractUserAnswerHashes(x);
            if (answer) itemTemp[String(x.id)] = answer;
        });
        const param = {
            id: this.item.id,
            answered: itemTemp,
        };

        const response = await HttpAPI.quizFinish(param).then(r => r?.responseData);
        if (response?.status === 'success') {
            const mark = response.result?.results?.user_mark || response.results?.user_mark;
            if (mark !== undefined) {
                this.fetchPercentil(this.item.id, mark);
            } else {
                console.warn("Missing user mark with response on quiz finish!", response, mark)
            }

            await this.setState(defaultState);
            await this.setState({preventAutoStart: true});
            this.itemCheck = [];
            this.item.graduation = '';
            this.item.status = 'completed';
            await this.getQuiz(this.item, false);
        } else {
            console.error("Failed to submit quiz - without error.", response);
        }
        dispatch(showLoading(false));
    }

    fetchPercentil(quiz_id, mark) {
        const {t} = this.props,
            self = this;
        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }

        this.setState({
            percentil: t('loading')
        })
        HttpAPI.percentil({
            id: quiz_id,
            user_mark:mark
        }).then(response => {
            response = response?.responseData;
            console.debug("Percentil computed: ", response);
            self.setState({
                percentil: response?.percentil
            });
        }).catch(console.error);
    }

    toggleExplanation = (question) => {
        let newState = [...this.state.activeExplanations];
        newState[question.id] = !newState[question.id];
        this.setState({activeExplanations: newState});
    }

    onFinishCourse = async () => {
        const {t} = this.props;
        const courseData = this.state.data?.assigned?.course || {};

        if (!courseData.id) {
            console.error("ERROR: Missing assigned course! Required property.", this.state.data);
        }

        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }
        Alert.alert(
            t('learningScreen.finishCourseAlert.title'),
            t('learningScreen.finishCourseAlert.description'),
            [
                {
                    text: t('learningScreen.finishCourseAlert.cancel'),
                    onPress: () => {
                    },
                    style: 'cancel',
                },
                {
                    text: t('learningScreen.finishCourseAlert.ok'),
                    onPress: async () => {
                        const {dispatch, navigation, network} = this.props;

                        if (!network.connection) {
                            Alert.alert(t('errors.network.title', {domain: VALUES.DOMAIN_NAME}), t('errors.network.body'));
                            return;
                        }

                        dispatch(showLoading(true));
                        const param = {
                            id: courseData.id,
                        };

                        const response = await HttpAPI.finishCourse(param).then(r => r?.responseData);
                        dispatch(showLoading(false));
                        if (response.status === 'success') {
                            Alert.alert(response.message);
                            DeviceEventEmitter.emit('loadCourseDetail');
                            DeviceEventEmitter.emit('loadMyCourse');
                            this.autoGoBack();
                        } else {
                            Alert.alert(response.message);
                        }
                    },
                },
            ],
            {cancelable: false}
        );
    };

    onFinishQuiz = async () => {
        const {t} = this.props;
        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }
        Alert.alert(
            t('learningScreen.quiz.finishTitle'),
            t(this.state.dataQuiz?.timed ?
                'learningScreen.quiz.finishTimedDescription' : 'learningScreen.quiz.finishDescription'),
            [
                {
                    text: t('learningScreen.finishCourseAlert.cancel'),
                    onPress: () => {
                    },
                    style: 'cancel',
                },
                {
                    text: t('learningScreen.finishCourseAlert.ok'),
                    onPress: this.onFinish.bind(this)
                },
            ],
            {cancelable: false}
        );
    };

    showHint = () => {
        const {t} = this.props;
        const {itemQuestion} = this.state;
        if (itemQuestion?.hint)
            Alert.alert(t('learningScreen.quiz.hint'), itemQuestion.hint);
        else {
            Alert.alert(t('learningScreen.quiz.hintEmpty'));
        }
    };

    renderFillInBlanks = () => {
        const {itemQuestion, data, dataQuiz} = this.state;
        const lstIdKeys = [];
        const item = itemQuestion.options[0];
        const {ids, title_api} = item;
        ids.forEach((id) => {
            lstIdKeys.push({id, key: `{{FIB_${id}}}`});
        });

        const words = title_api.split(' ');
        return words.map((i, k) => {
            const itemKey = lstIdKeys.find((x) => x.key === i);

            if (itemKey) {
                if (this.isDisable(itemQuestion)) {
                    const userAnswer = data.results.answered[itemQuestion.id].answered;
                    return (
                        <View
                            key={String(k)}
                            style={{
                                minWidth: 60,
                                paddingVertical: 2,
                                paddingHorizontal: 5,
                                backgroundColor: '#ECECEC',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            {!item.answers[itemKey.id]?.is_correct && (
                                <Text
                                    style={{
                                        fontWeight: 'bold',
                                        color: Colors.scholastik.black,
                                        textDecorationLine: "line-through",
                                    }}
                                >
                                    {userAnswer[itemKey.id]}
                                </Text>
                            )}
                            {!item.answers[itemKey.id]?.is_correct &&
                                item.answers[itemKey.id]?.correct && (
                                    <IconF style={{marginHorizontal: 2}} name="arrow-right" color="#666" size={14}/>
                                )}
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    color: Colors.scholastik.greenDark,
                                }}
                            >
                                {item.answers[itemKey.id]?.correct
                                    ? `${item.answers[itemKey.id]?.correct}`
                                    : ''}
                            </Text>
                        </View>
                    );
                }
                return (
                    <TextInput
                        key={String(k)}
                        disabled={!! this.itemCheck.find((x) => x.id === itemQuestion.id)}
                        style={{
                            marginVertical: 0,
                            paddingVertical: 0,
                            marginBottom: 5,
                            // height: 20,
                            minWidth: 60,
                            borderBottomWidth: 1,
                            color: '#000',
                        }}
                        value={itemQuestion.answer?.[itemKey.id] || ""}
                        underlineColorAndroid="undefined"
                        onChangeText={(value) => this.onChangeFillBlank(itemKey.id, value)}
                    />
                );
            }
            return <Text key={String(k)}> {i} </Text>;
        });
    };

    onChangeFillBlank = (id, value) => {
        const {itemQuestion} = this.state;
        itemQuestion.answer = itemQuestion.answer || {};
        itemQuestion.answer[id] = value;
        this.setState({
            itemQuestion: itemQuestion
        })
    };

    extractUserAnswerHashes = (itemQuestion) => {
        if (itemQuestion?.answer) {
            if (itemQuestion.type === 'true_or_false') return itemQuestion.answer.value;
            if (itemQuestion.type === 'fill_in_blanks') return itemQuestion.answer;
            if (itemQuestion.type === 'multi_choice') return itemQuestion.answer.map((y) => y.value);
            return itemQuestion.answer.map((y) => y.value);
        }
        return null;
    }

    wrapUserAnswerHashes = (itemQuestion, hashArray) => {
        if (itemQuestion?.answer || hashArray?.length < 1) return undefined;
        if (itemQuestion.type === 'true_or_false') return {value: hashArray};
        if (itemQuestion.type === 'fill_in_blanks') return hashArray;
        if (itemQuestion.type === 'multi_choice') return hashArray?.map((y) => ({value: y}));
        return Array.isArray(hashArray) ? hashArray.map((y) => ({value: y})) : [{value: hashArray}];
    }

    onCheck = async (force = false) => {
        const {t, dispatch, network} = this.props;
        if (this.state.preview) {
            this.alertCoursePreview('unableToEvaluate');
            return;
        }

        const {itemQuestion} = this.state;

        if (!force && !itemQuestion?.answer) {
            Alert.alert('', t('learningScreen.quiz.checkSubmitAlert'), [
                {
                    text: t('learningScreen.lesson.alert.cancel'),
                    onPress: () => {},
                    style: 'confirm'
                },
                {
                    text: t('learningScreen.lesson.alert.ok'),
                    onPress: () => this.onCheck(true),
                    style: 'cancel'
                },
            ]);
            return;
        }

        if (!network.connection) {
            Alert.alert(t('errors.network.title', {domain: VALUES.DOMAIN_NAME}), t('errors.network.body'));
            return;
        }

        try {
            dispatch(setLoading(true));
            const answers = this.extractUserAnswerHashes(itemQuestion);
            const param = {
                id: this.item.id,
                question_id: itemQuestion.id,
                answered: answers,
            };

            const response = await HttpAPI.checkAnswer(param).then(r => r?.responseData);
            if (response.code === 'cannot_check_answer') {
                Alert.alert(response.message);
            }
            const dataTemp = {
                id: itemQuestion.id,
                result: response.result,
                explanation: response?.explanation || null,
                optionSolutions: response?.options,
            };

            this.onStatusQuestion(
                QStatus.computeStatus(this.props, itemQuestion.id, response.result?.correct),
                true
            );

            this.itemCheck.push(dataTemp);
            this.forceUpdate();
        } catch (e) {
            console.warn('Error evaluating question!', e);
        } finally {
            dispatch(showLoading(false));
        }
    };

    onCheckImmediate = (force=false) => {
        const {t} = this.props;
        const {itemQuestion, preview} = this.state;

        if (itemQuestion.type === "fill_in_blanks") {
            //todo make this also available as offline evaluation
            this.onCheck(force);
            return;
        }

        // Disabled prevention from
        if (!force && !itemQuestion.answer) {
            Alert.alert('', t('learningScreen.quiz.checkSubmitAlert'), [
                {
                    text: t('learningScreen.lesson.alert.cancel'),
                    onPress: () => {},
                    style: 'confirm'
                },
                {
                    text: t('learningScreen.lesson.alert.ok'),
                    onPress: () => this.onCheckImmediate(true),
                    style: 'cancel'
                },
            ]);
            return;
        }

        if (!itemQuestion.answer) {
            //so that we responded as 'empty' reponse
            itemQuestion.answer = [];
        }

        const answers = this.extractUserAnswerHashes(itemQuestion);

        // we require ALL questions to match, 1:1, with no exceptions, fill in blanks are instead fetched from server above
        //for some reason correct answers have different format, sometimes hash is in value prop sometimes it is the object
        const correctAnswers = itemQuestion?.options?.filter(x => x.is_true === "yes").map(x => x.value || x);
        if (!correctAnswers || correctAnswers.length < 1) {
            //todo make this also available as offline evaluation
            return this.onCheck();
        }

        const isCorrect = Array.isArray(answers) ? (
                answers.every(a => correctAnswers.includes(a)) && correctAnswers.every(b => answers.includes(b))
            ) : correctAnswers.length === 1 && correctAnswers[0].value === answers;

        this.onStatusQuestion(QStatus.computeStatus(this.props, itemQuestion.id, isCorrect), false);

        this.itemCheck.push({
            id: itemQuestion.id,
            result: {
                answered: answers,
                correct: isCorrect,
                mark: 0 //todo updated later
            },
            explanation: itemQuestion?.explanation || null,
            optionSolutions: itemQuestion?.options,
        });

        if (!preview) {
            const param = {
                id: this.item.id,
                question_id: itemQuestion.id,
                answered: answers,
            };

            const self = this;
            Routines.sendRequestWithoutWaitingAndHandleFailureByCaching(
                this,
                `quiz_${param.id}_check_answer_${param.question_id}`,
                'checkAnswer',
                [param],
                (response) => {
                    const storedItemCheckQuestion = self.itemCheck.find(x => x.id === param.question_id);
                    storedItemCheckQuestion.result = response?.result || storedItemCheckQuestion.result;
                    storedItemCheckQuestion.exact = response?.explanation || storedItemCheckQuestion?.explanation;
                    storedItemCheckQuestion.optionSolutions = response?.options || storedItemCheckQuestion.options;
                    storedItemCheckQuestion.answered = response?.answered || storedItemCheckQuestion.answered;
                    self.wrapUserAnswerHashes(itemQuestion, storedItemCheckQuestion.answered);
                    self.forceUpdate();

                    self.onStatusQuestion(
                        QStatus.computeStatus(self.props, itemQuestion.id, response?.result.correct),
                        true
                    );
                }
            );
        }
        this.forceUpdate();
    };

    isDisable = (itemQuestion) => {
        const {dataQuiz} = this.state;
        return !! itemQuestion && this.itemCheck.find((x) => x.id === itemQuestion.id) ||
            (dataQuiz?.checked_questions &&
                dataQuiz.checked_questions.includes(itemQuestion.id));
    };

    onPinQuestion = () => {
        const {t} = this.props;
        if (this.state.preview) {
            this.alertCoursePreview('unableToUsePin');
            return;
        }

        const {itemQuestion} = this.state;
        let isCorrect = this.itemCheck?.find((x) => x.id === itemQuestion.id)?.result?.correct;

        const isPin = QStatus.pinIsEnabled(this.props, itemQuestion.id);
        let status = QStatus.computeStatus(this.props, itemQuestion.id, isCorrect);
        if (isPin) status -= 10;
        else status += 10;
        this.onStatusQuestion(status, true);
    };

    onStatusQuestion = (status, updateServer) => {
        if (this.state.preview) return; //no dialog, a service

        const {itemQuestion} = this.state;
        const { user, dispatch } = this.props;

        if (status > 2) { //we keep track only on status no 3+ (pin or incorrect)
            console.log("Update QStat", status, "server", updateServer);
            if (updateServer) {
                const param = {
                    id: this.item.id,
                    question_id: itemQuestion.id,
                    status: status,
                    user_id: user?.info.id
                };
                HttpAPI.questionStorePin(param).catch(e => {
                    console.warn("Could not save pin data on question", e);
                });
            }

            dispatch(saveQuestionStatus({
                id: itemQuestion.id,
                quizId: this.item.id,
                status: status,
                order: this.state.pageActive,
                quizName: this.getTheName(),
            }));
        } else {
            console.log("Erase QStat", status, "server", updateServer);
            if (updateServer) {
                const param = {
                    id: this.item.id,
                    question_id: itemQuestion.id,
                    user_id: user?.info.id,
                    status: -1
                };
                HttpAPI.questionStorePin(param).catch(e => {
                    console.warn("Could not save pin data on question", e);
                });
            }
            dispatch(deleteQuestionStatus(itemQuestion.id));
        }
    };

    renderAnswerButton = (i, text, wasAnswer, pressHandler, disabled, isMultiChoice, wasCorrectUnanswered) => {
        const self = this;
        let handler = disabled ? null : () => {
            pressHandler.apply(self);
            if (this.state.dataQuiz?.timed) {
                Cache.set('active_timed_quiz',
                    this.state.dataQuiz.questions.map(q => ({
                        answer: q.answer,
                        id: q.id
                    })), this.props, 'cached', VALUES.EXPIRY_TSTAMP.DAY);
            }
        };

        const foregroundText = wasAnswer ? Colors.scholastik.white : disabled ?
            (wasCorrectUnanswered ? Colors.scholastik.greenDark : Colors.scholastik.darkGray) : Colors.scholastik.textPrimary;
        const foregroundVisuals = wasAnswer ? Colors.scholastik.white : disabled ?
            (wasCorrectUnanswered ? Colors.scholastik.greenDark : Colors.scholastik.darkGray) : Colors.scholastik.purple;
        const background = wasAnswer ? Colors.scholastik.purple : disabled ?
            (wasCorrectUnanswered ? Colors.scholastik.green : Colors.scholastik.gray): Colors.scholastik.white;

        return text.includes('oscio-mathjax-equation') ? (<MathJax key={String(i)} html={`
    <div style="display: flex; flex-direction: row; align-items: center; border: 2px solid ${Colors.scholastik.gray}; 
    border-radius: 4px; padding: 8px; background-color: ${background}; 
    opacity: ${disabled ? 0.5 : 1}; color: ${foregroundText}; margin: 0 -8px;">
        ${
            isMultiChoice ? `<div><svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="23.2" height="20" viewBox="3 3 38.26 33" fill="${background}" style="background: ${foregroundVisuals}">
    ${wasAnswer ? '<path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19zM39 4.5c1 1.2.7 2.1-1.9 5-3 3.4-3.1 4-3.1 12.2C34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1 1.6.9 2.4.7 3.6-.5 2-2 2.6-2 4.2-.1z"/><path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5-3.8 4.4c-2.1 2.4-4.3 4.2-4.8 4C18.8 26.2 10 16.4 10 15.3c0-.6.6-1.6 1.4-2.2 1.2-1 2.2-.5 5.2 2.9l3.7 4.1 4.4-4.3C31.1 9.5 30.7 9 19 9H8.9l.3 10.2z"/>'
            : '<path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19ZM31.2 5.1c1.6.9 3.142.42 3.11 4.315C34.27 14.422 34 13.5 34 21.7 34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1Z"/><path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5s.373-8.758.19-8.695C29.023 9.862 30.7 9 19 9H8.9l.3 10.2Z"/>'}
</svg></div>` : ''
        }
       <div style="font-size: 15px !important; line-height: 19px; margin-left: 9px; align-self: center; font-family: ;
       font-weight: 800; color: ${foregroundVisuals}">
        ${getQuestionLetter(i)})&nbsp;</div>
       <div>${text}</div>
    </div>
    `} style={{backgroundColor: 'transparent'}} font={'Poppins'} clickHandler={() => {
            self.tapTime = Date.now();
            handler();
        }}/>) : (<TouchableOpacity
            key={String(i)}
            style={[styles.itemQuestion, {backgroundColor: background, opacity: disabled ? 0.5 : 1}]}
            onPressIn={(x) => self.tapTime = Date.now()}
            onPressOut={handler}
            disabled={!!disabled}>
            {isMultiChoice && (
                wasAnswer ? <SvgChecked fill={background} style={{backgroundColor: foregroundVisuals}}></SvgChecked> :
                    <SvgUnchecked fill={background} style={{backgroundColor: foregroundVisuals}}></SvgUnchecked>
            )}
            <Text style={[styles.txtItemQuestionIcon, {color: foregroundVisuals}]}>
                {getQuestionLetter(i)}) </Text>
            <Text style={[styles.txtItemQuestion, {color: foregroundText}]}>{text}</Text>
        </TouchableOpacity>);
    }

    getTheName() {
        const {data} = this.state;
        const {course} = this.props;

        let sectionIndex = 0; //activeSections?.length > 0 ? activeSections[0] : -1;
        return data?.name ||  //todo the second expression might be invalid...
            (course?.data?.sections?.length > sectionIndex ? course?.data?.sections[sectionIndex] : {})?.title
            || this.item?.name
            || "";
    }

    render() {
        const {t, course, dispatch, common, pins, user} = this.props;

        const {
            isShowMenu,
            data,
            activeSections,
            isLesson,
            isQuiz,
            isStartQuiz,
            dataQuiz,
            itemQuestion,
            pageActive,
            isAssignment,
            dataAssignment,
            evaluating,
            percentil,
            preview,
        } = this.state;

        let sectionTitle = this.getTheName();
        let missingQuestions = isQuiz && !itemQuestion && data?.questions?.length < 1,
            isTrulyStartQuiz = isStartQuiz && !missingQuestions && !evaluating,
            questionSolutionIndex, // = this.itemCheck.indexOf(itemQuestion?.id),
            isLast = isQuiz && pageActive >= dataQuiz?.questions?.length - 1,
            isFirst = isQuiz && pageActive === 0,
            correctAnswerIndex = itemQuestion?.options?.map((x, i) => ({c:x.is_true === "yes", i:i}))
                .filter(x=>x.c).map(x => x.i);

        for (let i in this.itemCheck) {
            if (this.itemCheck[i].id === itemQuestion?.id) questionSolutionIndex = i;
        }

        if (this.logLastSolutionIndex !== questionSolutionIndex) {
            console.debug("Question:", isTrulyStartQuiz ,
                ", timed:", dataQuiz?.timed, ", elapsed:", dataQuiz?.total_time,
                ". Page", pageActive, "with current answer -> ",
                questionSolutionIndex && this.itemCheck[questionSolutionIndex]?.optionSolutions);
            this.logLastSolutionIndex = questionSolutionIndex;
        }

        let quizStatus = dataQuiz?.results?.status || dataQuiz?.status || data?.results?.status;

        return <CommonAppLayout color={Colors.scholastik.white} style={[styles.container, {
                //58 - Explanation max height - add this padding to allow scroll
                paddingBottom: this.state.activeExplanations?.[itemQuestion?.id] ? deviceHeight * 0.57 : 0
            }]}>
                <ContentMiddleLayout>
                    <View style={styles.header}>
                        {/*<TouchableOpacity*/}
                        {/*    onPressIn={this.openMenu}*/}
                        {/*  hitSlop={{ top: 8, bottom: 8, left: 8, right: 8 }}*/}
                        {/*>*/}
                        {/*  <Image source={Images.iconMenu} style={styles.iconMenu} />*/}
                        {/*</TouchableOpacity>*/}

                        {
                            isQuiz && isTrulyStartQuiz && <View style={{flexDirection: "row"}}>
                                {
                                    //TODO: consider allowing pins for timed quiz? maybe only in reviews, during test no pins are available anyway
                                    (!dataQuiz?.timed || dataQuiz?.duration < 1) && <TouchableOpacity
                                        onPressIn={this.onPinQuestion}
                                        style={{marginRight: 22, marginLeft: 8}}
                                        hitSlop={{top: 8, bottom: 8, left: 8, right: 8}}
                                    >
                                        {
                                            (QStatus.pinIsEnabled(this.props, itemQuestion.id) ?
                                                    <View style={{position: "relative"}}>
                                                        <IconM name="pin" size={20} color={Colors.scholastik.darkGrayText} style={{marginTop: 5}}/>
                                                        <View style={{position: "absolute", bottom: 0, width: 25, height: 5, backgroundColor: "white"}}></View>
                                                    </View>
                                                    :
                                                    <IconM name="pin-outline" size={20}/>
                                            )
                                        }
                                    </TouchableOpacity>
                                }
                                <TouchableOpacity
                                    onPressIn={this.onFinishQuiz}
                                    hitSlop={{top: 8, bottom: 8, left: 8, right: 8}}
                                >
                                    <Text style={{
                                        fontSize: 10, fontWeight: 'bold', color: Colors.scholastik.purple,
                                        fontFamily: 'Poppins', maxWidth: 80
                                    }}>{t('learningScreen.quiz.submit')}</Text>
                                </TouchableOpacity>
                            </View>
                        }


                        {
                            isQuiz ?
                                <View style={{flexDirection: 'row', alignItems: 'flex-start', marginRight: 20}}>
                                    {
                                        isTrulyStartQuiz && dataQuiz?.timed && dataQuiz?.duration > 0 ? (
                                            <>
                                                <Text style={{color: Colors.scholastik.textPrimary}}>
                                                    {t('learningScreen.quiz.timeRemaining')}
                                                </Text>
                                                <Text>&nbsp;</Text>
                                                <CountDown
                                                    duration={dataQuiz?.total_time}
                                                    callBack={() => this.onFinish()}
                                                />
                                            </>
                                        ) : <></>
                                    }
                                </View> : (<Text style={styles.lessonTitle}>{sectionTitle}</Text>)
                        }
                        <TouchableOpacity
                            onPressIn={this.goBack}
                            style={{paddingLeft: 20}}
                            hitSlop={{top: 18, bottom: 18, left: 18, right: 18}}
                        >
                            <Image source={Images.iconClose} style={styles.iconBack}/>
                        </TouchableOpacity>
                    </View>

                    {!data ? (common.loading ? <></> : (this.isLessonFetcherActive ?
                        <View style={{paddingTop: '30%'}}>
                            <ActivityIndicator size="large" color={Colors.scholastik.darkGray}/>
                            <Text style={{
                                fontFamily: 'Poppins', color: Colors.scholastik.darkGray, fontSize: 18,
                                width: '70%', textAlign: "center", paddingTop: 30, alignSelf: "center",
                            }}>{t('startScreen.redirecting') + ' ' + sectionTitle + '...'}</Text>
                        </View>
                        : <InternetConnectionErrorView
                            containerStyle={{paddingBottom: 100}}
                            onPress={() => this.refresh()}/>)) : (
                        <>
                            <ScrollView
                                ref={(refs) => {
                                    this.scrollView = refs;
                                }}
                                scrollEnabled={this.state.scrollenabled}
                                style={styles.content}
                                contentContainerStyle={{paddingBottom: 50}}
                                showsVerticalScrollIndicator={Platform.OS === "web"}
                            >
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {isLesson && (
                                        <View style={{flex: 1}}>
                                            {data?.duration !== '' && data?.duration > 0 && (
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: 6,
                                                    }}
                                                >
                                                    <IconF name="clock" size={22}/>
                                                    <Text style={styles.txtTime}>{data?.duration}</Text>
                                                </View>
                                            )}

                                            {/*<Text style={styles.txtName}>{data?.name}</Text>*/}
                                            <RenderDataHTML html={data?.video_intro || ''} options={{dispatch: dispatch}}/>
                                            {renderHtml(data?.content, {dispatch: dispatch})}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    paddingTop: 20
                                                }}
                                            >
                                                <View style={{flex: 1}}>
                                                    {quizStatus !== 'completed' ? (
                                                            <TouchableOpacity
                                                                style={styles.btnFinishLesson}
                                                                onPressIn={() => {
                                                                    Alert.alert(t('learningScreen.lesson.submit'),
                                                                        t('learningScreen.lesson.submitMessage'),
                                                                        [{
                                                                            text: t('learningScreen.lesson.submitDo'),
                                                                            onPress: () => this.onCompleteLesson(),
                                                                            style: 'cancel',
                                                                        }, Platform.OS === "ios" && {
                                                                            text: t('back'),
                                                                            onPress: () => {},
                                                                        }]);
                                                                }}
                                                            >
                                                                <Text style={styles.txtFinish}>
                                                                    {t('learningScreen.lesson.btnComplete')}
                                                                </Text>
                                                            </TouchableOpacity>
                                                        ) :
                                                        (<View style={{
                                                            paddingHorizontal: 20,
                                                            flexDirection: 'row',
                                                            alignSelf: 'center',
                                                        }}>
                                                            <Text style={[styles.txtFinished, {lineHeight: 35}]}>
                                                                {t('learningScreen.lesson.completed')}
                                                            </Text>
                                                            <IconF name="check" style={{
                                                                color: Colors.scholastik.greenDark,
                                                                alignSelf: 'center'
                                                            }} size={35}/>
                                                        </View>)
                                                    }

                                                    <TouchableOpacity
                                                        style={[styles.btnFinishLesson, {marginTop: 20}]}
                                                        onPressIn={() => this.findAndNavigateNextLesson()}
                                                    >
                                                        <Text style={styles.txtFinish}>
                                                            {t('learningScreen.lesson.goPractice')}
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>

                                            </View>

                                            {/*{ this.item.id && !preview && <WpComments*/}
                                            {/*    maxDepth={5}*/}
                                            {/*    pageId={this.item.id}*/}
                                            {/*    hostUrl={VALUES.SITE_URL}*/}
                                            {/*    allowComments={true}*/}
                                            {/*    user={user.name}*/}
                                            {/*>*/}
                                            {/*</WpComments>}*/}
                                            { this.item.id && !preview && <View>
                                                <Text style={[styles.txtSubTitle, {marginTop: 50, fontSize: 18}]}>
                                                    {t('learningScreen.comments.mainHeading')}
                                                </Text>

                                                <Comments
                                                    postId={this.item.id}
                                                    user={user}
                                                    dispatch={dispatch}
                                                >
                                                </Comments>
                                            </View>}
                                        </View>
                                    )}

                                    {isQuiz && !isTrulyStartQuiz && quizStatus === '' && (
                                        <View style={{width: '100%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 15}}>
                                                <IconF name="clock" size={22}/>
                                                <Text style={styles.txtTime}>{data?.duration}</Text>
                                            </View>

                                            <Text style={[styles.title, {marginBottom: 7}]}>{data?.name}</Text>
                                            <Text style={[styles.txtLession, {alignSelf: 'flex-end'}]}>
                                                {t('learningScreen.quiz.questionCount', {
                                                    count: data?.questions.length,
                                                })}
                                            </Text>
                                            {/*<Text style={[styles.txtLession, {alignSelf: 'flex-end'}]}>*/}
                                            {/*    {t('learningScreen.quiz.passingGrade', {*/}
                                            {/*        grade: data?.meta_data._lp_passing_grade,*/}
                                            {/*    })}*/}
                                            {/*</Text>*/}
                                            {typeof data?.content === 'string' && data?.content !== '' && (
                                                <View style={{marginVertical: 7}}>{renderHtml(data?.content, {dispatch: dispatch})}</View>
                                            )}
                                            <TouchableOpacity
                                                style={[styles.btnFinish, {
                                                    marginTop: 20,
                                                    width: '95%',
                                                    alignSelf: 'center'
                                                }]}
                                                onPressIn={this.onStartQuiz}
                                            >
                                                <Text style={styles.txtFinish}>
                                                    {t('learningScreen.quiz.btnStart')}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                    {isQuiz && !isTrulyStartQuiz && quizStatus !== '' && (
                                        <View style={{flex: 1}}>
                                            <View style={{display: "flex", flex: 1}}>
                                                {!missingQuestions && (<View style={styles.overview}>
                                                    <ProgressCircle
                                                        widthX={110}
                                                        progress={
                                                            Math.round(dataQuiz?.results?.result) / 100
                                                        }
                                                        animate={true}
                                                        strokeWidth={10}
                                                        backgroundColor="#F6F6F6"
                                                        progressColor={
                                                            dataQuiz?.results?.graduation === 'failed'
                                                                ? Colors.scholastik.redDark
                                                                : Colors.scholastik.greenDark
                                                        }
                                                        textStyle={styles.txtCircle}
                                                    />
                                                    <View style={{
                                                        justifyContent: 'center',
                                                        marginLeft: 20,
                                                    }}>
                                                        <Text
                                                            style={[
                                                                styles.txtResult,
                                                                dataQuiz?.results?.graduation !== 'failed' && {
                                                                    color: Colors.scholastik.greenDark,
                                                                },
                                                            ]}
                                                        >
                                                            {dataQuiz?.results?.graduationText}
                                                        </Text>
                                                    </View>
                                                </View>)}
                                            </View>
                                            <View>
                                                {/*TODO: V kurzu si neprospěl text je trochu divnej, odstraněno
                                                dataQuiz?.results?.graduation === 'failed' && (*/}
                                                {/*    <Text style={styles.txt3}>*/}
                                                {/*        {t('learningScreen.quiz.result.failed', {*/}
                                                {/*            result: Math.round(dataQuiz?.results?.result),*/}
                                                {/*            grade: dataQuiz?.results?.passing_grade || 0,*/}
                                                {/*        })}*/}
                                                {/*    </Text>*/}
                                                {/*)}*/}

                                                {
                                                    missingQuestions ? <ErrorView
                                                            title={t('dataNotFound')}
                                                            titleStyle={{color: Colors.scholastik.textPrimary}}
                                                            containerStyle={{
                                                                marginTop: "30%",
                                                                width: deviceWidth-40
                                                            }}
                                                            IconComponent={IconM}
                                                            icon="cable-data"
                                                            iconStyle={{color: Colors.scholastik.textPrimary}}
                                                        >
                                                            <PlainButton
                                                                onPress={() => this.refresh()}
                                                                containerStyle={{marginTop: 30}}
                                                                title={t('refresh')}
                                                                body={t('canHelpUnknown')}
                                                            />

                                                        </ErrorView> :
                                                        <>
                                                            { percentil !== undefined &&
                                                                <View style={styles.viewQuestion1}>

                                                                    <View style={{flexDirection: "row"}}>
                                                                        <Text style={styles.txt2}>
                                                                            {t('learningScreen.quiz.result.percentil')}
                                                                        </Text>
                                                                        <Hint hint={t('learningScreen.quiz.percentil')} iconStyle={{marginLeft: 5}}/>
                                                                    </View>
                                                                    <Text style={styles.txt2}>
                                                                        {isNaN(percentil) ? "-" : `${Math.round((percentil * 10000)) / 100}`}
                                                                    </Text>
                                                                </View>
                                                            }
                                                            <View style={styles.viewQuestion1}>
                                                                <Text style={styles.txt2}>
                                                                    {t('learningScreen.quiz.result.questions')}
                                                                </Text>
                                                                <Text style={styles.txt2}>
                                                                    {dataQuiz?.results?.question_count}
                                                                </Text>
                                                            </View>
                                                            <View style={styles.viewQuestion1}>
                                                                <Text style={styles.txt2}>
                                                                    {t('learningScreen.quiz.result.correct')}
                                                                </Text>
                                                                <Text style={styles.txt2}>
                                                                    {dataQuiz?.results?.question_correct}
                                                                </Text>
                                                            </View>
                                                            <View style={styles.viewQuestion1}>
                                                                <Text style={styles.txt2}>
                                                                    {t('learningScreen.quiz.result.wrong')}
                                                                </Text>
                                                                <Text style={styles.txt2}>
                                                                    {dataQuiz?.results?.question_wrong}
                                                                </Text>
                                                            </View>
                                                            <View style={styles.viewQuestion1}>
                                                                <Text style={styles.txt2}>
                                                                    {t('learningScreen.quiz.result.skipped')}
                                                                </Text>
                                                                <Text style={styles.txt2}>
                                                                    {dataQuiz?.results?.question_empty}
                                                                </Text>
                                                            </View>
                                                            { (dataQuiz?.results?.subtracted !== undefined) &&
                                                                <View style={styles.viewQuestion1}>
                                                                    <Text style={styles.txt2}>
                                                                        {t('learningScreen.quiz.result.negativePoints')}
                                                                    </Text>
                                                                    <Text style={styles.txt2}>
                                                                        - {Math.round(dataQuiz?.results?.subtracted*100)/100}
                                                                    </Text>
                                                                </View>
                                                            }
                                                            <View style={styles.viewQuestion1}>
                                                                <Text style={styles.txt2}>
                                                                    {t('learningScreen.quiz.result.points')}
                                                                </Text>
                                                                <Text style={styles.txt2}>
                                                                    {Math.round(dataQuiz?.results?.user_mark*100)/100}
                                                                </Text>
                                                            </View>
                                                        </>
                                                }
                                                {
                                                    dataQuiz?.timed && (<View style={styles.viewQuestion1}>
                                                        <Text style={styles.txt2}>
                                                            {t('learningScreen.quiz.result.timespent')}
                                                        </Text>
                                                        <Text style={styles.txt2}>
                                                            {dataQuiz?.results?.time_spend}
                                                        </Text>
                                                    </View>)
                                                }
                                            </View>
                                            {!missingQuestions && (<View style={styles.viewBottom}>
                                                {(data?.meta_data?._lp_retake_count < 0 || data?.meta_data?._lp_retake_count - data.results?.retaken >
                                                    0) && (
                                                    <TouchableOpacity
                                                        style={styles.btnRetoke}
                                                        onPressIn={() => this.onStartQuiz()}
                                                    >
                                                        <Text style={styles.txtRetoke}>
                                                            {t('learningScreen.quiz.result.btnRetake', {
                                                                count:
                                                                    data?.meta_data?._lp_retake_count -
                                                                    data.results?.retaken > 0 ? `(${data?.meta_data?._lp_retake_count -
                                                                    data.results?.retaken}x)` : "",
                                                            })}
                                                        </Text>
                                                    </TouchableOpacity>
                                                )}
                                                <TouchableOpacity
                                                    style={styles.btnReview}
                                                    onPressIn={() => {
                                                        this.setState({showReviewQuestionId: 0});
                                                    }}
                                                >
                                                    <Text style={styles.txtReview}>
                                                        {t('learningScreen.quiz.result.btnReview')}
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>)}
                                        </View>
                                    )}
                                </View>
                                {isAssignment && (
                                    // <Assignment data={dataAssignment} navigation={navigation}/>
                                    <Text>We're sorry: Assignments are not available: please, upgrade the app.</Text>
                                )}
                                {isQuiz && isTrulyStartQuiz && (
                                    <View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Text
                                                numberOfLines={1}
                                                style={[styles.title, {flex: 1, paddingRight: 10, marginBottom: 10}]}
                                            >
                                                {data?.name || dataQuiz?.name}
                                            </Text>
                                        </View>
                                        {dataQuiz.questions.length > 1 && (
                                            <View style={[styles.viewPage, {marginTop: 0}]}>
                                                <TouchableOpacity
                                                    style={styles.btnPage}
                                                    onPressIn={() => this.onPrevQuiz()}
                                                    disabled={isFirst}
                                                >
                                                    <IconI name="chevron-back-outline"/>
                                                </TouchableOpacity>
                                                <FlatList
                                                    ref={(ref) => {
                                                        this.flatListRef = ref;
                                                    }}
                                                    data={dataQuiz.questions}
                                                    horizontal
                                                    style={styles.flatPage}
                                                    // initialScrollIndex={pageActive || 0} todo not working
                                                    contentContainerStyle={styles.flatPageContainer}
                                                    showsHorizontalScrollIndicator={false}
                                                    keyExtractor={(item, index) => String(index)}
                                                    renderItem={({item, index}) =>
                                                        this.renderHeaderItem({item, index})
                                                    }
                                                />
                                                <TouchableOpacity
                                                    style={styles.btnPage}
                                                    onPressIn={() => this.onNextQuiz()}
                                                    disabled={isLast}
                                                >
                                                    <IconI name="chevron-forward-outline"/>
                                                </TouchableOpacity>
                                            </View>
                                        )}

                                        {itemQuestion && (
                                            <View style={styles.viewQuestion}>

                                                {/*{renderHtml(itemQuestion.title, {dispatch: dispatch})}*/}
                                                {renderHtml(itemQuestion.content, {dispatch: dispatch})}

                                                <View style={{marginTop: 10}}>
                                                    {itemQuestion.type === 'single_choice' &&
                                                        itemQuestion.options.map((item, i) => this.renderAnswerButton(i,
                                                            item.title,
                                                            itemQuestion?.answer && itemQuestion.answer.some(
                                                                (x) => x.value === item.value),
                                                            () => this.selectQuestion(item),
                                                            this.isDisable(itemQuestion),
                                                            false,
                                                            correctAnswerIndex.includes(i)
                                                        ))}

                                                    {itemQuestion.type === 'true_or_false' &&
                                                        itemQuestion.options.map((item, i) => this.renderAnswerButton(i,
                                                            item.title,
                                                            itemQuestion?.answer && itemQuestion.answer.value === item.value,
                                                            () => this.selectQuestion(item),
                                                            this.isDisable(itemQuestion),
                                                            false,
                                                            correctAnswerIndex.includes(i)
                                                        ))}

                                                    {itemQuestion.type === 'multi_choice' &&
                                                        itemQuestion.options.map((item, i) => this.renderAnswerButton(i,
                                                            item.title,
                                                            itemQuestion?.answer && itemQuestion.answer.some(
                                                                (x) => x.value === item.value
                                                            ),
                                                            () => this.selectQuestion(item),
                                                            this.isDisable(itemQuestion),
                                                            true,
                                                            correctAnswerIndex.includes(i)
                                                        ))}
                                                    {itemQuestion.type === 'fill_in_blanks' && (
                                                        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                                                            {this.renderFillInBlanks()}
                                                        </View>
                                                    )}
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                )}
                            </ScrollView>

                            {/*{dataQuiz?.checked_questions.includes(itemQuestion.id) && ( <View*/}
                            {/*      style={{*/}
                            {/*      marginLeft: 16,*/}
                            {/*      padding: 8,*/}
                            {/*      alignSelf: 'flex-start',*/}
                            {/*    }}*/}
                            {/*      >*/}
                            {/*      <Text>*/}
                            {/*      {t('learningScreen.quiz.point', {*/}
                            {/*        point: this.itemCheck.find(*/}
                            {/*            (x) => x.id === itemQuestion.id*/}
                            {/*        )?.result?.mark,*/}
                            {/*      })}*/}
                            {/*      </Text>*/}
                            {/*      </View>*/}
                            {/*)}*/}
                            {/*<View style={{ height: 36 }} />*/}


                            {isTrulyStartQuiz && (questionSolutionIndex >= 0 ?
                                <Explanation
                                    dispatch={dispatch}
                                    maxHeightPercent={50}
                                    question={itemQuestion}
                                    solution={this.itemCheck[questionSolutionIndex]}
                                    active={!! this.state.activeExplanations[itemQuestion.id]}
                                    onToggle={this.toggleExplanation.bind(this)}/> :
                                (dataQuiz?.checked_questions && dataQuiz?.checked_questions.includes(itemQuestion?.id) ?
                                    (<Text style={{
                                        color: Colors.scholastik.purple, fontWeight: 'bold',
                                        position: 'absolute', bottom: 100, alignSelf: 'center'
                                    }}>
                                        {t('learningScreen.quiz.questionAnswered')}
                                    </Text>) : (<View/>)))
                            }

                            {  //todo instant check is false with first request and true with subsequen
                                (isTrulyStartQuiz && itemQuestion && !this.state.evaluating &&
                                    (<View style={styles.bottomBar}>
                                        {dataQuiz?.instant_check ?
                                            ((dataQuiz?.checked_questions && dataQuiz?.checked_questions.includes(itemQuestion.id))
                                            || questionSolutionIndex >= 0 ?
                                                (<View style={{
                                                        flexDirection: "row",
                                                        justifyContent: 'space-between',
                                                        width: '100%'
                                                    }}>
                                                        <TouchableOpacity
                                                            style={[styles.buttonAnswer, {
                                                                backgroundColor: Colors.scholastik.white,
                                                                flex: 1
                                                            },
                                                                isFirst && {borderColor: Colors.scholastik.gray}]}
                                                            onPressIn={() => this.onPrevQuiz()}
                                                        >
                                                            <Text style={{
                                                                color: Colors.scholastik[isFirst ? 'gray' : 'purple'],
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {t('learningScreen.quiz.prevQuestionShort')}
                                                            </Text>
                                                        </TouchableOpacity>
                                                        <TouchableOpacity
                                                            style={[styles.buttonAnswer, {
                                                                backgroundColor: isLast ? Colors.scholastik.purple : Colors.scholastik.white,
                                                                flex: 1,
                                                            }]}
                                                            onPressIn={() => {
                                                                if (isLast) this.onFinishQuiz();
                                                                else this.onNextQuiz();
                                                            }}>
                                                            <Text style={{
                                                                color: isLast ? Colors.scholastik.white : Colors.scholastik.purple,
                                                                fontWeight: 'bold'
                                                            }}>
                                                                {t(isLast ? 'learningScreen.quiz.submitSet' :
                                                                    'learningScreen.quiz.nextQuestionShort')}
                                                            </Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                ) : (<TouchableOpacity
                                                        style={[styles.buttonAnswer, {
                                                            backgroundColor: itemQuestion?.answer ? Colors.scholastik.purple : Colors.scholastik.white,
                                                        }]}
                                                        disabled={!! this.itemCheck.find(
                                                            (x) => x.id === itemQuestion.id
                                                        )}
                                                        onPressIn={() => this.onCheckImmediate()}>
                                                        <Text style={{
                                                            color: itemQuestion?.answer ? Colors.scholastik.white : Colors.scholastik.purple,
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {t('learningScreen.quiz.btnCheck')}
                                                        </Text>
                                                    </TouchableOpacity>
                                                )) : (
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: 'space-between',
                                                        width: '100%'
                                                    }}>
                                                    <TouchableOpacity
                                                        style={[styles.buttonAnswer, {
                                                            backgroundColor: Colors.scholastik.white,
                                                            flex: 1,
                                                        },
                                                            isFirst && {borderColor: Colors.scholastik.gray}]}
                                                        onPressIn={() => this.onPrevQuiz()}
                                                    >
                                                        <Text style={{
                                                            color: Colors.scholastik[isFirst ? 'gray' : 'purple'],
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {t('learningScreen.quiz.prevQuestionShort')}
                                                        </Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        style={[styles.buttonAnswer, {
                                                            backgroundColor: isLast ? Colors.scholastik.purple : Colors.scholastik.white,
                                                            flex: 1,
                                                        }]}
                                                        onPressIn={() => {
                                                            if (isLast) this.onFinishQuiz();
                                                            else this.onNextQuiz();
                                                        }}>
                                                        <Text style={{
                                                            color: isLast ? Colors.scholastik.white : Colors.scholastik.purple,
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {t(isLast ? 'learningScreen.quiz.btnFinish' :
                                                                'learningScreen.quiz.nextQuestionShort')}
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                            )}
                                    </View>))
                            }

                            {/*<Modal*/}
                            {/*    isVisible={isShowMenu}*/}
                            {/*    backdropOpacity={0.3}*/}
                            {/*    // deviceWidth={width}*/}
                            {/*    // deviceHeight={height}*/}
                            {/*    style={styles.modal}*/}
                            {/*    animationIn="slideInLeft"*/}
                            {/*    animationOut="slideOutLeft"*/}
                            {/*    useNativeDriver*/}
                            {/*    coverScreen*/}
                            {/*    onBackButtonPress={() => {*/}
                            {/*        this.setState({isShowMenu: false});*/}
                            {/*    }}*/}
                            {/*    onBackdropPress={() => {*/}
                            {/*        this.setState({isShowMenu: false});*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <View style={styles.viewModalMenu}>*/}
                            {/*        <View style={styles.viewHeaderModalMenu}>*/}
                            {/*            <Text*/}
                            {/*                style={[styles.title, {flex: 1, marginRight: 10, flexWrap: 'wrap'}]}*/}
                            {/*                numberOfLines={1}*/}
                            {/*            >*/}
                            {/*                {data?.name || course?.data?.name || sectionTitle || ''}*/}
                            {/*            </Text>*/}
                            {/*            <TouchableOpacity>*/}
                            {/*                style={styles.btnCloseMenu}*/}
                            {/*                onPress={() => {*/}
                            {/*                this.setState({isShowMenu: false});*/}
                            {/*            }}*/}
                            {/*                hitSlop={{top: 30, bottom: 30, left: 30, right: 30}}*/}
                            {/*                >*/}
                            {/*                <Image source={Images.iconClose} style={styles.iconBack}/>*/}
                            {/*            </TouchableOpacity>*/}
                            {/*        </View>*/}
                            {/*        <View style={styles.line}/>*/}
                            {/*        <ScrollView*/}
                            {/*            showsVerticalScrollIndicator={Platform.OS === "web"}*/}
                            {/*            removeClippedSubviews={false}*/}
                            {/*        >*/}
                            {/*            <View style={styles.contentMenu}>*/}
                            {/*                {course?.data?.sections && (*/}
                            {/*                    <Accordion*/}
                            {/*                        sections={course?.data?.sections}*/}
                            {/*                        underlayColor="transpation"*/}
                            {/*                        activeSections={activeSections}*/}
                            {/*                        renderHeader={this.renderLeftMenuAccordionHeader}*/}
                            {/*                        renderContent={this.renderLeftMenuAccordionContent}*/}
                            {/*                        onChange={(value) => {*/}
                            {/*                            this.setState({activeSections: value});*/}
                            {/*                        }}*/}
                            {/*                    />*/}
                            {/*                )}*/}
                            {/*            </View>*/}
                            {/*        </ScrollView>*/}
                            {/*    </View>*/}
                            {/*</Modal>*/}
                            {this.state.showReviewQuestionId !== undefined && (
                                <ReviewQuiz
                                    dispatch={dispatch}
                                    data={data}
                                    item={this.item}
                                    activeQuestionIndex={this.state.showReviewQuestionId}
                                    isShowReview={true}
                                    onClose={() => this.setState({showReviewQuestionId: undefined})}
                                />
                            )}
                        </>)}
                </ContentMiddleLayout>
            </CommonAppLayout>;
    }
}

const mapStateToProps = ({course, user, failed, cached, common, network, pins, category}) => ({
    course, user, failed, cached, common, network, pins, category
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Learning));
