import React from 'react';
import {createStackNavigator} from "@react-navigation/stack";
import web from '../screens/web';
import s404 from '../screens/404';
import HomeBar from "./home-bar";

const Stack = createStackNavigator();
function AppNavigator() {
    return (
        <Stack.Navigator
            header={null}
            initialRouteName="HomeTabScreen"
            screenOptions={
                {
                    cardStyle: {flex: 1},
                    gesturesEnabled: true,
                    presentation: 'card',

                    headerShown: false,
                    header: null,
                }
            }
        >
            <Stack.Screen
                name="HomeTabScreen"
                component={HomeBar}
            />
            <Stack.Screen
                name="WebScreen"
                component={web}
                options={{ headerShown: false, title: 'Web' }}
            />
            <Stack.Screen
                name="Screen404"
                component={s404}
                options={{ headerShown: false, title: 'Nenalezeno' }}
            />
        </Stack.Navigator>
    );
}



export default AppNavigator;
