export class LRUCache {
    constructor(capacity) {
        this.capacity = capacity;
        this.cache = new Map();
    }

    get(key) {
        if (!this.cache.has(key)) {
            return undefined;
        }
        const value = this.cache.get(key);
        // Move the accessed key to the end to mark it as most recently used
        this.cache.delete(key);
        this.cache.set(key, value);
        return value;
    }

    put(key, value) {
        if (this.cache.has(key)) {
            // Remove the old entry
            this.cache.delete(key);
        } else if (this.cache.size >= this.capacity) {
            // Remove the least recently used entry
            // keys().next().value gives us the first item's key
            this.cache.delete(this.cache.keys().next().value);
        }
        // Insert the new entry
        this.cache.set(key, value);
    }
}
