import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import { faSchool } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import {getTabBarStyle, renderBottomTabItem, renderBottomTabText} from "./home-tabbar-common";
import i18next from "../common/translations";
import Board from "../screens/board";
import Start from "../screens/start";
import React from "react";
import ProfileRouter from "./home/profile-router";
import {withSafeAreaInsets} from "react-native-safe-area-context";
import SubjectsRouter from "./home/subjects-router";
import { Colors } from 'app-assets';
import {getFocusedRouteNameFromRoute} from "@react-navigation/native";

const Tab = createBottomTabNavigator();
let tabBarGlobalStyle = {};
const HomeBar = function (props) {
    tabBarGlobalStyle = getTabBarStyle(props);
    return (
        <Tab.Navigator
            header={null}
            initialRouteName={'SubjectsStackScreen'}
            swipeEnabled={false}
            animationEnabled={true}
            screenOptions={({ route }) => ({
                tabBarIcon: ({ focused, color, horizontal }) => {
                    let icon;
                    switch (route.name) {
                        case 'SubjectsStackScreen':
                            icon = faSchool;
                            break;
                        case 'Board':
                            icon = faPaperclip;
                            break;
                        case 'Start':
                            icon = faClockRotateLeft;
                            break;
                        case 'ProfileStackScreen':
                            icon = faUser;
                            break;
                        default:
                            icon = 'home';
                    }
                    return renderBottomTabItem(icon, focused);
                },

                tabBarLabel: ({ focused, color }) => {
                    let name;

                    switch (route.name) {
                        case 'SubjectsStackScreen':
                            name = i18next.t('bottomNavigation.subject');
                            break;
                        case 'Board':
                            name = i18next.t('bottomNavigation.courses');
                            break;
                        case 'Start':
                            name = i18next.t('bottomNavigation.history');
                            break;
                        case 'ProfileStackScreen':
                            name = i18next.t('bottomNavigation.profile');
                            break;
                        default:
                            name = i18next.t('bottomNavigation.home');
                    }

                    return renderBottomTabText(name, focused);
                },

                showLabel: true,
                activeTintColor: Colors.scholastik.blue,
                tabBarLabelPosition: "below-icon",
                tabBarStyle: tabBarGlobalStyle,

            })}
        >
            <Tab.Screen name="SubjectsStackScreen" component={SubjectsRouter} options={(params) => {
                const focusedRouteName =
                    getFocusedRouteNameFromRoute(params.route) || 'SubjectsStackScreen';
                tabBarGlobalStyle.display = ["Subjects", "SubjectsStackScreen"].includes(focusedRouteName) ? 'flex' : 'none';
                return {
                    tabBarStyle: tabBarGlobalStyle,
                    headerShown: false,
                    title: 'Kurzy'
                };
            }} />
            <Tab.Screen name="Board" component={Board} options={{headerShown: false, tabBarVisible: false, title: 'Nástěnka'}} />
            <Tab.Screen name="Start" component={Start} options={{headerShown: false, title: 'Historie'}} />
            <Tab.Screen name="ProfileStackScreen" component={ProfileRouter} options={{headerShown: false, title: 'Můj účet'}} />
        </Tab.Navigator>
    );
}
export default withSafeAreaInsets(HomeBar);