import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {WebView} from "react-native-webview";
import {Colors} from "app-assets";

import {  ActivityIndicator, View } from 'react-native';

const Spinner = () => (
    <View style={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: Colors.scholastik.white,
        height: '100%',
        width: '100%'
    }}>
        <ActivityIndicator size="large" color={'black'} />
    </View>
);

//todo react memo?
class Web extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const href = this.props.href || this.props.url;
        return (
            <WebView
                source={{
                    uri: href || '',
                }}
                {...this.props}
                renderLoading={Spinner}
                startInLoadingState={true}
                originWhitelist={['*']}
                javaScriptEnabledAndroid={true}
            />
        );
    }
}

export default withTranslation()(Web);
