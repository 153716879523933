import { StyleSheet, Dimensions, Platform } from 'react-native';
import {Colors} from 'app-assets';

export default StyleSheet.create({
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 36,
    color: Colors.scholastik.white,
    textAlign: 'center',
    maxWidth: '70%',
    alignSelf: 'center',
    marginBottom: 20
  },
  noHistory: {
    fontFamily: 'Poppins-Medium',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 16,
    color: Colors.scholastik.textTertiary,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center',
    paddingTop: 25,
  },
  noHistoryDetails: {
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 16,
    color: Colors.scholastik.textTertiary,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center',
    paddingTop: 25,
  },
});
