import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import React from 'react';
import {Colors, Images} from '../assets';
import PlainButton from '../../src/component/common/plainButton';

export class ProductRenderers {
  constructor(translator, onClick) {
    this.t = translator;
    this.onclick = onClick;
  }

  appNameRemover = t => t.replace(/\([^)]*\)/g, '');

  capitalizeFirstLetter = string =>
    string && string.charAt(0).toUpperCase() + string.slice(1);

  renderersAndroid = {
    'osp.scholastik': (sub, i, offer) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.osp.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    'zsv.scholastik': (sub, i, offer) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.zsv.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    'tsp.scholastik': (sub, i, offer) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.tsp.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    default: (sub, i, offer) => {
      return (
        <PlainButton
          key={String(i)}
          containerStyle={{marginHorizontal: 25, marginTop: 20}}
          onPress={() => this.onclick(sub, offer.offerToken)}
          title={`${this.t(
            'subscriptionScreen.purchase',
          )} ${this.appNameRemover(sub.title)}`}
          body={this.getSubscriptionDetailsAndroid(offer)}
        />
      );
    },
  };

  getSubscriptionDetailsAndroid(offer) {
    return '';

    // todo somehow diferentiate offers within a sub, but the data available 'P1M' does not tell much (docs missing)
    // console.log(offer.pricingPhases.pricingPhaseList);
    // return offer.pricingPhases.pricingPhaseList
    //     .map((ppl) => ppl.billingPeriod)
    //     .join(',');
  }

  iosDuration = (t, str) => {
    return (
      {
        MONTH: this.t('subscriptionScreen.forMonth'),
        DAY: this.t('subscriptionScreen.forDay'),
        WEEK: this.t('subscriptionScreen.forWeek'),
      }[str] || this.t('subscriptionScreen.unknownDuration')
    );
  };

  renderersIos = {
    'osp.premium': (sub, i) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.osp.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    'zsv.premium': (sub, i) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.zsv.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    'tsp.premium': (sub, i) => {
      return (
        <View key={String(i)}>
          <Text
            style={{
              fontFamily: 'Poppins-Medium',
              fontSize: 18,
              marginTop: 10,
              marginHorizontal: 25,
            }}>
            {this.capitalizeFirstLetter(this.appNameRemover(sub.title))}
          </Text>
          <Text style={{fontFamily: 'Poppins', paddingHorizontal: 20}}>
            {this.t('products.tsp.description')}
          </Text>
          <View style={{marginHorizontal: 20, marginTop: 15}}>
            <Text
              style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 15}}>
              {this.t('subscriptionScreen.contains')}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet1')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet2')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet3')}`}
            </Text>
            <Text style={{fontFamily: 'Poppins', marginLeft: 15}}>
              {`• ${this.t('products.any.bullet4')}`}
            </Text>
          </View>
        </View>
      );
    },
    default: (sub, i, token) => {
      return (
        <View key={String(i)}>
          <PlainButton
            containerStyle={{marginHorizontal: 25, marginTop: 20}}
            onPress={() => this.onclick(sub)}
            title={`${this.t(
              'subscriptionScreen.purchase',
            )} ${this.appNameRemover(sub.title)}`}>
            <View style={{flexDirection: 'row'}}>
              <Text style={{color: 'white'}}>
                {this.iosDuration(this.t, sub?.subscriptionPeriodUnitIOS)}
              </Text>
              <Text style={{color: 'white'}}>
                {` - ${sub?.localizedPrice}`}
              </Text>
            </View>
          </PlainButton>
          <TouchableOpacity
            style={{marginTop: 10, paddingHorizontal: 15, alignItems: 'center'}}
            onPress={() => this.onclick(sub)}>
            <Text style={{color: Colors.scholastik.darkGrayText}}>
              {this.t('subscriptionScreen.restoreAppStore')}
            </Text>
          </TouchableOpacity>
        </View>
      );
    },
  };

  renderSubscription(sub, j, preview) {
    const result = [];
    let i = 0;

    if (Platform.OS === 'android') {
      if (!sub?.subscriptionOfferDetails) return [];
      if (
        preview &&
        sub.subscriptionOfferDetails.length > 0 &&
        this.renderersAndroid[sub.productId]
      ) {
        result.push(
          this.renderersAndroid[sub.productId](
            sub,
            j + i,
            sub?.subscriptionOfferDetails[i],
          ),
        );
      }
      for (; i < sub.subscriptionOfferDetails.length; i++) {
        result.push(
          this.renderersAndroid.default(
            sub,
            j + i + 1,
            sub?.subscriptionOfferDetails[i],
          ),
        );
      }
      return result;
    }

    if (preview && this.renderersIos[sub.productId]) {
      result.push(this.renderersIos[sub.productId](sub, j + i));
    }
    result.push(this.renderersIos.default(sub, j + i + 1));
    return result;
  }
}
