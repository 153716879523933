import { StyleSheet, Dimensions, Platform } from 'react-native';
import { getStatusBarHeight } from 'app-common';
import {Colors} from "app-assets";

const deviceWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === "web";

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
    zIndex: 1,
    paddingTop: Platform.OS === 'ios' ? 0 : getStatusBarHeight(),
  },
  header: {
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    paddingHorizontal: 16,
  },
  header1: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menu: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: 44,
  },
  btnGenaral: {
    marginRight: 31,
    marginBottom: 4,
  },
  txtActive: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 24,
  },
  lineButton: {
    height: 2,
    width: '100%',
    backgroundColor: Colors.scholastik.textPrimary,
  },
  inputSearch: {
    flex: 1,
    marginRight: 8,
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.scholastik.textPrimary,
  },
  txtPhone: {
    marginLeft: 14,
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.scholastik.textPrimary,
  },
  iconBack: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.textPrimary,
  },
  icon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 36,
  },
  fullName: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
  },
  txt1: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
    marginLeft: 20,
  },
  txtBtnUpload: {
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.scholastik.textPrimary,
  },
  viewSearch: {
    backgroundColor: Colors.scholastik.white,
    width: 40,
    height: 40,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',

    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },

  iconSearch: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.textPrimary,
  },
  viewFilter: {
    backgroundColor: Colors.scholastik.white,
    width: 64,
    height: 24,
    borderRadius: 60,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',

    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  txtFilter: {
    fontFamily: 'Poppins',
    fontSize: 10,
    lineHeight: 15,
    marginRight: 4,
  },
  viewModalFilter: {
    width: 107,
    height: 131,
    paddingHorizontal: 18,
    paddingVertical: 16,
    borderRadius: 6,
    backgroundColor: Colors.scholastik.white,
    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  txtFilterItem: {
    marginBottom: 10,
    fontFamily: 'Poppins',
    fontSize: 10,
    lineHeight: 15,
    color: '#A9A9A9',
  },
  content: {
    paddingHorizontal: 16,
    marginTop: 20,
  },
  avatar: {
    width: 46,
    height: 46,
    borderRadius: 23,
  },
  border: {
    width: deviceWidth * 0.7,
    height: 1,
    backgroundColor: '#F0F0F0',
    alignSelf: 'center',
    marginTop: 35,
    marginBottom: 40,
  },
  titleChild: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
  },
  viewInputBio: {
    height: 100,
    borderWidth: 1,
    borderColor: '#EBEBEB',
    borderRadius: 8,
    marginBottom: 16,
  },
  textInputBio: {
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 19,
    margin: 20,
    marginTop: 15,
    color: '#949494',
    textAlignVertical: 'top',
    outlineStyle: isWeb ? 'none' : undefined,
    flex: 1,
  },
  viewInput: {
    height: 42,
    borderWidth: 1,
    borderColor: '#EBEBEB',
    borderRadius: 8,
    marginBottom: 16,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  btnViewPass: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtInput: {
    paddingTop: 0,
    paddingBottom: 0,
    textAlignVertical: 'center',
    fontFamily: 'Poppins',
    fontSize: 13,
    marginHorizontal: 20,
    color: '#949494',
    flex: 1,
    outlineStyle: isWeb ? 'none' : undefined,
  },
});
