import { StyleSheet, Dimensions, Platform } from 'react-native';
import { getStatusBarHeight, ifIphoneX } from 'app-common';
import {Colors} from 'app-assets';

const deviceWidth = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
    zIndex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: "100%",
    paddingHorizontal: 20,
    paddingTop: 25
  },
  viewInput: {
    borderRadius: 12,
    flex: 1,
    height: 40,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F3F3F3',
    marginRight: 8,
  },
  buttonAnswer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    height: 50,
    borderColor: Colors.scholastik.purple,
    borderWidth: 2,
    marginHorizontal: 22,
    marginVertical: 10,
    marginBottom: 10,
  },
  bottomBar: {
    elevation: 11,
    zIndex: 11,
    position: 'absolute',
    width: '100%',
    backgroundColor: Colors.scholastik.white,
    bottom: 0,
    height: 70,
    paddingBottom: 0,
  },
  inputSearch: {
    flex: 1,
    fontFamily: 'Poppins',
    fontSize: 10,
    lineHeight: 15,
    color: Colors.scholastik.textPrimary,
  },
  txtPhone: {
    marginLeft: 14,
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.scholastik.textPrimary,
  },
  iconMenu: {
    height: 22,
    width: 22,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.textPrimary,
  },
  iconBack: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.purple,
    marginRight: 10,
  },
  icon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 27,
    color: Colors.scholastik.textPrimary,
  },
  btn: {
    flexDirection: 'row',
    position: 'absolute',
    ...ifIphoneX(
        {
          bottom: 50,
          right: 16,
        },
        {
          bottom: 16,
          right: 16,
        }
    ),

    justifyContent: 'center',
    alignItems: 'center',
  },
  txtBtn: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    marginRight: 9,
  },
  txt1: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
  },
  // txtContent: {
  //   fontFamily: 'Poppins',
  //   fontSize: 14,
  //   lineHeight: 21,
  //   color: '#8D8D8D',
  //   marginTop: 6,
  // },

  lessonTitle: {
    fontFamily: 'Poppins-Light',
    fontWeight: '500',
    fontSize: 25,
    lineHeight: 27,
    flexWrap: 'wrap',
    flex: 1,
  },
  txtTime: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 20,
    color: Colors.scholastik.textPrimary,
    marginHorizontal: 9,
  },
  content: {
    marginTop: 15,
    paddingHorizontal: 20
  },
  txtContent: {
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 20,
    color: Colors.scholastik.textPrimary,
    fontWeight: '300',
    marginTop: 20,
  },
  viewModalMenu: {
    width: '88%',
    minWidth: 250,
    height: '100%',
    backgroundColor: Colors.scholastik.white,
    borderColor: '#FBC815',
    borderRightWidth: 3,
  },
  viewHeaderModalMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    marginTop: 25,
    marginLeft: 16,
    backgroundColor: 'red'
  },
  btnCloseMenu: {
    backgroundColor: '#FBC815',
    width: 47,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
  },
  line: {
    width: (333 / 375) * deviceWidth - 32,
    marginBottom: 30,
    marginTop: 22,
    backgroundColor: '#F0F0F0',
    height: 1,
    alignSelf: 'center',
  },
  contentMenu: {
    marginHorizontal: 16,
  },
  txtSubTitle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 10,
    justifyContent: 'flex-start',
    flex: 1,
  },
  txtLength: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 10,
  },
  txtOverview: {
    marginTop: 13,
    fontFamily: 'Poppins-ExtraLight',
    fontSize: 13,
    lineHeight: 20,
    color: Colors.scholastik.textPrimary,
    fontWeight: '300',
  },
  subTitle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalHours: {
    fontFamily: 'Poppins',
    fontSize: 10,
    marginLeft: 10,
    color: '#8F8F8F',
    fontWeight: '300',
  },
  txtItemLession: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 20,
    color: '#4E4E4E',
    fontWeight: '300',
    marginLeft: 10,
    flex: 1,
  },
  btnFinish: {
    backgroundColor: Colors.scholastik.purple,
    borderRadius: 6,
    paddingHorizontal: 21,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  btnFinishLesson: {
    backgroundColor: Colors.scholastik.purple,
    borderRadius: 4,
    paddingHorizontal: 30,
    paddingBottom: 10,
    paddingTop: 12,
    width: '100%',
    height: 45,
    flex: 1,
    justifyContent: 'center',

    alignItems: 'center',
    alignSelf: 'center',
  },
  btnFinishCourse: {
    backgroundColor: '#222',
    borderRadius: 6,
    // width: 80,
    paddingHorizontal: 21,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    right: 0,
    top: 0,
  },

  txtFinish: {
    fontFamily: 'Poppins-Medium',
    fontSize: 17,
    lineHeight: 21,
    color: Colors.scholastik.white,
    fontWeight: '500',
  },
  txtFinished: {
    fontFamily: 'Poppins-Light',
    fontSize: 20,
    lineHeight: 21,
    marginRight: 10,
    color: Colors.scholastik.greenDark,
    fontWeight: 'bold',
  },
  btnPage: {
    width: 25,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E4E4E4',
    marginHorizontal: 3,
  },
  txtPage: {
    fontFamily: 'Poppins-Medium',
    fontSize: 10,
    lineHeight: 20,
    color: '#000',
    fontWeight: '500',
  },
  viewPage: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 23,
  },
  flatPage: {
    flex: 1,
    alignSelf: 'center',
    marginHorizontal: 40,
  },
  flatPageContainer: {
  },
  viewQuestion: {
    marginTop: 23,
    paddingBottom: 120
  },
  itemQuestion: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.scholastik.gray,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
    marginVertical: 4,
  },
  txtTitleQuestion: {
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 20,
    color: '#000',
    fontWeight: '500',
  },
  txtItemQuestionIcon: {
    fontFamily: 'Poppins',
    fontSize: 15,
    lineHeight: 19,
    marginLeft: 9,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  txtItemQuestion: {
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 19,
    marginLeft: 9,
    paddingRight: 60,
  },
  viewBtnBottom: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  modal: {
    backgroundColor: Colors.scholastik.white,
    margin: 0,
    width: '80%',
  },
  btnSubmit: {
    height: 50,
    flex: 1,
    backgroundColor: Colors.scholastik.purple,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    marginRight: 10,
  },
  txtBtnSubmit: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    lineHeight: 21,
    color: '#000',
    fontWeight: '500',
  },
  btnNext: {
    height: 50,
    width: 50,
    backgroundColor: '#F0F0F0',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  btnHint: {
    height: 50,
    width: 50,
    backgroundColor: '#626FE2',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,

    marginRight: 10,
  },
  iconNext: {
    resizeMode: 'contain',
    width: 14,
    height: 14,
  },
  viewNextLesson: {
    marginTop: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnLession: {
    flexDirection: 'row',
    borderColor: '#E7E7E7',
    alignItems: 'center',
    borderWidth: 1,
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 8,
  },
  txtLession: {
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    lineHeight: 18,
    color: '#000',
    fontWeight: '500',
  },
  txtName: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 20,
    color: '#000',
    fontWeight: '500',
  },
  iconPreview: {
    fontSize: 16,
    color: Colors.scholastik.greenDark,
  },
  viewQuestion1: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 13,
    width: '100%',
    marginTop: 12,
  },
  overview: {
    paddingHorizontal: 20,
    width: deviceWidth - 100,
    height: '55%',
    minHeight: 200,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  txtCircle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 30,
    // lineHeight: 30,
    color: '#000',
    fontWeight: '500',
  },
  txtLable: {
    fontFamily: 'Poppins-Medium',
    fontSize: 18,
    lineHeight: 30,
    color: '#000',
    fontWeight: '500',
  },
  txtResult: {
    fontFamily: 'Poppins-Medium',
    fontSize: 28,
    color: Colors.scholastik.redDark,
    fontWeight: '500',
  },
  txt3: {
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 24,
    color: '#D03838',
    fontWeight: '500',
    marginBottom: 20,
  },
  txt2: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 13,
    lineHeight: 19,
    color: '#000',
    fontWeight: '600',
  },
  btnRetoke: {
    width: 150,
    height: 50,
    borderRadius: 8,
    backgroundColor: Colors.scholastik.blue,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  txtRetoke: {
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.scholastik.white,
    fontWeight: '500',
  },
  btnReview: {
    width: 150,
    height: 50,
    borderRadius: 8,
    backgroundColor: Colors.scholastik.purple,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  txtReview: {
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.scholastik.white,
    fontWeight: '500',
  },
  viewBottom: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
});
