/* eslint-disable import/prefer-default-export */
import Types from './types';

/**
 * Cache property
 * @param value value to cache
 * @param key key to cache
 * @param duration duration for how long
 * @param cacheType Types.CacheName type value constant member
 * @returns {{duration, cachedValue, cachedKey, type: string}}
 */
export const cacheProperty = (value, key, duration, cacheType='') => ({
  type: cacheType,
  cachedValue: value,
  cachedKey: key,
  duration: duration,
  commandType: "save",
  tStamp: Date.now(),
});
export const deleteProperty = (key, cacheType='') => ({
  type: cacheType,
  key: key,
  duration: -1,
  commandType: "save",
  tStamp: Date.now(),
});
export const eraseCache = (cacheType) => ({
  type: cacheType,
  commandType: "erase",
});


