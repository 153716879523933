import React, { PureComponent } from 'react';
import {
  Image, Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {Alert, BackHandler} from "proxy";

import { withTranslation } from 'react-i18next';
import { Images, Colors } from 'app-assets';
import { HttpAPI } from 'app-api';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'proxy';
import { ValidateEmail } from 'app-common';
import styles from './styles';
import {CommonAppLayout, ContentMiddleLayout} from "app-component";
import {VALUES} from 'app-config';
import {showLoading} from "../../actions/common";

class Forgot extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }

  handleBackPress = () => {
    this.onBack(); // works best when the goBack is async
    return true;
  };

  onBack = () => {
    this.autoGoBack();
  };

  onSend = async () => {
    const { email } = this.state;
    const { t, dispatch } = this.props;
    if (!ValidateEmail(email)) {
      Alert.alert(t('forgot.invalidMail'));
      return;
    }
    try {
      dispatch(showLoading(true));
      const response = await HttpAPI.resetEmail({ user_login: email }).then(r => r?.responseData);
      if (response.code === 'success') {
        Alert.alert(response.message, t('forgot.pleaseWait'));
      } else {
        Alert.alert(response?.message || t('forgot.unknownError'), t('forgot.unknownErrorDetails', {email: VALUES.SUPPORT_MAIL}));
      }
    } catch (e) {
      console.error(e);
      Alert.alert(t('forgot.unknownError'), t('forgot.unknownErrorDetails', {email: VALUES.SUPPORT_MAIL}));
    }
    dispatch(showLoading(false));
  };

  render() {
    const { t } = this.props;
    const { email } = this.state;
    return (
        <CommonAppLayout
            edges={['right', 'left', 'top']}
            color={Colors.scholastik.white}>
          <KeyboardAwareScrollView
              contentContainerStyle={styles.container}
              keyboardShouldPersistTaps="always"
          >
            <ScrollView
                showsVerticalScrollIndicator={Platform.OS === "web"}
                bounces={false}
                contentContainerStyle={{paddingBottom: 50}}
                keyboardShouldPersistTaps="handled"
            >
              <ContentMiddleLayout containerStyle={{ paddingHorizontal: 46,
                marginTop: 35, marginBottom: 80, height: "auto"}}>
                <TouchableOpacity
                    style={{ marginLeft: 10, width: 50, position: "absolute" }}
                    onPress={this.onBack}
                >
                  <Image source={Images.iconBack} style={styles.iconBack} />
                </TouchableOpacity>
                <View style={styles.viewLogo}>
                  <Image source={Images.LogoSchool} style={styles.logo} />
                  <Text style={styles.title}>{t('forgot.title')}</Text>
                  <Text style={styles.childTitle}>{t('forgot.description')}</Text>
                </View>
                <TextInput
                    placeholder={t('forgot.emailPlaceholder')}
                    placeholderTextColor="#9E9E9E"
                    style={styles.textInput}
                    autoCorrect={false}
                    autoCapitalize="none"
                    value={email}
                    onChangeText={(value) => this.setState({ email: value })}
                />
                <TouchableOpacity style={styles.btnSubmit} onPress={this.onSend}>
                  <Text style={styles.txtSubmit}>{t('forgot.btnSubmit')}</Text>
                </TouchableOpacity>
              </ContentMiddleLayout>
            </ScrollView>
          </KeyboardAwareScrollView>
        </CommonAppLayout>

    );
  }
}
const mapStateToProps = ({ network }) => ({
  network,
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Forgot));
