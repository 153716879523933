const Types = {
  TEST: 'TEST',
  SAVEDEBUG: 'SAVEDEBUG',
  SAVE_USER_TOKEN: 'SAVE_USER_TOKEN',
  SAVE_USER: 'SAVE_USER',
  SET_APP_OPEN: 'SET_APP_OPEN',
  NETWORK_STATUS: 'NETWORK_STATUS',
  SHOW_LOADING: 'SHOW_LOADING',
  SET_LOADING: 'SET_LOADING',
  SHOULD_REDIRECT: 'SHOULD_REDIRECT',
  RECENT_SEARCH: 'RECENT_SEARCH',
  SET_OVERVIEW: 'SET_OVERVIEW',
  SAVE_COURSE: 'SAVE_COURSE',
  SAVE_CATEGORY: 'SAVE_CATEGORY',
  SELECT_DEFAULT_CATEGORY: 'SELECT_DEFAULT_CATEGORY',
  COURSE_LIGHTBOX: 'COURSE_LIGHTBOX',
  SAVE_WISHLIST: 'SAVE_WISHLIST',
  CACHE_PROPERTY: 'CACHE_PROPERTY',
  APP_VERSION: 'APP_VERSION',
  SUBSCRIPTION_PURCHASED: 'SUBSCRIPTION_PURCHASED',
  SUBSCRIPTION_TIMED_OUT: 'SUBSCRIPTION_TIMED_OUT',
  PINS_CACHE: 'PINS_CACHE',
  PINS_UPDATE: 'PINS_UPDATE',
  PINS_DELETE: 'PINS_DELETE',
  PINS_ERASE: 'PINS_ERASE',
  PINS_METADATA: 'PINS_QUIZ_PARENT_DATA',
  CacheName: {
    cached: 'CACHE_PROPERTY' + 'cached',
    failed: 'CACHE_PROPERTY' + 'failed',
    common: 'CACHE_PROPERTY' + 'common',
    course: 'CACHE_PROPERTY' + 'course',
    debug: 'CACHE_PROPERTY' + 'debug',
    user: 'CACHE_PROPERTY' + 'user',
    wishlist: 'CACHE_PROPERTY' + 'wishlist',
  }
};

export default Types;
