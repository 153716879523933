//todo sync load on login
export const QStatus = {
    //parsed from pins store
    computeStatus: (ctx, qId, isCorrect) => {
        const statVal = ctx.pins[qId];
        const status = QStatus.getStatus(statVal);
        const pin = QStatus.pinIsEnabled(ctx, qId);

        let res = 0;
        if (isCorrect && status === 3) res = 2;
        else if (isCorrect === true) res = 1;
        else if (isCorrect === false) res = 3;
        return res + (pin ? 10 : 0);
    },
    pinIsEnabled: (ctx, qId) => {
        const pin = ctx.pins[qId];
        return QStatus.isPin(pin?.status);
    },

    //context free
    isPin: (status) => { //PIN ENABLED if second digit is odd (1)
        return Math.round((status / 10) % 100) === 1;
    },
    getStatus: (qStats) => {
        if (!qStats) return 0;
        return qStats % 10;
    },
    //QUESTION statuses encode question status - correct, wrong or corrected
    getStatusNamed: (qStats) => {
        return ['not-answered', 'correct', 're-correct', 'incorrect'][QStatus.getStatus(qStats)];
    },
};