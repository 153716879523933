import * as React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Colors} from "app-assets";

class ErrorView extends React.Component {

    render() {
        const { title, body, icon, IconComponent, titleStyle, bodyStyle } = this.props;
        const iconStyle = this.props.iconStyle || {};

        if (IconComponent && !icon) {
            throw "Invalid icon setup: specify also icon property for icon rendering.";
        }

        return (
            <View style={[styles.container, this.props.containerStyle]}>
                {typeof icon === "string" && IconComponent &&
                    <IconComponent name={icon}
                                   size={iconStyle.fontSize || iconStyle.size || 35}
                                   color={iconStyle.color || Colors.scholastik.darkGray}/>}
                {typeof title === "string" && title &&
                    <Text style={[styles.title, titleStyle]}>{title}</Text>}
                {typeof body === "string" && body &&
                    <Text style={[styles.body, bodyStyle]}>{body}</Text>}

                {this.props.children}
            </View>
        );
    }
}


const styles = StyleSheet.create({

    title: {
        fontFamily: 'Poppins',
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.scholastik.darkGray,
        paddingTop: 12,
        paddingHorizontal: 12,
    },
    body: {
        fontFamily: 'Poppins-Light',
        fontSize: 14,
        color: Colors.scholastik.textPrimary,
        padding: 12,
        textAlign: "center"
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
});

export default ErrorView;
