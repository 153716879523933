import React, { PureComponent } from 'react';
import { View, Image } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import styles from './styles/bottom-tab-item';
import { Colors } from "app-assets";

class BottomTabIcon extends PureComponent {
  render() {
    const { icon, tintColor, containerStyle } = this.props;

    return (
      <View style={[styles.container, containerStyle]}>
          <FontAwesomeIcon icon={ icon } style={{color: tintColor || Colors.scholastik.unselected}} />
      </View>
    );
  }
}
export default BottomTabIcon;
