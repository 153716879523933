import React from 'react';
import {Platform, Text, View} from "react-native";
import {Alert, FileLogger} from "proxy";
import PlainButton from "../component/common/plainButton";
import DeviceInfo from "react-native-device-info";
import {withTranslation} from "react-i18next";
import Toast from "react-native-toast-message";
import {Colors} from "app-assets";

class ErrorWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo);
    }

    render() {
        const {t} = this.props;

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <View style={{width: '100%', height: '100%', justifyContent: "center"}}>
                <Text style={{fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 20, color: Colors.scholastik.white,
                alignSelf: 'center'}}>
                    {t('errors.titleGeneralSorry')}
                </Text>
                <PlainButton
                    onPress={async () => {
                        const osBuild = await DeviceInfo.getBuildId();
                        FileLogger.sendLogFilesByEmail({
                            to: 'horak.j@oscio.cz',
                            subject: t('shareLogs.subject', { platform:Platform.OS }),
                            body: t('shareLogs.messageBodyWithError', {version: DeviceInfo.getVersion(), build: osBuild})
                        }).then(() => {
                            Toast.show({
                                type: 'info',
                                text1: t('shareLogs.thanks'),
                                text2: t('shareLogs.thanksDetails'),
                                visibilityTime: 7000,
                            });
                        }).catch(e => {
                            Alert.alert(
                                t(Platform.OS === 'ios' ? 'errors.shareLogs.iosTitle' : 'errors.shareLogs.title'),
                                t(Platform.OS === 'ios' ? 'errors.shareLogs.iosBody' : 'errors.shareLogs.body')
                            );
                        });
                    }}
                    title={t('shareLogs.buttonTitle')}
                    body={t('shareLogs.buttonDescriptionWithError')}
                >

                    {/*<TouchableOpacity*/}
                    {/*    style={{marginTop: '30%', paddingVertical: 18, paddingHorizontal: 36,*/}
                    {/*        backgroundColor: Colors.scholastik.purple, maxWidth: 250, alignSelf: 'center', borderRadius: 6}}*/}
                    {/*    ><Text style={{color: Colors.scholastik.white, fontFamily: 'Poppins', textAlign: 'center'}}>*/}
                    {/*   </Text></TouchableOpacity>*/}
                </PlainButton>

            </View>;
        }
        return this.props.children;
    }
}

export default withTranslation()(ErrorWrapper);