// import {BackHandler} from 'react-native';
export {default as Alert}  from "./stubs/Alert";

//import BackgroundTimer from 'react-native-background-timer';
export {default as BackgroundTimer} from './stubs/BackgroundTimer';

// import {BackHandler} from 'react-native';
export {default as BackHandler}  from "./stubs/BackHandler";

//import {FileLogger} from "react-native-file-logger";
export {default as FileLogger}  from "./stubs/FileLogger";

//import {ImageZoom} from "@likashefqet/react-native-image-zoom/src";
export {default as ImageZoom} from "./stubs/ImageZoom";

export { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
//export {ScrollView as KeyboardAwareScrollView} from 'react-native';

