import {
    type AlertType,
    type AlertButton,
    type AlertOptions,
} from 'react-native'

import Swal from 'sweetalert2'
import {Colors} from 'app-assets';

export type CustomAlertButton = AlertButton & {
    swalType?: 'deny' | 'cancel' | 'confirm'
}

class Alert {
    static alert(
        title: string,
        message?: string,
    buttons?: CustomAlertButton[],
    options?: AlertOptions
): void {
    buttons = (buttons || []).filter(x => typeof x === "object"); //sometimes booleans are sent due to
    const types = ['cancel', 'deny', 'confirm'].filter(x => !buttons.some(b => b.style === x));
    buttons.forEach(b => {
        if (b.style) b.swalType = b.style;
        else b.swalType = types.pop();
    });

    const confirmButton = buttons
        ? buttons.find((button) => button.swalType === 'confirm')
        : undefined;
    const denyButton = buttons
        ? buttons.find((button) => button.swalType === 'deny')
        : undefined
    const cancelButton = buttons
        ? buttons.find((button) => button.swalType === 'cancel')
        : undefined

    Swal.fire({
        title: title,
        text: message,
        showConfirmButton: !!confirmButton,
        showDenyButton: !!denyButton,
        showCancelButton: !!cancelButton,
        confirmButtonText: confirmButton?.text,
        denyButtonText: denyButton?.text,
        cancelButtonText: cancelButton?.text,
        confirmButtonColor: Colors.scholastik.purple,
        cancelButtonColor: Colors.scholastik.darkGray,
    }).then((result) => {
        if (result.isConfirmed) {
            if (confirmButton?.onPress !== undefined) {
                confirmButton.onPress()
            }
        } else if (result.isDenied) {
            if (denyButton?.onPress !== undefined) {
                denyButton.onPress()
            }
        } else if (result.isDismissed) {
            if (cancelButton?.onPress !== undefined) {
                cancelButton.onPress()
            }
        }
    })
}

static prompt(
    title: string,
    message?: string,
    callbackOrButtons?: ((text: string) => void) | CustomAlertButton[],
    type?: AlertType,
    defaultValue?: string,
    keyboardType?: string
): void {
    throw new Error('Not implemented.')
}
}

export default Alert