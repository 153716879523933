import { StyleSheet, Dimensions, Platform, StatusBar } from 'react-native';
import { getStatusBarHeight } from 'app-common';
import {Colors} from 'app-assets';

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.background,
    flex: 1,
    zIndex: 1,
    minHeight: deviceHeight,
  },
  header: {
    height: 60,
    backgroundColor: 'transparent',
    flex: 1, width: '100%',  elevation: 999,
    zIndex: 999,
  },
  iconBack: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.white,
  },
  buttonBackAbsolute: {
    position:'absolute',
    top: (Platform.OS === 'ios' ? 0 : getStatusBarHeight()) + 40,
    left: 20,
    //todo
    // backgroundColor: Colors.scholastik.purple,
    // borderRadius: '50%',
  },
  paddingTopStatusBar: {
    paddingTop: (Platform.OS === 'ios' ? 0 : getStatusBarHeight()) + 20
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontSize: 24,
    color: Colors.scholastik.white,
    fontWeight: 'bold',
    paddingLeft: 25,
    paddingBottom: 10
  },
  txtSearch: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#939393',
  },
  txtItemFilter: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#858585',
  },
  icon: {
    width: 21,
    height: 21,
    resizeMode: 'contain',
    tintColor: '#FBC815',
  },
  icon1: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    tintColor: '#FBC815',
    marginLeft: 15,
  },
  txt3: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#939393',
    marginLeft: 4,
  },
  price: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
  },
  oldPrice: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#B0B0B0',
    textDecorationLine: 'line-through',
    marginLeft: 13,
  },
  txtTitle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 22,
    lineHeight: 27,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginTop: 17,
  },
  subSectionTitle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  txtSubSection: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 10,
    justifyContent: 'flex-start',
    flex: 1,
  },
  txtLength: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 10,
  },
  txtSubTitle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.scholastik.textPrimary,
    fontWeight: '500',
    marginLeft: 10,
  },
  txtOverview: {
    marginTop: 13,
    fontFamily: 'Poppins-ExtraLight',
    fontSize: 13,
    lineHeight: 20,
    color: Colors.scholastik.textPrimary,
    fontWeight: '300',
  },
  subTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  totalHours: {
    fontFamily: 'Poppins-ExtraLight',
    fontSize: 10,
    color: '#8F8F8F',
    fontWeight: '300',
    marginLeft: 10,
  },
  txtItemLession: {
    flex: 1,
    fontFamily: 'Poppins-ExtraLight',
    fontSize: 12,
    lineHeight: 20,
    color: '#4E4E4E',
    fontWeight: '300',
    marginLeft: 10,
  },
  line: {
    width: deviceWidth - 32,
    marginBottom: 14,
    marginTop: 9,
    backgroundColor: '#F0F0F0',
    height: 1,
    alignSelf: 'center',
    flex: 1,
  },
  imgAvatar: {
    width: 51,
    height: 51,
    borderRadius: 51 / 2,
  },
  titleRating: {
    fontFamily: 'Poppins-Medium',
    fontSize: 13,
    lineHeight: 19,
    color: '#000000',
    fontWeight: '500',
    marginTop: 15,
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: Colors.scholastik.background,
    paddingBottom: 15,
    paddingTop: 10,
    margin: 0,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnAddToCart: {
    marginHorizontal: 10,
    flex: 1,
    backgroundColor: Colors.scholastik.purple,
    paddingVertical: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    flexDirection: 'row',
  },
  txtAddToCart: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    lineHeight: 21,
    color: Colors.scholastik.white,
    fontWeight: '500',
  },
  iconCart: {
    width: 18,
    height: 18,
    marginRight: 8,
  },
  iconPreview: {
    fontSize: 16,
    color: '#25C717',
    marginLeft: 5,
  },
});
