/* eslint-disable import/prefer-default-export */
import Types from './types';

export const subscriptionPurchased = (subscription, subscriptionData) => ({
  type: Types.SUBSCRIPTION_PURCHASED,
  subscription,
  subscriptionData,
});

export const subscriptionTimedOut = (subscription) => ({
  type: Types.SUBSCRIPTION_TIMED_OUT,
  subscription,
});
