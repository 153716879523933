import {StyleSheet, Dimensions, Platform} from 'react-native';
import {Colors} from 'app-assets';
const isWeb = Platform.OS === "web";

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;
export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
  },
  containerAll: {},
  logo: {
    height: (98 / 375) * deviceWidth,
    width: (73 / 375) * deviceWidth,
    resizeMode: 'contain',
    // position: "absolute",
  },
  viewLogo: {
    marginTop: 40,
    alignSelf: 'center',
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: '400',
    fontFamily: 'Poppins',
  },
  childTitle: {
    fontFamily: 'Poppins',
    lineHeight: 21,
    fontSize: 13,
    color: '#858585',
    fontWeight: '400',
    marginTop: 15,
    paddingHorizontal: 46,
    textAlign: 'center',
  },
  text: {
    fontSize: 15,
    color: Colors.scholastik.textPrimary,
  },
  textInput: {
    flex: 1,
    marginTop: 20,
    height: 45,
    color: Colors.scholastik.textPrimary,
    fontFamily: 'Poppins',
    fontSize: 14,
    outlineStyle: isWeb ? 'none' : undefined,
  },
  button: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
    top: deviceHeight / 2 - 20,
  },
  nextButton: {
    height: (264 / 375) * deviceWidth,
    width: (264 / 375) * deviceWidth,
    resizeMode: 'contain',
  },
  iconBack: {
    height: 22,
    width: 22,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.textTertiary
  },
  txtAccept: {
    fontFamily: 'Poppins',
    fontSize: 13,
    color: '#9E9E9E',
    fontWeight: '400',
  },
  iconCheck: {
    fontSize: 22,
    color: '#9E9E9E',
    marginRight: 12,
  },
  btnSubmit: {
    marginTop: 10,
    paddingVertical: 4,
    flex: 1,
    height: 50,
    backgroundColor: Colors.scholastik.green,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  txtSubmit: {
    fontFamily: 'Poppins',
    fontSize: 18,
    color: Colors.scholastik.textPrimary,
    fontWeight: '400',
  },
  line: {
    width: 90,
    height: 1,
    backgroundColor: '#DBDBDB',
  },
});
