import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from './resources';
import * as additionalResources from '../../../setup/translations';

const DEFAULT_LANGUAGE = 'cz';

function mergeDeep(...objects) {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
        ...Object.entries(resources).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: {
                    translation: mergeDeep(value, additionalResources[key] || {}),
                },
            }),
            {}
        ),
    },
    lng: DEFAULT_LANGUAGE,
    fallbackLng: 'cz',
});

export default i18n;
