/* TODO redirect instead on web and somehow go back... */
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Web} from '../../component/common';
import {Image, Platform, SafeAreaView, TouchableOpacity, View} from "react-native";
import {getStatusBarHeight} from "../../common";
import styles from "../web/styles";
import {Colors, Images} from 'app-assets';

class WebScreen extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    componentWillUnmount() {

    }

    handleBackPress = () => {
        this.autoGoBack();
        return true;
    };

    goBack = () => {
        this.autoGoBack();
    };

    render() {
        const {route} = this.props;
        return (
            <SafeAreaView>
                <View style={{
                    marginTop: Platform.OS === 'ios' ? 0 : getStatusBarHeight(),
                    width: '100%',
                    height: '100%'
                }}>

                    <Web
                        {...this.props}
                        href={this.props.href || route.params.href}
                        url={this.props.url || route.params.url}/>
                    <TouchableOpacity
                        style={{
                            position: "absolute",
                            top: 25,
                            left: 25,
                            zIndex: 1,
                            backgroundColor: Colors.scholastik.purple,
                            padding: 12,
                            borderRadius: 24
                        }}
                        onPress={() => this.goBack()}
                        hitSlop={{top: 30, bottom: 30, left: 30, right: 50}}
                    >
                        <Image source={Images.iconBack} style={styles.iconBack}/>
                    </TouchableOpacity>
                </View>

            </SafeAreaView>
        );
    }
}

export default withTranslation()(WebScreen);
