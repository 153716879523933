const scholastik = {
  blue: '#172153',
  unselected: '#b6bec9',
  purple: '#c70b7b',
  purpleMild: '#cb65a2',
  background: '#f1f1f1',
  gray: '#f2f2f2',
  white: '#fefefe',
  softGray: '#dadada',
  darkGray: '#b1b1b1',
  darkGrayText: '#676767',
  green: '#b8e9dc',
  greenDark: '#00a938',
  red: '#f9e6eb',
  redDark: '#c70b3e',
  orange: '#f9ede6',
  orangeDark: '#c7600b',
  textPrimary: '#000000',
  textSecondary: '#1e212d',
  textTertiary: '#2b2e41',
  borderPrimary: '#000000',
  borderSecondary: '#1e212d',
  borderTertiary: '#2b2e41',
  black: '#000000',
};

const other = {
  facebook: '#3085f3',
};

const packages = {
  diamond: '#EB5155',
  gold: '#F89520',
  silver: '#0298DA',
  basic: '#30456E',
};

const Colors = {
  scholastik,
  packages,
  other,
};

// eslint-disable-next-line import/prefer-default-export
export {Colors};
