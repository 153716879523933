import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {Colors} from "app-assets";

class ErrorBox extends React.Component {

    static Level = {
        WARN: ['containerWarn', 'titleWarn', 'bodyWarn'],
        ERROR: ['containerError', 'titleError', 'bodyError'],
    }

    render() {
        const { title, body, level } = this.props;
        const styled = level || this.constructor.Level.ERROR;
        return (
            <View style={[styles[styled[0]], this.props.containerStyle]}>
                {typeof title === "string" && title && <Text style={styles[styled[1]]}>{title}</Text>}
                {typeof body === "string" && body && <Text style={styles[styled[2]]}>{body}</Text>}

                {this.props.children}
            </View>
        );
    }
}

const styles = StyleSheet.create({

    titleError: {
        fontFamily: 'Poppins-Medium',
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.scholastik.redDark,
        paddingTop: 12,
        paddingHorizontal: 12,
    },
    bodyError: {
        fontFamily: 'Poppins-Light',
        fontSize: 14,
        color: Colors.scholastik.textPrimary,
        padding: 12,
        textAlign: "center"
    },
    containerError: {
        backgroundColor: Colors.scholastik.red,
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%'
    },
    titleWarn: {
        fontFamily: 'Poppins-Medium',
        fontSize: 20,
        fontWeight: 'bold',
        color: Colors.scholastik.orangeDark,
        paddingVertical: 12,
        paddingHorizontal: 12
    },
    bodyWarn: {
        fontFamily: 'Poppins-Light',
        fontSize: 14,
        color: Colors.scholastik.textPrimary,
        padding: 12,
        textAlign: "center"
    },
    containerWarn: {
        backgroundColor: Colors.scholastik.orange,
        alignItems: 'center',
        alignSelf: 'center',
        width: '100%'
    }
});

export default ErrorBox;
