/* eslint-disable */
import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList,Platform } from 'react-native';
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
  Shine
} from 'rn-placeholder';
import PropTypes from 'prop-types';
import {Colors} from 'app-assets';

class LazyLoadingNoContainer extends PureComponent {

  static renderItem = ({ item }) => {
    return (
        <View style={styles.itemVertical} key={item}>
          <Placeholder
              Animation={Fade}
              Left={() => <PlaceholderMedia style={styles.itemMediaLeft} />}
          >
            <View style={{ marginTop: 8 }}>
              <PlaceholderLine />
              <PlaceholderLine />
              <View
                  style={{ flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <PlaceholderLine width={20} />
                <PlaceholderLine width={20} />
              </View>
              <PlaceholderLine width={40} />
            </View>
          </Placeholder>
        </View>
    )
  };

  static propTypes = {
    visible: PropTypes.bool,
    length: PropTypes.number,
    renderer: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    length: 3,
    renderer: this.renderItem,
  };

  render() {
    const { visible, length, renderer } = this.props;
    if (visible) {
      return [...Array(length).keys()].map(renderer);
    }
    return null;
  }
}

const styles = StyleSheet.create({
  image: {
    width: 220,
    height: 179,
    backgroundColor: 'rgba(0,0,0,.1)',
    borderRadius: 8,
    marginBottom: 8
  },
  item: {
    borderRadius: 8,
    width: 220,
    marginRight: 16,
    height: 179,
    backgroundColor: Colors.scholastik.white
  },
  itemVertical: { height: 128, flex: 1, marginBottom: 32, marginRight: 16, marginLeft: 16 },
  itemMediaLeft: {
    width: 220,
    height: 179,
    marginRight: 10,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8
  }
});

export default LazyLoadingNoContainer;
