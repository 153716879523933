import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    title: {
        fontFamily: 'Poppins-Medium',
        fontWeight: '500',
        fontSize: 18,
        lineHeight: 27,
        color: '#000',
    },
    containerOpened: {
        position: 'absolute',
        elevation: 10,
        zIndex: 10,
        width: '100%',
        paddingTop: 20,
        paddingHorizontal: 10,
        bottom: 0,
    },
    containerClosed: {
        position: 'absolute',
        bottom: 0,
        elevation: 10,
        zIndex: 10,
        width: '100%',
        height: 170,
        paddingTop: 20,
        paddingHorizontal: 10,
    },
    explanationInnerContainer: {
        paddingHorizontal: 18,
        paddingVertical: 12,
        alignSelf: 'flex-start',
        borderRadius: 4,
        width: '100%',
        position: 'relative'
    }
});
