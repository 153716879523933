import {createStackNavigator} from "@react-navigation/stack";
import Profile from "../../screens/profile";
import Settings from "../../screens/settings";
import React from "react";
import {Colors} from "app-assets";
import Login from "../../screens/login";
import Register from "../../screens/register";
import Forgot from "../../screens/forgot";

const ProfileStack = createStackNavigator();
export default function ProfileRouter() {
    return (
        <ProfileStack.Navigator
            initialRouteName="ProfileScreen"
            screenOptions={{
                cardStyle: {flex: 1},
                gesturesEnabled: true,
                presentation: 'card',
                headerShown: false,
                header: null,
            }}>
            <ProfileStack.Screen
                name="ProfileScreen"
                component={Profile}
                options={{ headerShown: false, title: 'Můj účet' }}
            />
            <ProfileStack.Screen
                name="SettingsScreen"
                component={Settings}
                options={{ headerShown: false, title: 'Nastavení' }}
            />
            <ProfileStack.Screen
                name="LoginScreen"
                component={Login}
                options={{ headerShown: false, title: 'Přihlášení' }}
                path='third-party-login/' //Enable Deep linking redirection to get the auth_token
            />
            <ProfileStack.Screen
                name="RegisterScreen"
                component={Register}
                options={{ headerShown: false, title: 'Registrace' }}
            />
            <ProfileStack.Screen
                name="ForgotScreen"
                component={Forgot}
                options={{ headerShown: false, title: 'Zapomenuté údaje' }}
            />
        </ProfileStack.Navigator>
    );
}
