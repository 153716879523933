import { StyleSheet } from 'react-native';
import { Colors } from "app-assets";

export default StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  tabIcon: {
    width: 20,
    height: 18,
    resizeMode: 'contain',
    position: 'relative',
    tintColor: Colors.scholastik.unselected,
  },
  title: {
    fontSize: 10,
    lineHeight: 15,
    textAlign: 'center',
    color: Colors.scholastik.unselected,
    zIndex: 100,
  },
});
