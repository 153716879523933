import * as React from 'react';
import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Colors} from "app-assets";

class PlainButton extends React.Component {

    render() {
        const {
                title, body, titleStyle, bodyStyle, containerStyle,
                onPress, onPressIn, onPressOut, disabled
            } = this.props,
            handler = onPress || onPressIn || onPressOut;

        return (
            <TouchableOpacity
                style={[styles.container, containerStyle]}
                disabled={disabled}
                onPress={handler}>
                <View style={{flexDirection: 'column'}}>
                    {typeof title === "string" && title &&
                        <Text style={[styles.txt1, titleStyle]}>{title}</Text>}
                    {typeof body === "string" && body &&
                        <Text style={[styles.txt2, bodyStyle]}>{body}</Text>}
                {this.props.children}
            </View>
    </TouchableOpacity>
    );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.scholastik.purple,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "center",
        paddingHorizontal: 28,
        paddingVertical: 14,
        borderRadius: 6,
        margin: 10,
    },
    txt1: {
        fontFamily: 'Poppins-Medium',
        fontWeight: '500',
        color: Colors.scholastik.white,
        textAlign: "center",
        fontSize: 18,
        lineHeight: 24,
    },
    txt2: {
        fontFamily: 'Poppins-Medium',
        fontSize: Platform.OS === 'android' ? 12:14,
        maxWidth: '75%',
        alignSelf: "center",
        textAlign: "center",
        lineHeight: Platform.OS === 'android' ? 14:16,
        paddingTop: 8,
        color: Colors.scholastik.white,
    }
});

export default PlainButton;
