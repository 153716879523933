import * as React from 'react';
import ErrorView from "./error-view";
import IconM from "react-native-vector-icons/MaterialCommunityIcons";
import PlainButton from "./plainButton";
import {withTranslation} from "react-i18next";
import {Dimensions} from "react-native";
import {VALUES} from "../../../setup/config";
import {Colors} from "app-assets";

class InternetConnectionErrorView extends ErrorView {

    render() {
        const { t, containerStyle } = this.props;
        const onPress = this.props.onPress;

        return (
            <ErrorView
                title={t('dataNotFound')}
                titleStyle={{
                    color: Colors.scholastik.white
                }}
                containerStyle={[{
                    marginTop: 20,
                    height: Dimensions.get('screen').height - 100
                }, containerStyle]}
                IconComponent={IconM}
                icon="cable-data"
                iconStyle={{
                    color: Colors.scholastik.white
                }}
                {...this.props}
            >
                <PlainButton
                    onPress={onPress}
                    containerStyle={{marginTop: 30}}
                    title={t('refresh')}
                    body={t('canHelpIfNoWifi', {domain: VALUES.DOMAIN_NAME})}
                />

            </ErrorView>
        );
    }
}


export default withTranslation()(InternetConnectionErrorView);
