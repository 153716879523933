// Function to generate a pseudo-random color based on a string
import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import React, {Component} from "react";
import {Avatar} from "react-native-elements";
import RenderDataHTML from "../../common/render-data-html";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {Colors} from "app-assets";


const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `#${((hash >> 24) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 16) & 0xFF).toString(16).padStart(2, '0')}${((hash >> 8) & 0xFF).toString(16).padStart(2, '0')}`;
};

// Avatar component
const FallbackAvatar = ({ name }) => {
    const backgroundColor = stringToColor(name);
    const letter = name.charAt(0).toUpperCase();

    return (
        <View style={[styles.fallbackAvatar, { backgroundColor }]}>
            <Text style={styles.letter}>{letter}</Text>
        </View>
    );
};

class CommentItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            replyText: '',
            responseText: '',
            editText: props.comment.content.rendered,
            isEditing: false,
            showingChildren: false,
        };
    }

    handleReply = async () => {
        if (this.state.replyText.trim()) {
            const newComment = await this.props.onReply(this.props.comment.id, this.state.replyText);
            if (newComment) {
                this.setState({ replyText: '', showReplyInput: false });
            }
        }
    };

    handleAlwaysShownReply = async () => {
        if (this.state.responseText.trim()) {
            const newComment = await this.props.onReply(this.props.comment.id, this.state.responseText);
            if (newComment) {
                this.setState({ responseText: '' });
            }
        }
    };

    countChildrenCommentsSize = (node) => {
        return node.children.reduce((acc, x) => acc + this.countChildrenCommentsSize(x), 1);
    }

    handleEdit = async () => {
        if (this.state.editText.trim()) {
            const updatedComment = await this.props.onEdit(this.props.comment.id, this.state.editText);
            if (updatedComment) {
                this.setState({ isEditing: false });
            }
        }
    };

    render() {
        const { comment, onDelete, userId, t } = this.props;
        const { isEditing, editText } = this.state;
        const avatar = Object.values(comment.author_avatar_urls)[2];
        const depth = this.props.depth || 0;

        const hasChildren = comment.children && comment.children.length > 0;
        const showsChildren = hasChildren && (depth > 0 || this.state.showingChildren);

        return (
            <View style={[styles.commentContainer, comment.parent ? { marginLeft: 10 } : {marginBottom: 20}]}>
                <View style={styles.commentContent}>
                    <View style={{display: "flex", position: "relative"}}>
                        {avatar ? (
                            <Avatar
                                size="medium"
                                rounded
                                title={comment.author_name[0]}
                                containerStyle={styles.avatar}
                                source={{
                                    uri: avatar
                                }}
                            />
                        ) : (
                            <FallbackAvatar name={comment.author_name} />
                        )}
                        {
                            (depth === 0 || this.state.showReplyInput) &&
                            <View style={{width: 2, marginHorizontal: 0, flex: 1, marginTop: 20, backgroundColor: Colors.scholastik.gray}}></View>
                        }
                    </View>
                    <View style={styles.commentTextContainer}>
                        <Text style={styles.author}>{comment.author_name}</Text>
                        {isEditing ? (
                            <TextInput
                                style={styles.input}
                                value={editText}
                                multiline={true}
                                numberOfLines={2}
                                onChangeText={(text) => this.setState({ editText: text })}
                            />
                        ) : (
                            <RenderDataHTML
                                html={comment.content.rendered || ''}
                                style={styles.comment}
                            />
                        )}

                        <View style={styles.actions}>
                            <Text style={styles.time}>{new Date(comment.date).toLocaleString()}</Text>
                            {userId === comment.author && (
                                <>
                                    {isEditing ? (
                                        <>
                                            <TouchableOpacity onPress={this.handleEdit} style={styles.actionButton}>
                                                <Text style={styles.actionButtonText}>{t('save')}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => this.setState({ isEditing: false })} style={styles.actionButton}>
                                                <Text style={styles.actionButtonText}>{t('cancel')}</Text>
                                            </TouchableOpacity>
                                        </>
                                    ) : (
                                        <>
                                            {/*TODO support edits
                                            <TouchableOpacity onPress={() => this.setState({ isEditing: true })} style={styles.actionButton}>*/}
                                            {/*    <Text style={styles.actionButtonText}>{t('edit')}</Text>*/}
                                            {/*</TouchableOpacity>*/}
                                            {
                                                !hasChildren && <TouchableOpacity onPress={() => onDelete(comment.id)} style={styles.actionButton}>
                                                    <Text style={styles.actionButtonText}>{t('delete')}</Text>
                                                </TouchableOpacity>
                                            }
                                        </>
                                    )}
                                </>
                            )}
                            {
                                (!hasChildren || depth > 0) && <TouchableOpacity onPress={() => this.setState({ showReplyInput: !this.state.showReplyInput, showingChildren: !this.state.showReplyInput })} style={styles.actionButton}>
                                    <Text style={[styles.actionButtonText, {fontWeight: 'normal'}]}>{t('comments.reply')}</Text>
                                </TouchableOpacity>
                            }
                        </View>
                        {this.state.showReplyInput && (
                            <View style={styles.replyContainer}>
                                <TextInput
                                    style={styles.input}
                                    placeholder={t('comments.commentInputPlaceholder')}
                                    value={this.state.replyText}
                                    multiline={true}
                                    numberOfLines={2}
                                    onChangeText={(text) => this.setState({ replyText: text })}
                                />
                                <TouchableOpacity onPress={this.handleReply} style={styles.button}>
                                    <Text style={styles.buttonText}>{t('comments.submitButtonLabel')}</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                </View>
                {showsChildren ?
                    <View style={[depth === 0 && {borderLeftColor: Colors.scholastik.gray, borderLeftWidth: 2}]}>
                        {comment.children.map((child) => (
                            <CommentItem
                                key={child.id}
                                comment={child}
                                onDelete={onDelete}
                                onReply={this.props.onReply}
                                onEdit={this.props.onEdit}
                                userId={userId}
                                depth={depth+1}
                                t={t}
                            />
                        ))}
                    </View>
                    : (hasChildren ?
                        <TouchableOpacity onPress={() => this.setState({showingChildren: true})} style={styles.actionButtonShowComments}>
                            <Text style={styles.actionButtonText}>{t('comments.showChildComments', {count: this.countChildrenCommentsSize(comment) - 1})}</Text>
                        </TouchableOpacity>
                        : <View></View>)
                }

                {depth === 1 && (
                    <View style={styles.replyContainer}>
                        <TextInput
                            style={styles.input}
                            placeholder={t('comments.commentInputPlaceholder')}
                            value={this.state.responseText}
                            multiline={true}
                            numberOfLines={2}
                            onChangeText={(text) => this.setState({ responseText: text })}
                        />
                        <TouchableOpacity onPress={this.handleAlwaysShownReply} style={styles.button}>
                            <Text style={styles.buttonText}>{t('comments.reply')}</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        );
    }
}

CommentItem.propTypes = {
    comment: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onReply: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};


const styles = StyleSheet.create({
    commentContainer: {
        flexDirection: 'column',
        marginBottom: 0,
        marginTop: 10
    },
    commentContent: {
        flexDirection: 'row',
    },
    avatar: {
        marginRight: 10,
        width: 35,
        height: 35
    },
    commentTextContainer: {
        flex: 1,
    },
    author: {
        fontWeight: 'bold',
    },
    comment: {
        marginVertical: -5,
    },
    time: {
        color: 'gray',
        fontSize: 12,
        marginRight: 15,
    },
    deleteButton: {
        marginTop: 5,
    },
    deleteButtonText: {
        color: 'red',
    },

    button: {
        backgroundColor: Colors.scholastik.purple,
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    fallbackAvatar: {
        marginRight: 10,
        width: 35,
        height: 35,
        borderRadius: 25,
        justifyContent: 'center',
        alignItems: 'center',
    },
    letter: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
    },
    actions: {
        flexDirection: 'row',
        marginTop: 5,
    },
    actionButton: {
        marginRight: 10,
    },
    actionButtonShowComments: {
        marginLeft: 8, marginTop: 15
    },
    actionButtonText: {
        color: Colors.scholastik.purple,
        fontWeight: 'bold',
        fontFamily: 'Poppins'
    },
    input: {
        flex: 1,
        padding: 10,
        borderColor: '#ddd',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 10,
        color: Colors.scholastik.black,
        maxHeight: 120,
    },
    replyContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
    },
});

export default withTranslation()(CommentItem);