/* eslint-disable no-param-reassign */
import React, { useReducer, useRef, forwardRef, memo } from 'react';
import {
    ActivityIndicator,
    FlatList,
    Text,
    TouchableOpacity,
    Dimensions, Platform, View,
} from 'react-native';
import { ItemCourse } from 'app-component';
import {VALUES} from '../../../setup/config';
import { Colors } from 'app-assets';
import styles from "../../screens/home/styles";
import { withTranslation } from 'react-i18next';

const cols = Dimensions.get('window').width > 600 ? 2 : 1;

const ListCourses = memo(
  forwardRef((props, ref) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const flatList = useRef();
    const {
      data,
      style,
      horizontal,
      showFooter,
      refreshing,
      refreshScreen,
      contentContainerStyle,
      scrollEnabled,
      ListEmptyComponent,
      navigation,
      autoNavigation,
      user,
      nextPage,
      extraData,
      showPreviews,
    } = props;

      const renderNavigateSubscription = (courseId) => {
          const t = props.t;
          const loggedIn = user?.token;

          return <TouchableOpacity
              onPress={() => {
                if (loggedIn) {
                    autoNavigation('CoursesDetailsScreen', {courseId});
                } else {
                    autoNavigation('LoginScreen');
                }
              }}
              style={styles.subscriptionItem}
          >
             <View style={{paddingHorizontal: 18,
                 paddingVertical: 12,}}>
                 <Text style={[styles.title, {
                     fontSize: 20,
                     fontWeight: 'bold'
                 }]}>{t('home.details.titleMissingSubscription')}</Text>
                 <Text style={{
                     fontSize: 14,
                     color: Colors.scholastik.textTertiary
                 }}>{
                     loggedIn ? t('home.details.detailsMissingSubscription')
                         : t('home.details.loginRequiredSubscription', {domain: VALUES.DOMAIN_NAME})}</Text>
                 <View style={{flexDirection: "row-reverse"}}>
                     <View style={{backgroundColor: Colors.scholastik.purple, paddingHorizontal: 18,
                         paddingVertical: 12, marginTop: 12,
                         borderRadius: 18}}>
                         <Text style={[styles.title, {
                             color: Colors.scholastik.white,
                             fontSize: 22,
                             fontWeight: 'bold'
                         }]}>{
                             loggedIn ? t('home.details.iWantThis')
                                 : t('loginScreen.btnLogin')}</Text>
                     </View>
                 </View>
             </View>
          </TouchableOpacity>;
      }

    const renderItem = ({ item }) => {
          if (!item) return <></>;
          if (!item?.id) return renderNavigateSubscription(item.courseId);
          return <ItemCourse item={item} showPreviews={showPreviews} navigation={navigation}/>;
    };

    const keyExtractor = (item) => String(item?.id || Date.now());

    const onEndReached = () => {
      if (!data) return;
      if (data.length === 0) return;
      if (nextPage) nextPage();
    };

    const ListFooter = () => {
      if (horizontal)
        return (
          <TouchableOpacity
            style={{
              width: 100,
              height: 134,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontFamily: 'Poppins-Medium',
                fontWeight: '500',
                fontSize: 12,
                lineHeight: 18,
              }}
            >
              All source
            </Text>
          </TouchableOpacity>
        );
      if (showFooter) return <ActivityIndicator size="small" />;
      return null;
    };

    return (
      <FlatList
        ref={flatList}
        scrollEnabled={true}
        contentContainerStyle={contentContainerStyle}
        style={style}
        horizontal={horizontal}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={Platform.OS === "web"}
        refreshControl={refreshScreen}
        refreshing={refreshing}
        data={data}
        extraData={extraData}
        renderItem={renderItem}
        onEndReached={onEndReached}
        keyExtractor={keyExtractor} // Performance purpose
        removeClippedSubviews
        onEndReachedThreshold={0.5}
        ListFooterComponent={ListFooter}
        ListEmptyComponent={ListEmptyComponent}
        scrollEventThrottle={1}
        numColumns={cols}
      />
    );
  }),
  () => false
);
export default withTranslation()(ListCourses);
