import {Linking} from "react-native";

export async function sendEmail(to, subject, body, options = {}) {
    const { cc, bcc } = options;

    let url = `mailto:${to}`;

    // Create email link query
    const query = qs.stringify({
        subject: subject,
        body: body,
        cc: cc,
        bcc: bcc
    });

    if (query.length) {
        url += `?${query}`;
    }

    // check if we can use this link
    const canOpen = await Linking.canOpenURL(url);

    if (!canOpen) {
        throw new Error('Provided URL can not be handled');
    }

    return Linking.openURL(url);
}

export default {
    configure: function (props) {
        //no-op, logs are not by default
    },
    deleteLogFiles: async function (){
        //no-op, do nothing, we have no log files in a browser
    },
    sendLogFilesByEmail: async function (props) {
        return await sendEmail(props.to, props.subject, props.body);
    }
};
