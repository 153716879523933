import * as React from 'react';
import {Dimensions, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Colors} from "app-assets";
import IconI from "react-native-vector-icons/Ionicons";
const deviceWidth = Dimensions.get('window').width;

class Hint extends React.Component {

    constructor() {
        super();
        this.state = {
            opened: false
        }
    }

    render() {
        const { hint, iconStyle, hintContainerStyle } = this.props;

        return (
            <TouchableOpacity
                hitSlop={{top: 15, left: 15, right: 15, bottom: 15}}
                onPress={() => this.setState({opened: !this.state.opened})}
                style={styles.container}>
                <IconI name="help-circle-outline" style={[styles.roundedIcon, iconStyle]}/>
                {this.state.opened && <View style={[styles.hintContainer, hintContainerStyle || {}]}>
                    <Text>{hint}</Text>
                </View>}
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: "relative",
        zIndex: 99,
    },
    roundedIcon: {
        fontFamily: "Poppins",
        fontSize: 20,
        color: Colors.scholastik.darkGray,
        lineHeight: 25,
        paddingLeft: 5,
        backgroundColor: Colors.scholastik.white,
        marginTop: -3
    },
    hintContainer: {
        position: "absolute",
        top: 20,
        left: 20,
        width: 180,
        backgroundColor: Colors.scholastik.gray,
        color: Colors.scholastik.darkGrayText,
        borderRadius: 12,
        paddingHorizontal: 12,
        paddingVertical: 7,
    }
});

export default Hint;
