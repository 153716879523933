import { StyleSheet } from 'react-native';
import {Colors} from "app-assets";

export default StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    marginVertical: 4,
    backgroundColor: Colors.scholastik.white,
    marginRight: 16,
    borderRadius: 16,
  },
  title: {
    fontFamily: 'Poppins-ExtraLight',
    fontSize: 14,
    lineHeight: 20,
    fontWeight: '300',
  },
});
