import MathJax from "../../component/common/math-jax";
import React from "react";
import RenderDataHTML from "../../component/common/render-data-html";
import RenderDataHtmlFallback from "../../component/common/render-data-html-fallback";
import {Platform} from "react-native";

export function getQuestionLetter(index) {
    return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'][index];
}

/**
 *
 * @param html
 * @param options rendering options for non-native html renderers
 * @param style
 * @param options.additionalRightIframeMargin
 * @param options.additionalMarginBottom
 * @param options.forceFallbackAndroid unused
 * @returns {JSX.Element}
 */
export function renderHtml(html, options={}, style={}) {
    if (!typeof html === "string") html = "";
    if (html?.includes('oscio-mathjax-equation')) {
        style.backgroundColor = 'transparent';
        return <MathJax
            additionalMarginBottom={options.additionalMarginBottom || 0}
            additionalRightIframeMargin={options.additionalRightIframeMargin || 0}
            html={html}
            style={style}/>;
    }

    // //todo caused by memo?
    // if (forceFallbackAndroid && Platform.OS === 'android') {
    //     return <RenderDataHtmlFallback html={html} style={{ backgroundColor:'transparent'}} />;
    // }

    if (typeof html === "string" && (html.includes('<table')
        || html?.includes('<script')
        || html?.includes('<sup')))  //TODO render native html does not support supscript - will in future?
    {
        style.backgroundColor = 'transparent';
        return <RenderDataHtmlFallback
            additionalRightIframeMargin={options.additionalRightIframeMargin || 0}
            additionalMarginBottom={options.additionalMarginBottom || 0}
            html={html}
            style={style} />;
    }
    return <RenderDataHTML
        html={html || ''}
        options={{
            additionalRightIframeMargin: options.additionalRightIframeMargin || 0
        }}
        style={style}
    />;
}