/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {
    Text,
    View,
    TouchableOpacity,
    RefreshControl,
    Platform,
} from 'react-native';
import {Alert, KeyboardAwareScrollView} from "proxy";
import {withTranslation} from 'react-i18next';
import IconM from 'react-native-vector-icons/MaterialCommunityIcons';
import styles from './styles';
import {Colors} from 'app-assets';
import {connect} from "react-redux";
import {QStatus} from "../../common/util/qStatus";
import {Events, Routines} from "../../common/util";
import {showLoading} from "../../actions/common";
import {HttpAPI} from 'app-api';
import ErrorView from "../../component/common/error-view";

import {deleteQuestionStatus} from "../../actions/pins";
import PlainButton from "../../component/common/plainButton";
import {CommonAppLayout, ContentMiddleLayout, TallHeader, FullScreenLayout} from "app-component";
import {CategoryHeader} from "../../component/categoryHeader";
import {saveCategory, setSelectedCategory} from "../../actions/category";

class Board extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshing: false,
            categoryData: undefined,
            toggle: true
        };
        this.isFetchData = true;
    }

    componentDidMount() {
        const {navigation, user} = this.props, self = this;
        Events.onCustom(this, 'navigationFocus', () =>
            navigation.addListener('focus', this.refreshData.bind(this)));
    }

    componentWillUnmount() {
        Events.off(this, 'navigationFocus');
    }

    removePin(question) {
        const {t} = this.props;
        const _this = this;
        Alert.alert(
            t('board.alert.removePin'),
            t('board.alert.cannotUndone'),
            [
                {
                    text: t('alert.cancel'),
                    onPress: () => {
                    },
                    style: 'cancel',
                }, {
                text: t('alert.ok'),
                onPress: async () => {
                    await _this.removePinWithoutCheck(question);
                },
            },
            ],
            {cancelable: false}
        );
    }

    async removePinWithoutCheck(question) {
        const {user, dispatch} = this.props;
        const param = {
            id: question.quizId,
            question_id: question.id,
            user_id: user?.info.id,
            status: -1
        };
        HttpAPI.questionStorePin(param).catch(e => {
            console.warn("Could not save pin data - board", param, e);
        });
        await dispatch(deleteQuestionStatus(question.id));
        await this.refreshData();
    }

    async refreshData() {
        const {route, dispatch, category} = this.props;
        //prepare categories to render
        const pins = this.props.pins;
        const categories = {};
        const categoryData = await Routines.getCourseCategoryMeta(this.props);
        for (let category of categoryData) {
            categories[category.slug] = {
                meta: category.meta,
                name: category.name,
                slug: category.slug,
                data: {},
            }
        }

        //get stored map in pins that maps quizes to their categories and courses
        //todo pins should store only pin data, now we need to care for this key explicitly
        const quizToCourseMap = pins.categoryData || {};
        //for now ignore courses, just sort to categories
        for (let id in pins) {
            if (id == "categoryData") continue;

            const pinData = pins[id], quizCourseMeta = quizToCourseMap[pinData.quizId];

            if (!QStatus.isPin(pinData.status)) continue;
            if (!quizCourseMeta) {
                //create 'unknown' category for pins if meta missing
                if (!categories[""]) {
                    categories[""] = {
                        meta: {},
                        name: this.props.t('other'),
                        slug: "",
                        data: {
                            "unknown": {
                                name: "",
                                coursePins: {}
                            }
                        },
                    };
                }
                let data = categories[""].data["unknown"].coursePins;
                if (!data[pinData.quizId]) {
                    data[pinData.quizId] = {
                        items: [pinData],
                        name: pinData.quizName || this.props.t('unknown'),
                    }
                } else {
                    data[pinData.quizId].items.push(pinData);
                }
                continue;
            }

            const quizCategories = quizCourseMeta.courseCategories || [];
            const courseName = quizCourseMeta.courseName;
            //add pin to all categories of the given

            for (let category of quizCategories) {
                let data = categories[category.slug].data;

                //nest data in course
                if (!data[courseName]) {
                    data[courseName] = {
                        name: courseName,
                        coursePins: {}
                    }
                }
                //write to the coursePins
                data = data[courseName].coursePins;
                if (!data[pinData.quizId]) {
                    data[pinData.quizId] = {
                        items: [pinData],
                        name: pinData.quizName,
                    }
                } else {
                    data[pinData.quizId].items.push(pinData);
                }
            }
        }

        //select active category
        const chosenCategory = route.params?.category,
            oldCategory = category.activeCategorySlug;
        let toSelect = oldCategory;
        function select(slug) {
            const categoryData = categories[slug];
            if (!categoryData) return;

            const previousData = categories[toSelect];
            if (previousData) {
                previousData.active = false;
            }

            toSelect = slug;
            categoryData.active = true;
        }

        for (let category of categoryData) {
            if (category.slug.toLowerCase() === chosenCategory?.toLowerCase()) {
                //1) url overrides everything
                select(category.slug);
                continue;
            }
            if (categories[oldCategory]) {
                //2) existing old selection, no-op
                continue;
            }

            if (!toSelect) {
                //3) missing selector, and nothing active - choose first active
                select(category.slug);
            } else if (Object.keys(categories[toSelect]?.data || {}).length < 1) {
                //4) check whether selection non-empty
                select(category.slug);
            }
        }

        if (toSelect !== oldCategory) {
            dispatch(setSelectedCategory(toSelect));
        }
        this.setState({
            categoryData: Object.values(categories),
        });
    }

    handleBackPress() {
        this.autoGoBack();
        return true;
    }

    goBack() {
        this.autoGoBack();
    }

    onNavigate(item) {
        const {dispatch, t} = this.props,
            _this = this;

        Alert.alert(
            t('board.alert.choose', {number: item.order + 1}),
            '',
            Platform.OS === "android" ? [
                {
                    text: t('board.alert.remove'),
                    onPress: async () => _this.removePin(item),
                }, {
                    text: t('board.alert.navigate'),
                    onPress: () => {
                        _this.navigateQuestion(item).catch(e => {
                            console.warn(e);
                            //todo handle
                            dispatch(showLoading(false));
                        });
                    },
                }
            ] : [
                {
                    text: t('board.alert.navigate'),
                    onPress: () => {
                        _this.navigateQuestion(item).catch(e => {
                            console.warn(e);
                            //todo handle
                            dispatch(showLoading(false));
                        });
                    },
                }, {
                    text: t('board.alert.remove'),
                    onPress: async () => _this.removePin(item),
                }, {
                    text: t('board.alert.back'),
                    onPress: () => {
                    },
                }
            ],
            {cancelable: true}
        );
    }

    renderPinQuestion(item, i) {
        let bColor, bgColor;
        switch (QStatus.getStatus(item.status)) {
            case 1:
            case 2:
                bgColor = Colors.scholastik.green;
                bColor = Colors.scholastik.greenDark;
                break;
            case 3:
                bgColor = Colors.scholastik.red;
                bColor = Colors.scholastik.redDark;
                break;
            default:
                bgColor = Colors.scholastik.gray;
                bColor = Colors.scholastik.darkGray;
                break;
        }

        return <TouchableOpacity
            style={[styles.pinBox, {borderColor: bColor, backgroundColor: bgColor}]}
            key={i}
            onPress={() => this.onNavigate(item)}
        >
            <Text style={[styles.pinBoxText, {color: bColor}]}>{item.order + 1}</Text>
            <IconM name="pin" style={styles.pinBoxIcon}/>
        </TouchableOpacity>
    }

    renderPinCategory(item, i) {
        return <View key={String(i)} style={{marginTop: 5, marginBottom: 12}}>
            <Text style={styles.txt1}>{item.name}</Text>
            <View>
                {
                    Object.values(item.coursePins).map(this.renderPinQuiz.bind(this))
                }
            </View>
        </View>
    }

    renderPinQuiz(item, i) {
        return <View key={String(i)} style={{marginTop: 5, marginBottom: 12}}>
            <Text style={styles.txt2}>{item.name}</Text>
            <View style={{flexDirection: "row", flexWrap: "wrap"}}>
                {
                    item.items.map(this.renderPinQuestion.bind(this))
                }
            </View>
        </View>;
    }

    async navigateQuestion(qItem) {
        const {t, navigation, dispatch} = this.props,
            _this = this;

        dispatch(showLoading(true));

        const data = await HttpAPI.getCourseSummaryByQuizId(qItem.quizId).then(r => r?.responseData);
        //todo if no network show internet error, need to make client return other stuff but null
        if (!data?.data) {
            throw `Invalid course id ${qItem.quizId} - no course retrieved from server: ${JSON.stringify(data)}`;
        }
        const course = data.data;
        let quizItem;

        loop:
            for (let section of course.sections) {
                for (let item of section.items) {
                    if (item.id == qItem.quizId) {
                        quizItem = item;
                        break loop;
                    }
                }
            }
        if (!quizItem) {
            throw `No valid quiz ${qItem.quizId} for course ${course.id}!`;
            //todo cleanup the pin
        }

        //todo try just fetch course ID and then getOverview
        await Routines.performNecessaryUserChecksBeforeCourseStart(this.props, course,
            async (sub) => {
                _this.autoNavigate('LearningScreen', {
                    courseId: course.id,
                        sectionType: quizItem.type,
                        sectionId: qItem.quizId,
                        questionIndex: qItem.order
                });
            }, (sub, premiums) => {
                Alert.alert(
                    t('errors.missingSubscription.title'),
                    t('errors.missingSubscription.body'), [
                        {
                            text: t('board.alert.navigate'),
                            onPress: () => {
                                _this.autoNavigate('Subscription', {
                                    requiredPremiums: premiums,
                                    message: t('messages.noLongerCourseEligible')
                                });
                            },
                        }, {
                            text: t('board.alert.remove'),
                            onPress: async () => _this.removePin(item),
                        }
                    ],
                    {cancelable: true}
                );

            }, (sub, premiums) => {
                Alert.alert(
                    t('errors.subscription.title'),
                    t('errors.subscription.body') + course?.categories.map(c => c.name?.toUpperCase()).join(", ")
                    + (Platform.OS === 'android' ? '. ' + t('errors.subscription.additional') : ''), [
                        {
                            text: t('board.alert.navigate'),
                            onPress: () => {
                                _this.autoNavigate('Subscription', {
                                    requiredPremiums: premiums,
                                    message: t('messages.noLongerCourseEligible')
                                });
                            },
                        }, {
                            text: t('board.alert.remove'),
                            onPress: async () => _this.removePin(item),
                        }
                    ],
                    {cancelable: true}
                );
            }
        );
        dispatch(showLoading(false));
    }

    onClickChooseCategory(slug) {
        const { dispatch } = this.props;
        if ((this.state.categoryData || []).find(x => x.slug === slug)) {
            dispatch(setSelectedCategory(slug));
            this.setState({toggle: !this.state.toggle})
        } else {
            console.warn("Attempt to set invalid slug!");
        }
    }

    render() {
        const {t, user, category} = this.props;

        const {
            refreshing,
            categoryData,
        } = this.state;

        let activePinData = categoryData ?
            categoryData.find(x => x.slug === category.activeCategorySlug)?.data || {} : undefined;

        if (activePinData) activePinData = Object.values(activePinData);

        return (
            <CommonAppLayout
                edges={['right', 'left', 'top']}
                color={Colors.scholastik.purple}>
                {user?.token ?
                    <KeyboardAwareScrollView
                        removeClippedSubviews={false}
                        showsVerticalScrollIndicator={Platform.OS === "web"}
                        keyboardShouldPersistTaps="handled"
                        contentContainerStyle={{minHeight: '100%'}}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={() => this.refreshData()}
                            />
                        }>

                        <CategoryHeader
                            title={t('board.title')}
                            categories={categoryData}
                            selectedCategory={category.activeCategorySlug}
                            onSelect={slug => this.onClickChooseCategory(slug)}
                            navigation={this.props.navigation}
                        >
                        </CategoryHeader>

                        <ContentMiddleLayout containerStyle={{backgroundColor: Colors.scholastik.background}}>
                            <View style={styles.content2}>
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        marginHorizontal: 20,
                                        marginTop: 30,
                                        marginBottom: 50
                                    }}
                                >
                                    {
                                        activePinData?.length > 0 ?
                                            activePinData.map(this.renderPinCategory.bind(this))
                                            : <ErrorView
                                                title={t(activePinData ? 'board.noPins' : 'board.loading')}
                                                titleStyle={{
                                                    color: Colors.scholastik.textTertiary
                                                }}
                                                containerStyle={{
                                                    height: 150,
                                                    marginTop: 20
                                                }}
                                                IconComponent={IconM}
                                                icon="information"
                                                iconStyle={{
                                                    color: Colors.scholastik.textTertiary
                                                }}
                                                body={activePinData === undefined ? "" : t('board.noPinsDesc')}
                                                bodyStyle={{
                                                    color: Colors.scholastik.textTertiary
                                                }}
                                                {...this.props}
                                           />
                                    }
                                </View>
                            </View>
                        </ContentMiddleLayout>
                    </KeyboardAwareScrollView> : <FullScreenLayout>

                        <Text style={[styles.title, {color: Colors.scholastik.textPrimary}]}>{this.props.t('board.title')}</Text>

                        <Text style={styles.notAvailableTitle}>
                            {t('coursePreview.availableStudyPlan')}
                        </Text>
                        <View style={{flexDirection: "column", marginBottom: 30}}>

                            <PlainButton
                                onPress={() => this.autoNavigate('LoginScreen')}
                                title={t('login')}
                            />
                            <PlainButton
                                onPress={() => this.autoNavigate('RegisterScreen')}
                                title={t('register')}
                                containerStyle={{backgroundColor: Colors.scholastik.blue}}
                            />
                        </View>
                    </FullScreenLayout>}
            </CommonAppLayout>);
    }
}

const mapStateToProps = ({pins, user, subscription, category, cached}) => ({
    pins, user, subscription, cached, category
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Board));
