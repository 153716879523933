import {createStackNavigator} from "@react-navigation/stack";
import React from "react";
import Learning from "../../../../screens/learning";

const LearningStack = createStackNavigator();
export default function LearningRouter() {
    return (
        <LearningStack.Navigator
            initialRouteName="LearningScreen"
            screenOptions={{
                cardStyle: {flex: 1},
                gesturesEnabled: false,
                headerShown: false,
                header: null,
            }}>
            <LearningStack.Screen
                name="LearningScreen"
                component={Learning}
                options={{
                    headerShown: false,
                    title: 'Test'
                }}
            />
        </LearningStack.Navigator>
    );
}