/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import {Alert} from "proxy";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './styles/item-course';
import {Colors} from "app-assets";
import {VALUES} from "../../../setup/config";

class ItemCourse extends PureComponent {

  onNavigateDetail = () => {
    const { t, user, item, showPreviews } = this.props;

    if (user?.token) {
      if (showPreviews) {
        Alert.alert(t('coursePreview.noAvailable'), t('coursePreview.availableStudyPlan'), [
          {
            text: t('coursePreview.wantThis'),
            onPress: () => this.autoNavigate('CoursesDetailsScreen', {courseId: item.id})
          },
          {
            text: t('back'), onPress: () => {
            }, style: 'cancel'
          },
        ])
      } else {
        this.autoNavigate('CoursesDetailsScreen', {
          courseId: item.id
        });
      }
    } else {
      this.autoNavigate('LoginScreen', {
        message: t('messages.courseNeedsLogin',
            {domain: VALUES.DOMAIN_NAME}),
      });
    }
  };

  getSections(item) {
    const {t} = this.props;
    if (item?.sections) {
      return <View><Text>{t('courses.sectionCount', {count: item.sections.length})}</Text></View>;
    }
    return <></>;
  }

  getSectionNames(item) {
    if (item?.sections) {
      return <Text style={{fontSize: 10, marginTop: 5, color: Colors.scholastik.darkGray, marginHorizontal: 20}}> {item.sections.map((s, i) => s.title).join(" | ")}</Text>
          ;
    }
    return <></>;
  }

  render() {

    const { t, item, showPreviews } = this.props,
        hasSomeQuiz = showPreviews && item.sections?.some(section => section.items?.some(i => i.type === "lp_quiz"));

    return (
        <TouchableOpacity
            onPress={() => {
              if (hasSomeQuiz) {
                this.autoNavigate('LearningScreen', {
                  courseId: item.id,
                  sectionType: "lp_course_preview",
                  sectionId: "", //not a section
                  questionIndex: "" //no real question positions
                });
              } else {
                this.onNavigateDetail();
              }
            }}
            style={styles.container}
        >
          <View style={{flexDirection: "row", justifyContent: 'space-between', width: '100%'}}>

            <View style={{paddingTop: 12, flex: 2, marginLeft: 20}}>
              {item.on_sale ? (
                  <View
                      style={{
                        backgroundColor: '#FBC815',
                        top: 20,
                        left: 20,
                        width: 49,
                        borderRadius: 4,
                        height: 21,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                  >
                    <Text style={styles.txtSale}>{t('sale')}</Text>
                  </View>
              ) : null}

              {/*{item?.categories && item?.categories.length > 0 && (*/}
              {/*    <Text style={styles.content} numberOfLines={1}>*/}
              {/*      {item?.categories.map((x) => x.name).join(', ')}*/}
              {/*    </Text>*/}
              {/*)}*/}
              <Text numberOfLines={2} style={styles.title}>
                {item.name}
              </Text>
              {/*<View*/}
              {/*    style={{ flexDirection: 'row', marginTop: 9, alignItems: 'center' }}*/}
              {/*>*/}
              {/*  <Image source={Images.iconClock} style={styles.icon} />*/}
              {/*  <Text style={styles.txt1}>{t('durations')}</Text>*/}
              {/*  <Text style={styles.content}>{item.duration}</Text>*/}
              {/*</View>*/}

              {/*<View style={styles.viewAvatar}>
              <View>
                {item.on_sale ? (
                    <View
                        style={{
                          flexDirection: 'row',
                          height: 30,
                          backgroundColor: Colors.scholastik.white,
                          borderRadius: 4,
                          alignItems: 'center',
                          paddingHorizontal: 12,
                        }}
                    >
                      {item?.sale_price_rendered ? (
                          <Text style={styles.price}>{item.sale_price_rendered}</Text>
                      ) : (
                          <Text style={styles.price}>${item.sale_price}</Text>
                      )}
                      {item?.origin_price_rendered ? (
                          <Text style={styles.oldPrice}>
                            {item.origin_price_rendered}
                          </Text>
                      ) : (
                          <Text style={styles.oldPrice}>${item.origin_price}</Text>
                      )}
                    </View>
                ) : item.price > 0 && (
                    <View
                        style={{
                          flexDirection: 'row',
                          height: 30,
                          backgroundColor: Colors.scholastik.white,
                          borderRadius: 4,
                          alignItems: 'center',
                          paddingHorizontal: 12,
                        }}
                    >
                      {item?.price_rendered ? (
                          <Text style={styles.price}>{item?.price_rendered}</Text>
                      ) : (
                          <Text style={styles.price}>${item?.price}</Text>
                      )}
                    </View>
                )}
              </View>
              <View>
                {item.rating > 0 && (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <IconI name="star" color="#FBC815" size={15} />
                      <Text style={styles.rate}>{item.rating}</Text>
                    </View>
                )}
              </View>
            </View>*/}
              {this.getSections(item)}
            </View>

            {hasSomeQuiz && <View
                style={{
                  backgroundColor : Colors.scholastik.blue,
                  borderRadius: 6,
                  height: 30,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: 5,
                  marginRight: 20,
                  marginTop: 15,
                  maxWidth: 90,
                  flex: 1,
                }}
            >
              <Text style={{fontSize: 14, color: Colors.scholastik.white, fontFamily: 'Poppins'}}>{t('coursePreview.short')}</Text>
            </View>}
          </View>

          {this.getSectionNames(item)}

        </TouchableOpacity>
    );
  }
}
const mapStateToProps = ({ user }) => ({
  user
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ItemCourse));
