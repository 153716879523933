import qs from 'querystringify';
import {VALUES} from 'app-config';
import {asyncWithTimeout} from "../common/util";

let HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const responseOK = async (body, response, request) => {
  return {
    responseData: body,
    status: response.status
  };
};
const responseNok = async (body, response, request, msg="HTTP Error") => {
  // const sensitiveFields = ['password', 'headers', 'email'];
  // const removeSensitiveData = (data) => {
  //   if (typeof data === "object") {
  //     for (let key of sensitiveFields) delete data[key];
  //     for (let key in data) {
  //       removeSensitiveData(data[key]);
  //     }
  // }
  // try {
  //   removeSensitiveData(request);
  // } catch (e) {
  //   console.warn("Failed to remove data safely.", e);
  // }

  let reqOpts = request.options;
  if (reqOpts) {
    delete reqOpts.headers;
    if (reqOpts.body?.includes('password')) delete reqOpts.body;
  }
  console.warn(msg, response.status, request, body);
  return responseOK(body, response, request);
};

const ResponseHandlers = {
  200: responseOK,
  201: responseOK,
  202: responseOK,
  203: responseOK,
};

const logAsCURLCommand = (url, data, isGet, forceOutput=false) => {
  if (__DEV__ || forceOutput) {
    data = isGet ? '' : JSON.stringify(JSON.stringify(data));

    console.log(`
  curl -i -o - --header "Authorization: ${HEADERS.Authorization}" ${isGet ? '' : `--header "Content-Type: application/json" -d "${data}"`} "${url}"`);
  }
};

const onResponse = async (request, response) => {
  try {
    const body = await response.text();
    let newBody;
    try {
      newBody = JSON.parse(body);
    } catch (e) {
      newBody = {};
      console.error("Failed to parse the response:", e, body);
      logAsCURLCommand(request.url, request.options?.body, request.options?.method === "GET", true);
    }

    const handler = ResponseHandlers[response.status] || responseNok;
    const output = await handler(newBody, response, request);
    return output !== undefined ? output : await responseOK(newBody, response, request);

  } catch (e) {
    console.error("Error handling server response!", e);
    if (e?.type === 'object') return {};
    // SUCCESS: when response is {} and status 200 but parsing JSON failed. Still is success response
    if (response.status === 200) return {status: 200};
    throw e;
  }
};

const config = {
  post: async (endpoint: string, params: Object) => {
    const url = VALUES.SITE_URL + endpoint;
    logAsCURLCommand(url, params, false);
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { ...HEADERS, 'Content-Type': 'application/json' },
    };

    const request = {
      url,
      options,
    };

    //todo use catch on promise
    return asyncWithTimeout(
        fetch(url, options).then((result) => onResponse(request, result))
    );
  },

  get: async (endpoint: string, params: Object = {}) => {
    const url = `${VALUES.SITE_URL}${endpoint}${qs.stringify(
        { ...params, v: Math.floor(Math.random() * 999999999) },
        true
    )}`;

    logAsCURLCommand(url, params, true);
    const options = {
      method: 'GET',
      headers: HEADERS,
    };
    const request = {
      url,
      options,
    };

    return asyncWithTimeout(
        fetch(url, options).then((result) => onResponse(request, result))
    );
  },

  put: async (endpoint: string, params: Object) => {
    const url = VALUES.SITE_URL + endpoint;
    const options = {
      method: 'PUT',
      headers: HEADERS,
      body: JSON.stringify(params),
    };
    const request = {
      url,
      options,
    };
    return asyncWithTimeout(
        fetch(url, options).then((result) => onResponse(request, result))
    );
  },

  delete: async (endpoint: string, params: Object) => {
    const url = `${VALUES.SITE_URL}${endpoint}${qs.stringify(params, true)}`;
    const options = {
      method: 'DELETE',
      headers: HEADERS,
    };
    const request = {
      url,
      options,
    };
    return asyncWithTimeout(
        fetch(url, options).then((result) => onResponse(request, result))
    );
  },

  multipartPost: async (endpoint: string, params: Object) => {
    const url = VALUES.SITE_URL + endpoint;
    const options = {
      method: 'POST',
      body: params,
      headers: { ...HEADERS, 'Content-Type': 'multipart/form-data' },
    };

    const request = {
      url,
      options,
    };
    return fetch(url, options).then((result) => onResponse(request, result));
  },
};

const getApiUrl = () => VALUES.SITE_URL;
const setToken = (_token: string) => {
  HEADERS = {
    ...HEADERS,
    Authorization: `Bearer ${_token}`,
  };
};

const setClientLocale = (locale = 'vi') => {
  HEADERS = {
    ...HEADERS,
    'x-client-locale': locale,
  };
};

const setHttpHandler = (code, handler) => {
  ResponseHandlers[code] = handler;
};

export { config, getApiUrl, setToken, setClientLocale, setHttpHandler, responseOK, responseNok };
