import { StyleSheet, Dimensions } from 'react-native';
import {Colors} from "app-assets";

const deviceWidth = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    position: "relative",
    marginBottom: 20,
    backgroundColor: Colors.scholastik.white,
    borderRadius: 5,
    paddingBottom: 14,
    marginVertical: 8,
    shadowColor: Colors.scholastik.darkGray,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  item: { marginHorizontal: 12, flex: 1 },
  smallContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  image: {
    width: '100%',
    aspectRatio: 16 / 9,
    borderRadius: 6,
    resizeMode: 'cover',
    backgroundColor: '#e5e5e5',
  },
  title: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 16,
    lineHeight: 24,
    color: '#000',
    fontWeight: '600',
    marginTop: 6,
    maxWidth: '80%'
  },
  fullName: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.scholastik.white,
    marginLeft: 13,
  },
  price: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    lineHeight: 21,
    color: '#000',
    fontWeight: '500',
  },
  oldPrice: {
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: '#B0B0B0',
    textDecorationLine: 'line-through',
    marginLeft: 13,
  },
  avatar: {
    width: 51,
    height: 51,
    borderRadius: 51 / 2,
  },
  txtSale: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.scholastik.white,
    fontWeight: '600',
  },
  viewAvatar: {
    width: deviceWidth - 32 - 40,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 17,
    height: 17,
    resizeMode: 'contain',
    tintColor: '#FBC815',
  },
  icon1: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    tintColor: '#FBC815',
    marginLeft: 15,
  },
  viewContent: {
    marginLeft: 20,
    flex: 1,
  },
  content: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#939393',
  },
  txt1: {
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '500',
    marginLeft: 7,
  },
  txtPass: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#56C943',
  },
  txtFail: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#FF6161',
  },
  txtProgress: {
    fontFamily: 'Poppins',
    fontSize: 12,
    lineHeight: 18,
    color: '#58C3FF',
  },
  rate: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 12,
    lineHeight: 18,
    color: Colors.scholastik.white,
    fontWeight: '600',
    marginLeft: 6,
    marginTop: 2,
  },
});
