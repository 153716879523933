/* eslint-disable no-param-reassign */
import Types from '../actions/types';
import sharedCacheReducer from './sharedCacheReducer'
import routerConfig from "../navigations/router";

const INITIAL_STATE = {
  isAppOpen: true,
  loading: false,
  imageUri: undefined,
};

const common = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_APP_OPEN:
      delete action.type;
      return {
        ...state,
        isAppOpen: action.isAppOpen,
      };
    case Types.SET_LOADING:
      delete action.type;
      return {
        ...state,
        loading: action.loading,
      };
    case Types.SHOULD_REDIRECT:
      delete action.type;

      if (action.screenName && Object.keys(routerConfig).includes(action.screenName)) {
        return {
          ...state,
          redirectScreen: action.screenName,
          navigationReplace: action.replaceState,
          navigationParams: action.navigationParams
        };
      }
      return {
        ...state,
        redirectScreen: undefined,
        navigationReplace: undefined,
        navigationParams: undefined,
      };
    case Types.COURSE_LIGHTBOX:
      delete action.type;
      return {
        ...state,
        imageUri: action.imageUri,
      };
    case Types.CacheName.common:
      delete action.type;
      return sharedCacheReducer(state, action);
    default:
      return state;
  }
};

export default common;
