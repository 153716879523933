import Types from '../actions/types';

const subscription = (state = {}, action) => {
  switch (action.type) {
    case Types.SUBSCRIPTION_PURCHASED:
      delete action.type;
      return {
        ...state,
        [action.subscription]: action.subscriptionData
      }
    case Types.SUBSCRIPTION_TIMED_OUT:
      delete action.type;
      const stateCopy = {...state};
      delete stateCopy[action.subscription];
      return stateCopy;
    default:
      return state;
  }
};

export default subscription;
