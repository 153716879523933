import { StyleSheet, Dimensions } from 'react-native';
import {Colors} from 'app-assets';

const deviceHeight = Dimensions.get('window').height;

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.background,
    flex: 1,
    zIndex: 1,
    minHeight: deviceHeight,
  },
  menu: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  btnTopMenu: {
    marginHorizontal: 10,
  },
  btnMenuText: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '700',
    fontSize: 20,
    textTransform: 'uppercase',
    lineHeight: 24,
    marginTop: 18,
    paddingBottom: 8,
    paddingHorizontal: 5
  },
  lineButton: {
    height: 3,
    width: '100%',
    backgroundColor: Colors.scholastik.background,
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 36,
    color: Colors.scholastik.white,
    marginBottom: 20
  },
  notAvailableTitle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    marginBottom: 15,
    color: Colors.scholastik.darkGrayText
  },
  txt1: {
    fontFamily: 'Poppins-Medium',
    fontSize: 22,
    lineHeight: 25,
    fontWeight: '700',
    marginBottom: -5,

  },
  txt2: {
    fontFamily: 'Poppins-Medium',
    lineHeight: 21,
    fontWeight: '500',
    fontSize: 18,
    paddingBottom: 4
  },
  pinBox: {
    width: 50,
    height: 50,
    borderRadius: 7,
    borderWidth: 2,
    borderStyle: "solid",
    marginHorizontal: 10,
    marginVertical: 5,
    position: "relative"
  },
  pinBoxText: {
    fontSize: 22,
    fontWeight: "bold",
    lineHeight: 45,
    textAlign: "center"
  },
  pinBoxIcon: {
    position: "absolute",
    top: -5,
    right: -5,
    width: 18,
    height: 18,
    fontSize: 18,
    transform: [{ rotate: "45deg" }]
  }
});
