/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {
    Text,
    View,
    ScrollView,
    TouchableOpacity, Platform, Dimensions,
} from 'react-native';
import {Alert} from "proxy";
import IconI from 'react-native-vector-icons/Ionicons';
import {withTranslation} from 'react-i18next';
import styles from './styles'
import {Colors} from "app-assets";
import {getQuestionLetter, renderHtml} from "../../common/util";
import IconButton from "../common/iconButton";
import {VALUES} from "../../../setup/config";
import {FileLogger} from "proxy";
import Toast from "react-native-toast-message";


class Explanation extends Component {
    constructor(props) {
        super(props);
    }

    renderReportQuestion(question) {
        const {t} = this.props;
        const id = `ID ${question.id}`;

        //title might contain HTML and other ugly stuff :/
        // if (question.title?.length > 0) {
        //     id = id + ` (${question.title})`;
        // }

        return  <IconButton
            onPress={async () => {
                //todo waiting for the file API, now deletes log files before using
                await FileLogger.deleteLogFiles();
                FileLogger.sendLogFilesByEmail({
                    to: VALUES.SUPPORT_MAIL,
                    subject: t('shareMistakes.subject', {platform: Platform.OS, name: id}),
                    body: t('shareMistakes.messageBody', {name: id})
                }).then(() => {
                    Toast.show({
                        type: 'info',
                        text1: t('shareMistakes.thanks'),
                        text2: t('shareMistakes.thanksDetails'),
                        visibilityTime: 7000,
                    });
                }).catch(e => {
                    Alert.alert(
                        t(Platform.OS === 'ios' ? 'errors.shareLogs.iosTitle' : 'errors.shareLogs.title'),
                        t(Platform.OS === 'ios' ? 'errors.shareLogs.iosBody' : 'errors.shareLogs.body')
                    );
                });
            }}
            // containerStyle={{marginLeft: 30}}
            IconComponent={IconI}
            icon="flag-outline"
            containerStyle={{alignSelf: 'center', marginLeft: -15, marginTop: 50, paddingBottom: 40}}
            iconStyle={{color: Colors.scholastik.white, fontSize: 12, width: 25, height: 25}}
            title={t('shareMistakes.buttonTitle')}
        />;
    }

    render() {
        const {t, question, onToggle} = this.props;
        let solution = this.props.solution || {};
        let active = this.props.active && solution.explanation && solution.explanation !== '';

        const maxHeightPercent = (this.props.maxHeightPercent || 50);

        let wasCorrect = solution.result?.correct,
            colorFg = wasCorrect ? Colors.scholastik.greenDark : Colors.scholastik.redDark,
            colorBg = wasCorrect ? Colors.scholastik.green : Colors.scholastik.red;

        let solutionString = "";

        if (question.type === "fill_in_blanks") {
            const lstIdKeys = [];
            const item = question.options[0];
            const {ids, title_api} = item;
            ids.forEach((id) => {
                lstIdKeys.push({id, key: `{{FIB_${id}}}`});
            });

            const words = title_api.split(' ');
            solutionString = words.map((i, k) => {
                const itemKey = lstIdKeys.find((x) => x.key === i);
                return itemKey ? item.answers[itemKey.id]?.correct + " " : "";
            }).join("");
        } else {
            solutionString = (solution.optionSolutions?.filter(x => x["is_true"] === "yes").map(x =>
                getQuestionLetter(question.options.findIndex(y => y.value === x.value))).join(", "));
        }

        if (solutionString) {
            solutionString = t(solution.optionSolutions?.length > 1 ? 'learningScreen.quiz.correctWere' : 'learningScreen.quiz.correctWas')
                + " " + solutionString;
        } else {
            solutionString = false;
        }

        let toggle = () => onToggle(question);

        const renderContent = (t, advanced, toggleText, iconName) => {
            const explanation = solution.explanation || '';
            return (<View
                style={styles.explanationInnerContainer}
            >
                <View style={{flexDirection: 'row'}}>
                    <Text style={[styles.title, {color: colorFg, fontWeight: 'bold', flex: 1 }]}>
                        {wasCorrect ? t('learningScreen.quiz.correct') : t('learningScreen.quiz.inCorrect')}!
                    </Text>
                    {explanation !== '' && (<TouchableOpacity
                        style={{flexDirection: 'row'}}
                        onPressIn={toggle}
                        hitSlop={{ top: 15, bottom: 15, left: 15, right: 15}}>
                        <Text style={{fontWeight: 'bold'}}>{toggleText}</Text>
                        <IconI name={iconName} size={15} style={{marginLeft: 5, marginTop: 3}}/>
                    </TouchableOpacity>)
                    }
                </View>
                {solutionString &&
                    <Text style={{color: Colors.scholastik.textPrimary, fontWeight: 'bold', marginBottom: 20}}>{solutionString}</Text>}
                {advanced && (renderHtml(explanation, {
                    additionalRightIframeMargin: 25,
                    additionalMarginBottom: 15,
                    dispatch: this.props.dispatch
                }, {
                    width: '100%'
                }))}
                {advanced && question?.hint && (<View style={{marginBottom: 40}}>
                    <Text style={[styles.title, {marginTop: 20}]}>{t('learningScreen.quiz.videoSolution')}</Text>
                    {renderHtml(question.hint, {
                        additionalRightIframeMargin: 20,
                        additionalMarginBottom: 55,
                        dispatch: this.props.dispatch
                    }, {
                        width: '100%'
                    })}</View>)}
                {advanced && this.renderReportQuestion(question)}
            </View>);
        }

        return active ?
            <ScrollView
                scrollEnabled={true}
                contentContainerStyle={[{paddingBottom: 50}, this.props.openedContainerStyle]}
                showsVerticalScrollIndicator={Platform.OS === "web"}
                style={[styles.containerOpened, {
                    backgroundColor: colorBg,
                    height: `${maxHeightPercent}%`,
                    minHeight: 200
                }]}
            >{renderContent(t, true, t('learningScreen.quiz.hide'), 'chevron-down-outline')}</ScrollView>
            :
            <View style={[styles.containerClosed, {backgroundColor: colorBg}, this.props.styles]}>
                {renderContent(t, false, t('learningScreen.quiz.expand'), 'chevron-up-outline')}</View>
    }
}

export default withTranslation()(Explanation);
