import Types from '../actions/types';


const assign = (ctx, question) => {
  const id = question.id;
  //for type safety in case of import
  question.status = Number.parseInt(question.status);
  ctx[id] = question;
  return ctx;
};

const pins = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case Types.PINS_CACHE:
      delete action.type;
      newState = {...state};
      return assign(newState, action.question);
    case Types.PINS_UPDATE:
      delete action.type;
      newState = {...state};
      return action.questions.reduce(
          (accumulator, currentValue) => assign(accumulator, currentValue),
          newState
      );
    case Types.PINS_DELETE:
      delete action.type;
      newState = {...state};
      delete newState[action.id];
      return newState;
    case Types.PINS_METADATA:
      delete action.type;
      return {
        ...state,
        categoryData: action.data,
      };
    case Types.PINS_ERASE:
      return {};
    default:
      return state;
  }
};

export default pins;
