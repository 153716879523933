import React from 'react'
import CommentsSection from "./libs/comments/CommentsSection";
import {Routines} from "app-common";
import {setLoading} from "../actions/common";
import Toast from "react-native-toast-message";
import {withTranslation} from "react-i18next";


class Comments extends React.Component {

    async getData() {
        const {user, postId} = this.props;
        return await Routines.getCommentsFor(postId, user);
    }

    async createComment (content, parentId) {
        const {user, postId, t, dispatch} = this.props;
        try {
            dispatch(setLoading(true));
            return await Routines.addComment(postId, {
                user: user,
                content: content,
                parent: parentId,
            });
        } catch (error) {
            console.error('Error creating comment:', error);
            Toast.show({
                type: 'error',
                text1: t('errors.comments.failedToPost'),
                text2: error.message || undefined,
                visibilityTime: 7000,
            });
            return null;
        } finally {
            dispatch(setLoading(false));
        }
    }

    async deleteComment (commentId) {
        const {user, postId, t, dispatch} = this.props;

        try {
            dispatch(setLoading(true));
            return await Routines.deleteComment(postId, commentId, user);
        } catch (e) {
            console.error('Error deleting comment:', error);
            Toast.show({
                type: 'error',
                text1: t('errors.comments.failedToDelete'),
                text2: error.message || undefined,
                visibilityTime: 7000,
            });
        } finally {
            dispatch(setLoading(false));
        }
    };

    render() {
        const {user} = this.props;
        return (
            <CommentsSection
                userId={user.info.id}
                fetchComments={() => this.getData()}
                createComment={(content, parentId) => this.createComment(content, parentId)}
                deleteComment={id => this.deleteComment(id)}
                editComment={x => {}}
            />
        );
    }
}

export default withTranslation()(Comments);