export default {
  subscriptionScreen: {
    subtitle: 'Pro přijímačky OSP Scio, ZSV Scio a TSP MUNI',
  },
  products: {
    osp: {
      title: 'Obecné studijní předpoklady',
      description:
        'V Česku jsou nejběžnější přijímačky na vysokou testy OSP (Obecné studijní předpoklady) společnosti Scio. Ty se píší v rámci Národních srovnávacích zkoušek (NSZ).\n' +
        '\n' +
        'Teoreticky k jejich úspěšnému zvládnutí nejsou potřeba žádné konkrétní znalosti. Přesto je dobré přípravě věnovat dostatek času.',
    },
    zsv: {
      title: 'Základy společenských věd',
      description:
        'Pokud plánuješ studium humanitního oboru na vysoké škole, s největší pravděpodobností tě čeká přijímací zkouška Základy společenských věd společnosti Scio.\n' +
        '\n' +
        'Jedná se o vstupní předpoklad pro většinu fakult, které se zabývají právě humanitními vědami.  Učivo, které je ale zkoušené v testech ZSV Scio, v některých oborech silně přesahuje učivo učené na gymnáziích. Doporučujeme nepodcenit přípravu a začít s učením v dostatečném předstihu.',
    },
    tsp: {
      title: 'MUNI: Testy studijních předpokladů',
      description:
        'Pokud se chceš stát studenty Masarykovy univerzity, budeš muset s největší pravděpodobností skládat právě Test studijních předpokladů neboli TSP.\n' +
        '\n' +
        'Test si vytváří sama univerzita. Až na malé odlišnosti je test vcelku podobný testu Obecných studijních předpokladů (OSP) od společnosti Scio. I tady je třeba přípravě věnovat náležitý čas.',
    },
    any: {
      bullet1: 'Stovky řešených úloh z každého předmětu',
      bullet2: 'Testy s vyhodnocením a percentilem',
      bullet3: 'Synchronizace s webovým rozhraním',
      bullet4: 'Desítky hodin videí',
    },
  },
  errors: {
    access401: {
      body: 'Pokud si myslíš, že se jedná o chybu, napiš prosím na info@scholastik.cz',
    },
    accessService: {
      body: "Pravděpodobně se jedná o chybu, sdílej prosím chování aplikace přes 'Účet' nebo napiš na info@scholastik.cz. Děkujeme",
    },
    shareLogs: {
      body: 'Pokud chceš sdílet problémy s aplikací, napiš prosím na info@scholastik.cz. Děkujeme.',
    },
    bodyGeneral:
      "můžeš sdílet podrobnosti o chybě s vývojáři přes 'Účet' nebo napiš na info@scholastik.cz. Děkujeme.",
  },
};
