import React, { Component } from 'react';
import {
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Keyboard, Platform,
} from 'react-native';
import {Alert, BackHandler, KeyboardAwareScrollView} from "proxy";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HttpAPI } from 'app-api';
import { Images } from 'app-assets';
import IconF from 'react-native-vector-icons/Feather';

import {setLoading, showLoading} from '../../actions/common';
import { saveUserToken, setUser } from '../../actions/user';
import styles from './styles';
import {Colors} from 'app-assets';
import DeviceInfo from "react-native-device-info";
import {Routines} from "../../common";
import {CommonActions} from "@react-navigation/native";
import {CommonAppLayout, ContentMiddleLayout} from "../../component";
import {VALUES} from "app-config";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGenaral: true,
      isPassword: false,
      isDeleteAccount: false,
      avatar: undefined,

      description: props.user?.info?.description || '',
      firstName: props.user?.info?.first_name || '',
      lastName: props.user?.info?.last_name || '',
      nickName: props.user?.info?.nickname || '',

      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showCurrentPassword: true,
      showNewPassword: true,
      showConfirmPassword: true,
      deletePassword: '',
    };
  }

  async componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }

  handleBackPress = () => {
    this.autoGoBack();
    return true;
  };

  goBack = () => {
    this.autoGoBack();
  };

  onClickGenaral = () => {
    this.setState({
      isGenaral: true,
      isPassword: false,
      isDeleteAccount: false,
    });
  };

  onClickPassword = () => {
    this.setState({
      isGenaral: false,
      isPassword: true,
      isDeleteAccount: false,
    });
  };

  onClickDeleteAccount = () => {
    this.setState({
      isGenaral: false,
      isPassword: false,
      isDeleteAccount: true,
    });
  };

  // onUpload = async () => {
  //   const { user } = this.props;
  //
  //   ImagePicker.openPicker({
  //     width: Number(user?.info?.avatar_size?.width) || 250,
  //     height: Number(user?.info?.avatar_size?.height) || 250,
  //     cropping: true,
  //     multiple: false,
  //     mediaType: 'photo',
  //   }).then((image) => {
  //     this.setState({ avatar: image });
  //   });
  // };

  onRemove = () => {
    this.setState({ avatar: null });
  };

  onLogout = async () => {
    const { dispatch } = this.props;
    dispatch(setLoading(true));
    await Routines.logout(this.props);
    dispatch(setLoading(false));
    this.autoGoHome();
  };

  submitGeneral = async () => {
    Keyboard.dismiss();

    const { t, dispatch, user } = this.props;

    const { avatar, description, firstName, lastName, nickName } = this.state;

    if (nickName.trim() === '') {
      Alert.alert('Please enter nickname');
      return;
    }

    const param = new FormData();
    param.append('first_name', firstName);
    param.append('last_name', lastName);
    param.append('nickname', nickName);
    //param.append('description', description);

    if (avatar) {
      const file = {
        uri: avatar?.path,
        type: avatar?.mime,
        name: avatar?.path.split('/').pop(),
      };

      param.append('lp_avatar_file', file);
    }

    await dispatch(showLoading(true));

    const response = await HttpAPI.updateUser(user?.info?.id, param).then(r => r?.responseData);

    await dispatch(showLoading(false));

    if (response?.code) {
      Alert.alert(response?.message || 'Error');
    } else {
      Alert.alert(t('settings.saved'));
      dispatch(setUser(response));
    }
  };

  submitPassword = async () => {
    Keyboard.dismiss();

    const { dispatch, t } = this.props;
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (oldPassword.trim() === '' || newPassword.trim() === '') {
      Alert.alert(t('setting.fillPasswords'));
      return;
    }

    if (newPassword !== confirmPassword) {
      Alert.alert('settings.noMatch');
      return;
    }

    const params = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    await dispatch(showLoading(true));

    const response = await HttpAPI.changePassword(params).then(r => r?.responseData);

    await dispatch(showLoading(false));

    if (response.code === 'success') {
      Alert.alert(response.message);
      this.onLogout();
    } else {
      Alert.alert(response.message);
    }
  };

  onDeletedAccount = async () => {
    const { dispatch, user, t } = this.props;
    const { deletePassword } = this.state;

    if (!deletePassword) {
      Alert.alert(t('settings.enterPassword'));
      return;
    }

    await dispatch(showLoading(true));

    const response = await HttpAPI.deleteAccount({
      id: user?.info?.id,
      password: deletePassword,
    }).then(r => r?.responseData);

    await dispatch(showLoading(false));

    if (response.code === 'success') {
      Alert.alert(response.message);
      this.onLogout();
    } else {
      Alert.alert(response.message);
    }
  };

  toggleShowCurrentPassword = () => {
    const { showCurrentPassword } = this.state;
    this.setState({ showCurrentPassword: !showCurrentPassword });
  };

  toggleShowNewPassword = () => {
    const { showNewPassword } = this.state;
    this.setState({ showNewPassword: !showNewPassword });
  };

  toggleShowConfirmPassword = () => {
    const { showConfirmPassword } = this.state;
    this.setState({ showConfirmPassword: !showConfirmPassword });
  };

  render() {
    const {
      isGenaral,
      isPassword,
      isDeleteAccount,
      avatar,
      firstName,
      lastName,
      nickName,
      showCurrentPassword,
      showNewPassword,
      showConfirmPassword,
    } = this.state;
    const { t } = this.props;
    return (
      <CommonAppLayout edges={['right', 'left', 'top']}>
        <KeyboardAwareScrollView
            showsVerticalScrollIndicator={Platform.OS === "web"}
            bounces={false}
            contentContainerStyle={styles.container}
            keyboardShouldPersistTaps="always"
        >
          <ContentMiddleLayout>
            <ScrollView
                contentContainerStyle={{ paddingBottom: 100 }}
                keyboardShouldPersistTaps="handled"
                showsVerticalScrollIndicator={Platform.OS === "web"}
                bounces={false}
            >
              <View style={styles.header}>
                <View style={styles.header1}>
                  <TouchableOpacity
                      onPress={this.goBack}
                      style={{marginLeft: 10}}
                      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                  >
                    <Image source={Images.iconBack} style={styles.iconBack} />
                  </TouchableOpacity>
                  <Text style={styles.title}>{t('settings.title')}</Text>
                  <View
                      style={{
                        width: 40,
                        height: 40,
                      }}
                  />
                </View>
                <Text
                    style={{
                      fontSize: 10,

                      textAlign: 'center',

                      color: '#666',
                    }}
                >
                  Version: {DeviceInfo.getVersion()} - Build:{' '}
                  {DeviceInfo.getBuildNumber()}
                </Text>
              </View>
              <View style={styles.content}>
                <View style={styles.menu}>
                  <TouchableOpacity
                      style={styles.btnGenaral}
                      onPress={this.onClickGenaral}
                  >
                    <Text
                        style={[
                          styles.txtActive,
                          { color: isGenaral ? Colors.scholastik.textPrimary : '#999' },
                        ]}
                    >
                      {t('settings.general')}
                    </Text>
                    {isGenaral && <View style={styles.lineButton} />}
                  </TouchableOpacity>
                  <TouchableOpacity
                      style={styles.btnGenaral}
                      onPress={this.onClickPassword}
                  >
                    <Text
                        style={[
                          styles.txtActive,
                          { color: isPassword ? Colors.scholastik.textPrimary : '#999' },
                        ]}
                    >
                      {t('settings.password')}
                    </Text>
                    {isPassword && <View style={styles.lineButton} />}
                  </TouchableOpacity>
                  <TouchableOpacity onPress={this.onClickDeleteAccount}>
                    <Text
                        style={[
                          styles.txtActive,
                          { color: isDeleteAccount ? Colors.scholastik.textPrimary : '#999' },
                        ]}
                    >
                      {t('settings.deleteAccount')}
                    </Text>
                    {isDeleteAccount && <View style={styles.lineButton} />}
                  </TouchableOpacity>
                </View>
                {isGenaral && (
                    <View>
                      <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 14,
                          }}
                      >
                        <Text style={styles.titleChild}>
                          {t('settings.firstName')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            value={firstName}
                            onChangeText={(value) =>
                                this.setState({ firstName: value })
                            }
                        />
                      </View>
                      <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 14,
                          }}
                      >
                        <Text style={styles.titleChild}>
                          {t('settings.lastName')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            value={lastName}
                            onChangeText={(value) => this.setState({ lastName: value })}
                        />
                      </View>
                      <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 14,
                          }}
                      >
                        <Text style={styles.titleChild}>
                          {t('settings.nickName')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            value={nickName}
                            onChangeText={(value) => this.setState({ nickName: value })}
                        />
                      </View>
                      <TouchableOpacity
                          style={{
                            backgroundColor: Colors.scholastik.green,
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 8,
                            marginTop: 14,
                          }}
                          onPress={() => this.submitGeneral()}
                      >
                        <Text style={styles.titleChild}>{t('settings.save')}</Text>
                      </TouchableOpacity>
                    </View>
                )}
                {isPassword && (
                    <View>
                      <View style={{ marginBottom: 14 }}>
                        <Text style={styles.titleChild}>
                          {t('settings.currentPassword')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            secureTextEntry={showCurrentPassword}
                            onChangeText={(value) =>
                                this.setState({ oldPassword: value })
                            }
                        />
                        <TouchableOpacity
                            style={styles.btnViewPass}
                            onPress={() => this.toggleShowCurrentPassword()}
                        >
                          {showCurrentPassword ? (
                              <IconF name="eye" color="#D2D2D2" size={18} />
                          ) : (
                              <IconF name="eye-off" color="#D2D2D2" size={18} />
                          )}
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginBottom: 14 }}>
                        <Text style={styles.titleChild}>
                          {t('settings.newPassword')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            secureTextEntry={showNewPassword}
                            onChangeText={(value) =>
                                this.setState({ newPassword: value })
                            }
                        />
                        <TouchableOpacity
                            style={styles.btnViewPass}
                            onPress={() => this.toggleShowNewPassword()}
                        >
                          {showNewPassword ? (
                              <IconF name="eye" color="#D2D2D2" size={18} />
                          ) : (
                              <IconF name="eye-off" color="#D2D2D2" size={18} />
                          )}
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginBottom: 14 }}>
                        <Text style={styles.titleChild}>
                          {t('settings.confirmNewPassword')}
                        </Text>
                      </View>
                      <View style={styles.viewInput}>
                        <TextInput
                            style={styles.txtInput}
                            numberOfLines={1}
                            secureTextEntry={showConfirmPassword}
                            onChangeText={(value) =>
                                this.setState({ confirmPassword: value })
                            }
                        />
                        <TouchableOpacity
                            style={styles.btnViewPass}
                            onPress={() => this.toggleShowConfirmPassword()}
                        >
                          {showConfirmPassword ? (
                              <IconF name="eye" color="#D2D2D2" size={18} />
                          ) : (
                              <IconF name="eye-off" color="#D2D2D2" size={18} />
                          )}
                        </TouchableOpacity>
                      </View>
                      <TouchableOpacity
                          style={{
                            backgroundColor: Colors.scholastik.green,
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 8,
                            marginTop: 14,
                          }}
                          onPress={() => this.submitPassword()}
                      >
                        <Text style={styles.titleChild}>{t('settings.savePwd')}</Text>
                      </TouchableOpacity>
                    </View>
                )}

                {isDeleteAccount && (
                    <View>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                            style={{
                              fontFamily: 'Poppins',
                              fontSize: 14,
                              color: '#444',
                              marginBottom: 20,
                            }}
                        >
                          {t('settings.deleteAccountTitle')}
                        </Text>
                        <Text
                            style={{
                              fontFamily: 'Poppins-Medium',
                              fontSize: 14,
                              color: '#222',
                              marginBottom: 10,
                            }}
                        >
                          {t('settings.deleteAccountTitle2')}
                        </Text>
                        <Text
                            style={{
                              fontFamily: 'Poppins',
                              fontSize: 14,
                              color: '#444',
                              marginBottom: 20,
                            }}
                        >
                          {t('settings.deleteAccountContent')}
                        </Text>
                        <Text
                            style={{
                              fontFamily: 'Poppins-Bold',
                              fontSize: 16,
                              color: '#222',
                              marginBottom: 20,
                              backgroundColor: Colors.scholastik.red,
                              borderLeftWidth: 4,
                              paddingLeft: 18,
                              paddingRight: 12,
                              paddingVertical: 8,
                              borderLeftColor: Colors.scholastik.redDark
                            }}>
                          {t('settings.deleteAccountWarn', {platform: Platform.OS === "ios" ? "App Store" : (Platform.OS === "web" ? "aplikaci" : "Google Play"), domain: VALUES.DOMAIN_NAME})}
                        </Text>
                        <Text
                            style={{
                              fontFamily: 'Poppins-Medium',
                              fontSize: 14,
                              color: '#222',
                              marginBottom: 10,
                            }}
                        >
                          {t('settings.deleteAccountContent2')}
                        </Text>

                        <View style={styles.viewInput}>
                          <TextInput
                              style={styles.txtInput}
                              numberOfLines={1}
                              secureTextEntry
                              onChangeText={(value) =>
                                  this.setState({ deletePassword: value })
                              }
                          />
                        </View>
                        <TouchableOpacity
                            style={{
                              backgroundColor: Colors.scholastik.red,
                              height: 42,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderRadius: 8,
                              marginTop: 14,
                              borderWidth: 1,
                              borderColor: Colors.scholastik.red,
                            }}
                            onPress={() => this.onDeletedAccount()}
                        >
                          <Text
                              style={{
                                fontFamily: 'Poppins',
                                fontSize: 14,
                                lineHeight: 24,
                                color:  Colors.scholastik.redDark,
                              }}
                          >
                            {t('settings.deleteAccountBtn')}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                )}
              </View>
            </ScrollView>
          </ContentMiddleLayout>
        </KeyboardAwareScrollView>
      </CommonAppLayout>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Settings));
