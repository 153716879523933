import {StyleSheet, Dimensions, Platform} from 'react-native';
import {Colors} from 'app-assets';

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
  },
  imgBottom: {
    marginTop: 10,
    resizeMode: 'contain',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 50,
  },
  textBottom: {
    marginTop: 40,
    fontSize: 14,
    color: '#000',
    fontFamily: 'Poppins',
    fontWeight: '400',
  },
  logo: {
    width: '100%',
    height: '100%',
    minWidth: 80,
    minHeight: 80,
    marginVertical: 20,
    resizeMode: 'contain',
    maxWidth: 140,
    maxHeight: 140
  },
  viewLogo: {
    alignSelf: 'center',
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    fontFamily: 'Poppins-Medium',
    marginBottom: 25
  },
  loginMessage: {
    textAlign: 'center',
    paddingHorizontal: 40,
    paddingVertical: 5,
    marginTop: 25,
    width: '100%',
    backgroundColor: Colors.scholastik.gray,
    fontFamily: 'Poppins',
  },
  containerImg: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    fontSize: 15,
    color: Colors.scholastik.textPrimary,
  },
  textInput: {
    flex: 1,
    height: 45,
    color: Colors.scholastik.textPrimary,
    fontFamily: 'Poppins',
    fontSize: 14,
},
  icEnter: {
    width: 17,
    height: 17,
    resizeMode: 'contain',
  },
  button: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
    top: deviceHeight / 2 - 20,
  },
  nextButton: {
    height: (264 / 375) * deviceWidth,
    width: (264 / 375) * deviceWidth,
    resizeMode: 'contain',
  },
  iconBack: {
    height: 22,
    width: 22,
    tintColor: Colors.scholastik.textTertiary,
    resizeMode: 'contain',
  },
  txtAccept: {
    fontFamily: 'Poppins',
    fontSize: 13,
    color: Colors.scholastik.darkGray,
    fontWeight: '400',
  },
  iconCheck: {
    fontSize: 22,
    color: Colors.scholastik.darkGray,
    marginRight: 12,
  },
  btnSubmit: {
    marginTop: 10,
    flex: 1,
    height: 50,
    backgroundColor: Colors.scholastik.purple,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  btnThirdPartyLoginSmall: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40
  },
  btnRegister: {
    marginTop: 20,
    flex: 1,
    height: 50,
    borderWidth: 1,
    borderColor: Colors.scholastik.purple,
    backgroundColor: Colors.scholastik.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  btnApple: {
    verticalAlign: "middle",
    marginTop: 25,
    marginBottom: 20,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: Colors.scholastik.darkGray,
    flex: 1,
    paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  txtSubmit: {
    fontFamily: 'Poppins',
    fontSize: 18,
    color: Colors.scholastik.white,
    fontWeight: '400',
  },
  line: {
    width: 90,
    height: 1,
    backgroundColor: '#DBDBDB',
  },
  iconFacebook: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  iconTwitter: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  iconGoogle: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
  txtForgot: {
    marginTop: 25,
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '400',
    color: '#569EC6',
  },
  viewLine: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 250,
    alignSelf: 'center',
    marginTop: 71,
  },
  viewButton: {
    marginTop: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 120,
    alignSelf: 'center',
  },
  viewInput: {
    flex: 1,
    color: Colors.scholastik.textPrimary,
    backgroundColor: '#F3F3F3',
    borderRadius: 4,
    marginBottom: 16,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 2,
    marginTop: 25,
    borderColor: '#F3F3F3',
  },
});
