//Modified MathJax library: https://github.com/calvinkei/react-native-mathjax
import React from 'react';
import {View, Platform} from 'react-native';
import {Colors} from "app-assets";

class MathJax extends React.Component {
    constructor(props) {
        super(props);
        this._needsRender = false;
        this.id = "rn-wweb-mock-" + Date.now();
    }

    //inspired by https://github.com/mohammad-haque/react-native-webview-custom-font
    wrapMathjax(content) {
        return `
    <style>
        #formula {
            font-family: ${this.props.font || 'Poppins-Light'};
            color: ${Colors.scholastik.textPrimary};
            font-weight: 300;
            background-color: transparent;
            overflow: auto;
            font-size: 13px; 
            margin-bottom: ${Number.parseInt(this.props.additionalMarginBottom || 0)}px;
        } 
        #formula table {
            margin: 0 auto;
            border: 3px solid;
            border-collapse: collapse;
            table-layout:fixed;
            /*width: 95vw !important;*/
        }
        #formula td, th {
            border: 1px solid;
            padding: 6px;
            overflow: hidden; 
            text-overflow: ellipsis; 
            word-wrap: normal !important;
            max-width: 40vw !important;
        }
        #formula img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 10px 0;
        }
        #formula .separate-line-equation {
            text-align: center;
            line-height: 3em;
        }
    </style>
    <div id="formula" style="visibility: hidden">${content}</div>
`;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this._needsRender) {
            this._needsRender = false;
            return true;
        }
        return super.shouldComponentUpdate(nextProps, nextState);
    }

    render() {
        const html = this.wrapMathjax(this.props.html);

        // Create new props without `props.html` field. Since it's deprecated.
        const props = Object.assign({style:{}}, this.props, { html: undefined });
        delete props.font;
        delete props.fontFileEnding;
        delete props.stamp;
        delete props.style.height;

        return <View style={props.style}
                     id={this.id}
                     ref={ref => {
                         if (ref) {
                             this._needsRender = true;
                             ref.innerHTML = html;
                             ref.style.height = undefined;

                             const node = document.createElement("script");
                             node.appendChild(document.createTextNode("window.MathJax?.typeset();document.getElementById('formula').style.visibility = '';"));
                             ref.appendChild(node);
                         }
                     }}
        />;
    }
}

export default MathJax;