/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ImageBackground,
} from 'react-native';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './styles/item-category';
import {Colors, Images} from "app-assets";
import {safeReadMeta} from "../../common";
import IconI from "react-native-vector-icons/Ionicons";
class ItemCategory extends PureComponent {

  render() {
    const { t, item, wishlist, setCategory, capitalize, premiumList } = this.props;
    //const dataWishlist = wishlist?.data;
    // const ids =
    //   wishlist && dataWishlist.length > 0 ? dataWishlist.map((x) => x.id) : [];
    const imageUrl = safeReadMeta(item.meta, 'image', undefined),
        hasImage = imageUrl && imageUrl !== '',
        image = hasImage ? {uri: imageUrl} : undefined,
        itemPremiumRequiredList = item.meta?.subscription,
        hasDescription = item.description !== "";
    const pressHandler = () => setCategory(item);
    const showPremiumOwnership = Array.isArray(itemPremiumRequiredList),
        categoryHasPremium = showPremiumOwnership && itemPremiumRequiredList.every(slug => slug && premiumList?.includes(slug));

    return (
        <TouchableOpacity
            onPress={pressHandler}
            style={[styles.container, {position: "relative"}]}
        >
          <ImageBackground
              source={image}
              style={[styles.image, {aspectRatio: hasImage ? 16/9 : (hasDescription ? 2 : 4)}]}
              imageStyle={{borderRadius: 6}}
          >
            {/*todo fnuk srdicka vratit moc pekny <TouchableOpacity*/}
            {/*    onPress={this.onToggleWishlish}*/}
            {/*    style={{*/}
            {/*      position: 'absolute',*/}
            {/*      top: 10,*/}
            {/*      right: 15,*/}
            {/*      backgroundColor: ids.includes(item.id)*/}
            {/*          ? '#FBC815'*/}
            {/*          : 'rgba(0,0,0,0.2)',*/}
            {/*      borderRadius: 6,*/}
            {/*      height: 40,*/}
            {/*      width: 40,*/}
            {/*      alignItems: 'center',*/}
            {/*      justifyContent: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*  <IconI name="heart-outline" color="#fff" size={22}/>*/}
            {/*</TouchableOpacity>*/}
              <Image
                  source={Images.backgroundBanner}
                  style={{ position: 'absolute', bottom: 0, width: '100%', height: '70%', borderRadius: 6 }}
              />
          </ImageBackground>
            {
                showPremiumOwnership && categoryHasPremium && <View
                    style={{position: "absolute", top: 15, right: 15, paddingHorizontal: 7, paddingVertical: 4,
                        borderRadius: 7,
                        backgroundColor:Colors.scholastik.white, opacity: 0.65, flexDirection: "row"}}>
                    <IconI name="checkmark-done-circle-outline" size={18} color={Colors.scholastik.greenDark}/>
                    <Text style={{color: Colors.scholastik.greenDark}}> {t('activePlan')}</Text>
                </View>
            }
          <View style={{padding: 15, position: "absolute", bottom: 15}}>
            {item?.categories && item?.categories.length > 0 && (
                <Text style={styles.content} numberOfLines={1}>
                  {item?.categories.map((x) => x.name).join(', ')}
                </Text>
            )}
            <Text numberOfLines={1} style={[styles.title, {color: Colors.scholastik.white}]}>
              {capitalize ? item.name.toUpperCase() : item.name}
            </Text>
            {hasDescription &&
            (<View style={{flexDirection: 'row', marginTop: 9, alignItems: 'center'}}>
              <Text style={[styles.content, {color: Colors.scholastik.white}]} numberOfLines={3}>{item.description}</Text>
            </View>)
            }
          </View>
        </TouchableOpacity>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapDispatchToProps
)(withTranslation()(ItemCategory));
