import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import {VALUES} from "../../setup/config";

export function configStore() {
  const persistConfig = {
    key: VALUES.DOMAIN_NAME,
    storage: AsyncStorage,
    whitelist: ['debug', 'user', 'wishlist', 'pins', 'cached', 'app', 'failed', 'subscription'],
    blacklist: ['form', 'course', 'category'],
    debug: __DEV__
  };

  const persistedReducer = persistCombineReducers(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  return { store, persistor };
}