import { StyleSheet } from 'react-native';
import {Colors} from 'app-assets';

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.background,
    flex: 1,
    zIndex: 1,
    height: '100%'
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 36,
    color: Colors.scholastik.textPrimary,
    marginBottom: 20
  },
  banner: {
    maxWidth: 300,
    resizeMode: "contain",
    maxHeight: 250,
  },
  notAvailableTitle: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    marginBottom: 15,
    color: Colors.scholastik.darkGrayText
  },
  txt1: {
    fontFamily: 'Poppins-Medium',
    fontSize: 22,
    lineHeight: 25,
    fontWeight: '700',
    marginBottom: -5,
  },
  txt2: {
    fontFamily: 'Poppins-Medium',
    lineHeight: 21,
    fontWeight: '500',
    fontSize: 18,
    paddingBottom: 4
  },
});
