// Site URL
import {Platform} from 'react-native';
import {ProductRenderers} from './productRenderers';

const VALUES = {
  SITE_URL: 'http://localhost:5000',
  SCHEME: 'cz.scholastik',
  SCHEME_CTX: 'oscio.cz',
  DOMAIN_NAME: 'oscio.cz',
  ZASADY: 'https://oscio.cz/zasady-ochrany/',
  OBCHODNI: 'https://oscio.cz/obchodni-podminky/',
  DEV_MAIL: 'horak.j@oscio.cz',
  SUPPORT_MAIL: 'info@oscio.cz',
  LOGROCKET_ID: '',
  COPYRIGHTS: 'Scholastik 2023. Všechna práva vyhrazena.',
  CODE_PUSH: null,
  //Behavioral/rendering setup
  WINDOW_MAX_WIDTH: 720,
  UNDER_HOOD_REQUESTS_RETRY_COUNT: 5,
  ERASE_CACHE_ON_UPDATE: true,
  EXPIRY_TSTAMP: {
    ALWAYS: -1,
    DAY: 86400000,
    THREE_DAYS: 3 * 86400000,
    WEEK: 7 * 86400000,
    MONTH: 30 * 86400000,
    NEVER: 365 * 31 * 86400000,
  },
};

const setConst = function (key, value) {
  VALUES[key] = value;
};

export const getViaWebLoginUrl = deeplink => false;

export const getDeepLink = (path = '') => {
  return `${VALUES.SCHEME}://${VALUES.SCHEME_CTX}/${path}`;
};

// List products In App Purchases
// no IAP on web
const SUBSCRIPTIONS_IAP = [];
const PRODUCTS_IAP = [];
const PREMIUM_TO_SUBSCRIPTIONS_MAP = {};
const SUBSCRIPTIONS_TO_PREMIUM_MAP = {};


export {
  setConst,
  PRODUCTS_IAP,
  SUBSCRIPTIONS_IAP,
  PREMIUM_TO_SUBSCRIPTIONS_MAP,
  SUBSCRIPTIONS_TO_PREMIUM_MAP,
  ProductRenderers,
  VALUES
};
