/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
    Text,
    View,
    ScrollView,
    TouchableOpacity,
    FlatList,
    Dimensions,
    SafeAreaView, Platform
} from 'react-native';
import IconF from 'react-native-vector-icons/Feather';
import IconI from 'react-native-vector-icons/Ionicons';
import { withTranslation } from 'react-i18next';
import styles from './styles/review-quiz';
import MathJax from "../common/math-jax";
import {Colors} from "app-assets";
import {getQuestionLetter, getStatusBarHeight, renderHtml} from "../../common/util";
import Explanation from "../explanation";
import {SvgChecked, SvgUnchecked} from "../common/svg-checked";
import {CommonAppLayout, ContentMiddleLayout, SafeModal} from "../commonAppLayout";

const deviceHeight = Dimensions.get('window').height;

class ReviewQuiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageActive: 0,
            itemQuestion: null,
            data: null,
            dataQuiz: null,
            isVisible: false,
            activeExplanations: [],
        };
    }

    UNSAFE_componentWillMount() {
        const { data } = this.props;
        let activeQuestionIndex = this.props.activeQuestionIndex || 0;
        if (activeQuestionIndex >= data.questions.length) {
            activeQuestionIndex = 0;
        }
        this.setState({
            data,
            dataQuiz: data,
            itemQuestion: data.questions[activeQuestionIndex],
            pageActive: activeQuestionIndex
        });
        this.checkIfMissingAnswer(data.questions[activeQuestionIndex]);
    }

    updateUrl = (questionId) => {
        // Workaround: change the URL to reflect the state on web
        if (Platform.OS === "web") {
            const {courseId, type, id} = this.props.item;
            if (courseId && type) {
                const url = `/kurzy/${courseId}/${type}/${id}/${questionId}`;
                window.history.replaceState({}, '', url);
            }
        }
    }

    toggleExplanation(question) {
        let newState = [...this.state.activeExplanations];
        newState[question.id] = !newState[question.id];
        this.setState({ activeExplanations: newState });
    }

    // selectQuestion(item) {
    //     const { itemQuestion } = this.state;
    //
    //     if (
    //         itemQuestion.type === 'single_choice' ||
    //         itemQuestion.type === 'true_or_false'
    //     ) {
    //         itemQuestion.answer = [item];
    //         this.forceUpdate();
    //     }
    //     if (itemQuestion.type === 'multi_choice') {
    //         if (itemQuestion?.answer) {
    //             const temp = itemQuestion?.answer.find((x) => x.value === item.value);
    //             if (temp) {
    //                 itemQuestion.answer = itemQuestion.answer.filter(
    //                     (x) => x.value !== item.value
    //                 );
    //                 this.forceUpdate();
    //             } else {
    //                 itemQuestion.answer = [...itemQuestion.answer, item];
    //                 this.forceUpdate();
    //             }
    //         } else {
    //             itemQuestion.answer = [item];
    //             this.forceUpdate();
    //         }
    //     }
    // }

    onPrevQuiz = () => {
        const { pageActive, dataQuiz } = this.state,
            nextIndex = pageActive > 0 ? (pageActive - 1) : dataQuiz.questions.length - 1;
        this.flatListRef.scrollToIndex({
            index: nextIndex,
            animated: true,
        });
        let newQuestion = dataQuiz.questions[nextIndex];
        this.setState({
            itemQuestion: newQuestion,
            pageActive: nextIndex,
        });
        this.checkIfMissingAnswer(newQuestion);
        this.updateUrl(nextIndex);
    };

    onNextQuiz = () => {
        const { pageActive, dataQuiz } = this.state,
            nextIndex = (pageActive + 1) % dataQuiz.questions.length;

        this.flatListRef.scrollToIndex({
            index: nextIndex,
            animated: true,
        });

        let newQuestion = dataQuiz.questions[nextIndex];
        this.setState({
            itemQuestion: newQuestion,
            pageActive: nextIndex,
        });
        this.checkIfMissingAnswer(newQuestion);
        this.updateUrl(nextIndex);
    };

    checkIfMissingAnswer = (itemQuestion)=> {
        let activeExplain = this.state.activeExplanations;

        if (activeExplain[itemQuestion.id] === undefined
            && !itemQuestion.options.some(opt => opt.is_true === "yes")) {

            let newState = [...this.state.activeExplanations];
            newState[itemQuestion.id ] = true;
            this.setState({ activeExplanations: newState });
        }
    }

    renderHeaderItem = ({ item, index }) => {
        const { pageActive } = this.state;
        // if (index > 5 && pageActive < 5)
        //   return <Text style={{ marginLeft: 3, marginTop: 3 }}>...</Text>;
        return (
            <TouchableOpacity
                onPress={() => {
                    this.setState({
                        pageActive: index,
                        itemQuestion: item,
                    })
                    this.checkIfMissingAnswer(item);
                    this.updateUrl(index);
                }}
                style={[
                    styles.btnPage,
                    {
                        backgroundColor: pageActive === index ? Colors.scholastik.purple : Colors.scholastik.white,
                        borderColor: pageActive === index ? Colors.scholastik.purple : '#E4E4E4',
                    },
                ]}
            >
                <Text style={[styles.txtPage, {
                    color: pageActive === index ? Colors.scholastik.white : Colors.scholastik.black,
                }]}>{index + 1}</Text>
            </TouchableOpacity>
        );
    };

    itemOption = (item, i) => {
        const { data, itemQuestion } = this.state;
        const userAnswer = data.results.answered[itemQuestion.id].answered;
        const wasAnswer = userAnswer.includes(item.value);

        if (data.results.answered[itemQuestion.id]?.correct && wasAnswer) {
            return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.greenDark, Colors.scholastik.green, wasAnswer, false);
        }
        if (item?.is_true === 'yes') {
            return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.greenDark, Colors.scholastik.red, wasAnswer, false);
        }
        if (wasAnswer) {
            return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.redDark, Colors.scholastik.red, wasAnswer, false);
        }
        return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.purple, Colors.scholastik.white, wasAnswer, false);

        // return (
        //   <TouchableOpacity key={String(i)} style={styles.itemQuestion}>
        //     {data.results.answered[itemQuestion.id]?.correct &&
        //     userAnswer.includes(item.value) ? (
        //       <IconI name="checkmark-circle-outline" size={14} color="#36CE61" />
        //     ) : item?.is_true === 'yes' ? (
        //       <IconI name="checkmark-circle-outline" size={14} color="#36CE61" />
        //     ) : userAnswer.includes(item.value) ? (
        //       <IconI name="close-circle-sharp" size={14} color="#FF4444" />
        //     ) : (
        //       <IconI name="ellipse-outline" size={14} color="#878787" />
        //     )}
        //     <Text style={styles.txtItemQuestion}>{item.title}</Text>
        //   </TouchableOpacity>
        // );
    };

    itemOptionMultiChoice = (item, i) => {
        const { data, itemQuestion } = this.state;
        const userAnswer = data.results.answered[itemQuestion.id].answered;
        const wasAnswer = userAnswer.includes(item.value);

        if (data.results.answered[itemQuestion.id]?.correct && wasAnswer) {
            return this.renderAnswerButton(i, item.title, Colors.scholastik.greenDark, Colors.scholastik.greenDark, Colors.scholastik.green, wasAnswer, true);
        }
        if (item?.is_true === 'yes') {
            return this.renderAnswerButton(i, item.title, Colors.scholastik.greenDark, Colors.scholastik.greenDark, Colors.scholastik.white, wasAnswer, true);
        }
        if (wasAnswer) {
            return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.redDark, Colors.scholastik.red, wasAnswer, true);
        }
        return this.renderAnswerButton(i, item.title, 'black', Colors.scholastik.purple, Colors.scholastik.white, wasAnswer, true);
    };

    renderAnswerButton = (i, text, textColor, iconColor, backgroundColor, wasAnswer, isMultiChoice) => {

        return text.includes('oscio-mathjax-equation') ? (<MathJax key={String(i)} html={`
    <div style="display: flex; flex-direction: row; align-items: center; border: 2px solid ${Colors.scholastik.gray}; 
    border-radius: 4px; padding: 8px; background-color: ${backgroundColor}; color: ${textColor}; margin: 0 -8px;">
     ${isMultiChoice ? `<div><svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="23.2" height="20" viewBox="3 3 38.26 33" fill="${backgroundColor}" style="background: ${iconColor}">
    ${wasAnswer ? '<path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19zM39 4.5c1 1.2.7 2.1-1.9 5-3 3.4-3.1 4-3.1 12.2C34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1 1.6.9 2.4.7 3.6-.5 2-2 2.6-2 4.2-.1z"/><path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5-3.8 4.4c-2.1 2.4-4.3 4.2-4.8 4C18.8 26.2 10 16.4 10 15.3c0-.6.6-1.6 1.4-2.2 1.2-1 2.2-.5 5.2 2.9l3.7 4.1 4.4-4.3C31.1 9.5 30.7 9 19 9H8.9l.3 10.2z"/>' : '<path d="M0 19v19h44.1l-.3-18.7L43.5.5 21.8.2 0 0v19ZM31.2 5.1c1.6.9 3.142.42 3.11 4.315C34.27 14.422 34 13.5 34 21.7 34 33.8 33.8 34 19.2 34 3.8 34 4 34.2 4 19.3 4 4.2 4.2 4 18.6 4c5.8 0 11.5.5 12.6 1.1Z"/><path d="m9.2 19.2.3 10.3h19l.3-5.5.2-5.5s.373-8.758.19-8.695C29.023 9.862 30.7 9 19 9H8.9l.3 10.2Z"/>'}
    </svg></div>` : ''
            }
       <div style="font-size: 15px !important; line-height: 19px; margin-left: 9px; align-self: center; font-family: ;
       font-weight: 800; color: ${iconColor}">
        ${getQuestionLetter(i)})&nbsp;</div>
       <div>${text}</div>
    </div>
    `} style={{backgroundColor: 'transparent'}} font={'Poppins'} />) :
            (<TouchableOpacity
                key={String(i)}
                style={[styles.itemQuestion, {backgroundColor: backgroundColor}]}
                disabled>
                {isMultiChoice && (
                    wasAnswer ? <SvgChecked fill={backgroundColor} style={{backgroundColor: iconColor}}></SvgChecked> :
                        <SvgUnchecked fill={backgroundColor} style={{backgroundColor: iconColor}}></SvgUnchecked>
                )}
                <Text style={[styles.txtItemQuestionIcon, {color: iconColor}]}>
                    {getQuestionLetter(i)}) </Text>
                <Text style={[styles.txtItemQuestion, {color: textColor}]}>{text}</Text>
            </TouchableOpacity>);
    }

    renderFillInBlanks = (item) => {
        const lstIdKeys = [];
        const { itemQuestion, data } = this.state;
        const { ids, title_api } = item;
        ids.forEach((id) => {
            lstIdKeys.push({ id, key: `{{FIB_${id}}}` });
        });

        const userAnswer = data.results.answered[itemQuestion.id].answered;

        const words = title_api.split(' ');
        return words.map((i, k) => {
            const itemKey = lstIdKeys.find((x) => x.key === i);
            if (itemKey) {
                return (
                    <View
                        key={String(k)}
                        style={{
                            minWidth: 60,
                            paddingVertical: 2,
                            paddingHorizontal: 5,
                            backgroundColor: '#ECECEC',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        {!item.answers[itemKey.id]?.is_correct && (
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    color: '#000000',
                                }}
                            >
                                {userAnswer[itemKey.id]}
                            </Text>
                        )}
                        {!item.answers[itemKey.id]?.is_correct &&
                        item.answers[itemKey.id]?.correct && (
                            <IconF name="arrow-right" color="#666" size={14} />
                        )}
                        <Text
                            style={{
                                fontWeight: 'bold',
                                color: '#36CE61',
                            }}
                        >
                            {item.answers[itemKey.id]?.correct
                                ? `${item.answers[itemKey.id]?.correct}`
                                : ''}
                        </Text>
                    </View>
                );
            }
            return <Text key={String(k)}> {i} </Text>;
        });
    };

    renderItemSortChoice = (itemParam) => {
        const { t } = this.props;
        const { itemQuestion, data } = this.state;
        const userAnswer = data.results.answered[itemQuestion.id].answered;

        const dataSort = itemParam.options.sort((a, b) => a.sorting - b.sorting);
        return (
            <FlatList
                keyExtractor={(item) => `draggable-item-${item.value}`}
                data={userAnswer}
                renderItem={({ item, index }) => (
                    <View style={{ marginBottom: 10 }}>
                        <TouchableOpacity
                            disabled
                            style={{
                                padding: 8,
                                borderColor: '#F3F3F3',
                                borderWidth: 1,
                                borderRadius: 6,
                                alignItems: 'center',

                                // justifyContent: 'center',
                                flexDirection: 'row',
                                // marginHorizontal: 16,
                                marginBottom: 12,
                                backgroundColor: Colors.scholastik.white,
                            }}
                        >
                            <IconI
                                name="menu"
                                size={22}
                                color="#000"
                                style={{ marginRight: 10 }}
                            />
                            <Text style={styles.txtItemQuestion}>
                                {itemParam.options.find((x) => x.value === item).title}
                            </Text>
                        </TouchableOpacity>
                        <View
                            style={{
                                padding: 8,
                                borderColor: '#6CDA45',
                                borderWidth: 1,
                                borderRadius: 6,
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <Text style={styles.txtItemQuestion}>
                                {dataSort[index].title}
                            </Text>
                        </View>
                    </View>
                )}
            />
        );
    };

    render() {
        const { pageActive, data, itemQuestion, dataQuiz, activeExplanations } = this.state;
        const { t, isShowReview } = this.props;

        const userAnswer = data?.results?.answered?.[itemQuestion.id];
        if (!userAnswer) {
            console.log("Rendering review quiz without data!");
            return <></>;
        }

        const solution = {
            result: userAnswer,
            explanation: userAnswer.explanation,
            optionSolutions: itemQuestion.options,
        }

        return (
            <SafeModal
                isVisible={isShowReview}
                style={{
                    margin: 0,
                    zIndex: 10000,
                    width: '100%',
                    height: '100%',
                }}>
                <CommonAppLayout
                    color={Colors.scholastik.white}
                    style={{flex:1, position: 'relative'}}
                    forceInset={{top: 'always', bottom: 'always'}}>

                    <ContentMiddleLayout
                        style={{backgroundColor: Colors.scholastik.white}}>

                        <View style={{flex: 1, paddingTop: 25, paddingHorizontal: 22}}>
                            <View style={{flexDirection: "row", maxWidth: "100%"}}>
                                <View style={{flex: 1}}>
                                    <Text style={styles.title}>{t('learningScreen.quiz.results')}</Text>
                                    <Text style={styles.subTitle}>{dataQuiz.name}</Text>
                                </View>
                                <TouchableOpacity
                                    hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
                                    style={{ marginLeft: 20 }}
                                    onPress={() => this.props.onClose()}
                                >
                                    <IconI name="close" color="#000" size={28} />
                                </TouchableOpacity>
                            </View>
                            <View style={{ flex: 1 }}>
                                <ScrollView contentContainerStyle={{
                                    //58 - Explanation max height - add this padding to allow scroll
                                    paddingBottom: this.state.activeExplanations?.[itemQuestion?.id] ? (deviceHeight - 60) * 0.56 : 20
                                }}>
                                    {data.questions.length > 1 && (
                                        <>
                                            <View style={styles.viewPage}>
                                                <TouchableOpacity
                                                    style={styles.btnPage}
                                                    onPress={this.onPrevQuiz}
                                                >
                                                    <IconI name="chevron-back-outline" />
                                                </TouchableOpacity>

                                                <FlatList
                                                    ref={(ref) => {
                                                        this.flatListRef = ref;
                                                    }}
                                                    data={dataQuiz.questions}
                                                    horizontal
                                                    style={styles.flatPage}
                                                    contentContainerStyle={styles.flatPageContainer}
                                                    showsHorizontalScrollIndicator={false}
                                                    keyExtractor={(item, index) => String(index)}
                                                    renderItem={({ item, index }) =>
                                                        this.renderHeaderItem({ item, index })
                                                    }
                                                />
                                                <TouchableOpacity
                                                    style={styles.btnPage}
                                                    onPress={this.onNextQuiz}
                                                >
                                                    <IconI name="chevron-forward-outline" />
                                                </TouchableOpacity>
                                            </View>
                                        </>
                                    )}
                                    {itemQuestion && (
                                        <View style={styles.viewQuestion}>
                                            {/*{renderHtml(itemQuestion?.title, {dispatch: this.props.dispatch})}*/}
                                            {renderHtml(itemQuestion?.content, {dispatch: this.props.dispatch})}

                                            {(itemQuestion.type === 'true_or_false' ||
                                                    itemQuestion.type === 'single_choice') &&
                                                itemQuestion.options.map((item, i) =>
                                                    this.itemOption(item, i)
                                                )}

                                            {itemQuestion.type === 'multi_choice' &&
                                                itemQuestion.options.map((item, i) =>
                                                    this.itemOptionMultiChoice(item, i)
                                                )}
                                            {itemQuestion.type === 'fill_in_blanks' && (
                                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                                    {itemQuestion.options.map((item, i) =>
                                                        this.renderFillInBlanks(item, i)
                                                    )}
                                                </View>
                                            )}
                                        </View>
                                    )}
                                    <View style={{height: 150}}/>
                                </ScrollView>
                            </View>
                        </View>

                        <Explanation
                            dispatch={this.props.dispatch}
                            maxHeightPercent={58}
                            question={itemQuestion}
                            solution={solution}
                            active={activeExplanations[itemQuestion.id]}
                            onToggle={this.toggleExplanation.bind(this)}
                            openedContainerStyle={{paddingBottom: 120}}
                            // styles={{height: 120}}
                        />

                        <View style={styles.bottomBar}>
                            <View
                                style={{
                                    flexDirection: "row",
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}>
                                <TouchableOpacity
                                    style={[styles.buttonAnswer, {
                                        backgroundColor: Colors.scholastik.white,
                                        flex: 1
                                    }]}
                                    onPressIn={this.onPrevQuiz}>
                                    <Text style={{
                                        color: Colors.scholastik.purple,
                                        fontWeight: 'bold'
                                    }}>
                                        {t('learningScreen.quiz.prevQuestionShort')}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={[styles.buttonAnswer, {
                                        backgroundColor: Colors.scholastik.white,
                                        flex: 1
                                    }]}
                                    onPressIn={this.onNextQuiz}>
                                    <Text style={{
                                        color: Colors.scholastik.purple,
                                        fontWeight: 'bold'
                                    }}>
                                        {t('learningScreen.quiz.nextQuestionShort')}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                        </View>
                    </ContentMiddleLayout>
                </CommonAppLayout>
            </SafeModal>
        );
    }
}

export default withTranslation()(ReviewQuiz);
