import {StyleSheet, Dimensions, Platform} from 'react-native';
import {Colors} from 'app-assets';

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;
const isWeb = Platform.OS === "web";

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
  },
  containerAll: {},
  imgBottom: {
    width: deviceWidth,
    height: (380 / 1500) * deviceWidth,
    resizeMode: 'contain',
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textBottom: {
    marginTop: 40,
    fontSize: 14,
    color: Colors.scholastik.white,
    fontFamily: 'Poppins',
    fontWeight: '400',
  },
  logo: {
    width: '100%',
    height: '100%',
    minWidth: 80,
    minHeight: 80,
    marginVertical: 20,
    resizeMode: 'contain',
    maxWidth: 140,
    maxHeight: 140
  },
  viewLogo: {
    alignSelf: 'center',
    zIndex: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    marginBottom: 25
  },
  containerImg: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    fontSize: 15,
    color: Colors.scholastik.textPrimary,
  },
  viewInput: {
    flex: 1,
    color: Colors.scholastik.textPrimary,
    backgroundColor: '#F3F3F3',
    borderRadius: 4,
    marginBottom: 16,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',

    borderWidth: 2,
    borderColor: '#F3F3F3',
  },
  textInput: {
    flex: 1,
    height: 45,
    color: Colors.scholastik.textPrimary,
    fontFamily: 'Poppins',
    fontSize: 14,
    outlineStyle: isWeb ? 'none' : undefined,
  },
  button: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
    top: deviceHeight / 2 - 20,
  },
  nextButton: {
    height: (264 / 375) * deviceWidth,
    width: (264 / 375) * deviceWidth,
    resizeMode: 'contain',
  },
  iconBack: {
    height: 22,
    width: 22,
    resizeMode: 'contain',
    tintColor: Colors.scholastik.textTertiary
  },
  txtAccept: {
    fontFamily: 'Poppins',
    fontSize: 11,
    color: Colors.scholastik.textPrimary,
    fontWeight: '400',
    lineHeight: 16,
  },
  iconCheck: {
    fontSize: 22,
    color: '#9E9E9E',
    marginRight: 12,
  },
  btnSubmit: {
    marginTop: 26,
    flex: 1,
    height: 50,
    backgroundColor: Colors.scholastik.green,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  txtSubmit: {
    fontFamily: 'Poppins',
    fontSize: 18,
    color: Colors.scholastik.textPrimary,
    fontWeight: '400',
  },
  line: {
    width: 90,
    height: 1,
    backgroundColor: '#DBDBDB',
  },
  iconFacebook: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  iconTwitter: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  iconGoogle: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
  icEnter: {
    width: 17,
    height: 17,
    resizeMode: 'contain',
  },
});
