import {VALUES} from "../../setup/config";
import {cacheProperty, eraseCache} from "../actions/cached";
import { readPropOrThrow, readCacheTypeOrThrow } from "../common";
import Types from "../actions/types";

const Cache = {

    /**
     * Cache value in the system for a certain period of time
     * @param key key to cache
     * @param onExpired callback to execute when cache is outdated, to retrieve e.g. using fetch
     * @param props
     * @param reducerName
     * @param defaultValue default value to return in case of failure
     * @param forPeriod how long to cache
     * @param reloadIf ignore the caching and reload immediately
     */
    async getAsync(key, onExpired, props, reducerName, defaultValue=undefined,
                   forPeriod=VALUES.EXPIRY_TSTAMP.WEEK, reloadIf=(cache, defVal)=>false) {
        const stateContext = readPropOrThrow(props, reducerName, 'Cache::getAsync', 'object');
        const dispatch = readPropOrThrow(props, 'dispatch', 'Cache::getAsync', 'function');

        let cachedValue = stateContext[key],
            cachedMeta = stateContext[`_mt__${key}`];

        // noinspection EqualityComparisonWithCoercionJS
        if (reloadIf(cachedValue, defaultValue) || cachedValue === undefined || typeof cachedMeta !== "object" ||
            (Date.now() - cachedMeta.tStamp > cachedMeta.duration)
        ) {
            const cacheType = readCacheTypeOrThrow(reducerName, 'Cache::getAsync');
            try {
                cachedValue = await onExpired();
            } catch (e) {
                console.error(e);
                cachedValue = defaultValue;
            }
            dispatch(cacheProperty(cachedValue, key, forPeriod, cacheType));
        }
        return cachedValue;
    },

    /**
     * Cache value in the system for a certain period of time
     * VARN: boolean might get overwritten to 1/0
     *
     * @param key key to cache
     * @param newValueIfExpired value to cache and return in case the cached value is missing or expired
     * @param props
     * @param reducerName
     * @param forPeriod how long to cache
     * @param reloadIf ignore the caching, cache and return newValueIfExpired
     */
    get(key, newValueIfExpired, props, reducerName,
        forPeriod=VALUES.EXPIRY_TSTAMP.WEEK, reloadIf=(cache, defVal)=>false) {
        const stateContext = readPropOrThrow(props, reducerName, 'Cache::get', 'object');
        const dispatch = readPropOrThrow(props, 'dispatch', 'Cache::get', 'function');

        let cachedValue = stateContext[key],
            cachedMeta = stateContext[`_mt__${key}`];

        if (reloadIf(cachedValue, newValueIfExpired) || cachedValue === undefined || typeof cachedMeta !== "object" ||
            (cachedMeta && (Date.now() - cachedMeta.tStamp) > cachedMeta.duration)
        ) {
            const cacheType = readCacheTypeOrThrow(reducerName, 'Cache::get');
            cachedValue = newValueIfExpired;
            dispatch(cacheProperty(cachedValue, key, forPeriod, cacheType));
        }
        return cachedValue;
    },

    /**
     * Cache constant value in the system for a certain period of time
     * - in case the value is known and simply can be set, not retrieved
     * @param key key to cache
     * @param value value to cache
     * @param props
     * @param reducerName
     * @param forPeriod
     */
    set(key, value, props, reducerName, forPeriod=VALUES.EXPIRY_TSTAMP.WEEK) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Cache::set', 'function');
        const cacheType = readCacheTypeOrThrow(reducerName, 'Cache::set');
        dispatch(cacheProperty(value, key, forPeriod, cacheType));
    },

    /**
     * Only attempts to read if exists,
     * allows for property reading, e.g. if property is set, successful read returns the property,
     * unsuccessful default value
     * @param key key to read
     * @param defaultValue default value if not available
     * @param props
     * @param reducerName
     * @param property property to return if cache exists (i.e. cachedValue[property])
     */
    read(defaultValue, props, reducerName, key, property=undefined) {
        const stateContext = readPropOrThrow(props, reducerName, 'Cache::read', 'object');

        let cachedValue = stateContext[key],
            cachedMeta = stateContext[`_mt__${key}`];

        if (cachedValue === undefined ||
                typeof cachedMeta !== "object" ||
                (cachedMeta && Date.now() - cachedMeta.tStamp > cachedMeta.duration)) {
            return defaultValue;
        }
        if (typeof property === "string" && cachedValue && property in cachedValue) {
            return cachedValue[property];
        }
        return cachedValue;
    },

    /**
     * Type-safe reading alternative to Cache.read - defaultValue type must equal cached value type,
     * otherwise default value is returned
     * @param key key to read
     * @param defaultValue default value if not available
     * @param props
     * @param reducerName
     * @param property property to return if cache exists (i.e. cachedValue[property])
     */
    readTypeSafe(defaultValue, props, reducerName, key, property=undefined) {
        const result = Cache.read(defaultValue, props, reducerName, key, property);
        if (typeof result !== typeof defaultValue) return defaultValue;
        return result;
    },

    /**
     * Erase the whole app cache
     * @param props react properties with dispatcher or object with dispatcher
     * @returns {Promise<void>}
     */
    async eraseAll(props) {
        const dispatch = readPropOrThrow(props, 'dispatch', 'Cache::set', 'function');
        console.debug("Erasing state cache");
        for (let reducerName in Types.CacheName) {
            try {
                await dispatch(eraseCache(Types.CacheName[reducerName]));
            } catch (e) {
                console.error("Failed to erase cache ", reducerName, e);
            }
        }
    },
};

export default Cache;