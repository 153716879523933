import Types from '../actions/types';

const INITIAL_STATE = {};
const category = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SAVE_CATEGORY:
      delete action.type;
      return {
        ...state,
        data: action.data,
      };
    case Types.SELECT_DEFAULT_CATEGORY:
      delete action.type;
      return {
        ...state,
        activeCategorySlug: action.slug,
      };
    default:
      return state;
  }
};

export default category;
