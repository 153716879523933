/* eslint-disable import/prefer-default-export */
import Types from './types';

export function setVersion(version: string) {
  return {
    type: Types.APP_VERSION,
    version: version,
  };
}


