
class BackgroundTimer {
    constructor() {
        this.uniqueId = 0;
        this.callbacks = {};

        this.staticTimer = null;
    }

    start(delay = 0) {
        if (!this.staticTimer) {
            this.staticTimer = this.setInterval();
        }
    }

    stop() {
        if (this.staticTimer) {
            this.clearInterval(this.staticTimer);
        }
    }

    runBackgroundTimer(callback, delay) {
        throw "Not implemented!";
    }

    backgroundClockMethod(callback, delay) {
        throw "Not implemented!";
    }

    stopBackgroundTimer() {
        throw "Not implemented!";
    }

    setTimeout(callback, timeout) {
        this.uniqueId += 1;
        const timeoutId = this.uniqueId;
        this.callbacks[timeoutId] = setTimeout(callback, timeout);
        return timeout;
    }

    clearTimeout(timeoutId) {
        const id = this.callbacks[timeoutId];
        if (id) {
            clearTimeout(id);
            delete this.callbacks[timeoutId];
        }
    }

    setInterval(callback, timeout) {
        this.uniqueId += 1;
        const intervalId = this.uniqueId;
        this.callbacks[intervalId] = setInterval(callback, timeout);
        return intervalId;
    }

    clearInterval(intervalId) {
        const id = this.callbacks[intervalId];
        if (id) {
            clearInterval(id);
            delete this.callbacks[intervalId];
        }
    }
}

export default new BackgroundTimer();