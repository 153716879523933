import React, { Component } from 'react';
import { StatusBar } from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './common/translations';
import RootScreen from './screens/root';
import { configStore } from './store/index';
import { setToken } from './api';
import { saveStatusNetwork } from './actions/network';
import { VALUES } from '../setup/config';
import Toast, {ErrorToast, InfoToast, SuccessToast} from 'react-native-toast-message';
import {FileLogger} from "./proxy";

import DeviceInfo from "react-native-device-info";
import {
  asyncCheckConnectionBind,
  Routines
} from "./common";

import {setVersion} from "./actions/app";
import LogRocket from '@logrocket/react-native';

FileLogger.configure();

if (VALUES.LOGROCKET_ID) {
  LogRocket.init(VALUES.LOGROCKET_ID);
}


const toastConfig = {

  success: (props) => (
      <SuccessToast
          {...props}
          text2NumberOfLines={3}

          //Usage:
          // text1Style={{
          //   fontSize: 15
          // }}
          // style={{paddingVertical: 10}}
          // text2Style={{
          //   fontSize: 13
          // }}
      />
  ),

  info: (props) => (
      <InfoToast
          {...props}
          text2NumberOfLines={3}
      />
  ),

  error: (props) => (
      <ErrorToast
          {...props}
          text2NumberOfLines={3}
      />
  ),
};


const retryFailedRequests = (context) => {
  try {
    Routines.retryFailedRequestsFromCache(context);
  } catch (e) {
    console.error("Failure during under-hood request re-sending.", e);
  }
}

let store, persistor;
export { store };
class MyApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      icReachable: true,
    }

    if (this.constructor.__onBeforeStart) {
      this.constructor.__onBeforeStart();
      delete this.constructor.__onBeforeStart;
    }

    this.__lock = Date.now();

    let storeConfig = configStore();
    store = storeConfig.store;
    persistor = storeConfig.persistor;
  }

  static onBeforeStart(clbck) {
    this.__onBeforeStart = clbck;
  }

  static onStart(clbck) {
    this.__onStart = clbck;
  }

  async componentDidMount() {
    if (this.constructor.__onStart) {
      this.constructor.__onStart();
      delete this.constructor.__onStart;
    }

    // SplashScreen.show();

    //codepush not used for now
    // try {
    //   const deploymentKey =
    //     Platform.OS === 'ios' ? VALUES.CODE_PUSH.ios : VALUES.CODE_PUSH.android;
    //   await codePush.sync({
    //     deploymentKey,
    //     installMode: codePush.InstallMode.IMMEDIATE,
    //   });
    // } catch (e) {
    //   console.log(e);
    // }

    try {
      const currentVersion = DeviceInfo.getVersion();
      const state = store.getState();

      if (VALUES.ERASE_CACHE_ON_UPDATE) {
        const cachedVersion = state.app?.version;
        if (typeof  currentVersion === "string"
            && typeof cachedVersion === "string"
            && currentVersion !== cachedVersion) {
          console.debug('Cache: version mismatch. Will erase.', cachedVersion, currentVersion);
          await Cache.eraseAll({dispatch: store.dispatch});
        }
      }

      store.dispatch(setVersion(currentVersion));

    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Called from checkConnectionBind(er) before state changes
   * @param isConnected
   */
  connectionChanged(isConnected) {
    if (this.state.icReachable && !isConnected) {

      console.debug("Disconnected");
      Toast.show({
        type: 'info',
        text1: 'Chybí připojení k internetu',
        text2: 'Bez internetu se nemusí synchronizovat ani ukládat tvůj postup!',
        visibilityTime: 7000,
      });
      store.dispatch(saveStatusNetwork(isConnected));
    } else if (!this.state.icReachable && isConnected) {
      if (Date.now() - this.__lock > 5000) {
        this.__lock = Date.now();
        retryFailedRequests({
          dispatch: store.dispatch, failed: store.getState().failed
        });
      }

      store.dispatch(saveStatusNetwork(isConnected));
    }
  }

  // if (__DEV__) {
  //   import("../setup/config/ReactotronConfig").then(() => console.log("Reactotron Configured"));
  // }

  onBeforeLift = async () => {
    this.__lock = Date.now();
    asyncCheckConnectionBind(this);

    const { user } = store.getState();

    if (user?.token) {
      setToken(user?.token);
      const id = user.info?.id || 0;
      LogRocket.identify(__DEV__ ? -1 : id);

      //ensure we catch the caps at start so we are up to date, also triggers login if expired
      //crucial for subscriptions, where user might buy something while on expired login
      await Routines.getUserCapabilities({ user, dispatch: store.dispatch }, true, id);

    } else if (__DEV__) {
      LogRocket.identify(-1);
    }
  };

  render() {
    return (
        <Provider store={store}>
          <PersistGate
            onBeforeLift={this.onBeforeLift}
            loading={null}
            persistor={persistor}
          >
            <StatusBar
              translucent
              backgroundColor="rgba(255,255,255,0.1)"
              barStyle="dark-content"
            />
            {/*{*/}
            {/*  iOS ? <SafeAreaProvider><RootScreen /></SafeAreaProvider> : <RootScreen />*/}
            {/*}*/}
            <RootScreen />
            {/*{!icReachable && (*/}
            {/*    <View style={[styles.overlay, {*/}
            {/*      width: Dimensions.get('window').width,*/}
            {/*      height: Dimensions.get('window').height + (Platform.OS !== 'ios' ? getStatusBarHeight() : 0)}]}>*/}
            {/*      <IconM name="wifi-off" color="black" size={70} style={{alignSelf: 'center', margin: 30}}></IconM>*/}
            {/*      <Text style={styles.netTitle}>Nemáš připojení k internetu.</Text>*/}
            {/*      /!*TODO fix translation*!/*/}
            {/*    </View>*/}
            {/*)}*/}
            <Toast config={toastConfig} />
          </PersistGate>
        </Provider>
    );
  }
}
// const styles = StyleSheet.create({
//   overlay: {
//     backgroundColor: 'rgba(255,255,255,0.4)',
//     alignItems: 'center',
//     justifyContent: 'center',
//     zIndex: 0,
//     ...StyleSheet.absoluteFillObject,
//   },
//   netTitle: {
//     color: 'black',
//     fontSize: 20,
//     maxWidth: '70%', alignSelf: 'center',
//     textAlign: "center",
//     fontFamily: 'Poppins',
//     fontWeight: "bold"
//   }
// });

// const App = codePush(MyApp);
const App = MyApp;
export default App;
