/**
 * This reducer is not used in the root reducer, but can be used to
 * cache Type.CACHE_PROPERTY - implementation
 * @param storeObject object store
 * @param callback callback to perform on the storeObject, gets two arguments: value and meta keys
 * @param emptyCallback
 */
export default function (storeObject, callback: function, emptyCallback: function) {
    let counter = 0;
    for (let key in storeObject) {
        if (!key.startsWith('_mt__')) continue;
        let storeKey = key.substring(8, key.length);
        callback(storeKey, key);
        counter++;
    }

    if (emptyCallback && counter < 1) emptyCallback();
};