import {
  ConvertToDateTime,
  ConvertToDay,
  ConvertToHour,
  formatDateNow,
  ConvertToDate,
  secondsToHms,
} from './datetime';
import { ValidateEmail, isURL, isVideo } from './validate';

import {
  ifIphoneX,
  getStatusBarHeight,
  isIphoneX,
  getBottomSpace,
} from './deviceInfo';
import { getQuestionLetter, renderHtml } from './quiz';
import { Routines } from './routines';
import { DeviceEventEmitter } from "react-native";
import {CommonActions} from "@react-navigation/native";
import {readPropOrThrow} from "../index";

const Events = {
  _nameModificator: (name) => `${name}_event_emitter`,

  on: (context, event, handler, EventContext=DeviceEventEmitter) => {
    const name = Events._nameModificator(event);
    if (typeof context[name] !== "function") {
      context[Events._nameModificator(event)] = EventContext.addListener(event, handler);
    } else {
      console.error('Already subscribed!', context, event);
    }
  },

  onCustom: (context, event, handlerWrapper) => {
    const name = Events._nameModificator(event);
    if (typeof context[name] !== "function") {
      context[Events._nameModificator(event)] = handlerWrapper();
    } else {
      console.error('Already subscribed!', context, event);
    }
  },

  off: (context, event) => {
    const name = Events._nameModificator(event);
    const listener = context[name];
    if (listener && typeof listener.remove === "function") {
      listener.remove();
      delete context[name];
    } else if (typeof  listener === "function") {
      listener();
      delete context[name];
    }
  }
}

const asyncWithTimeout = async (request, ms=30000, throws=false) => {
  // eslint-disable-next-line new-cap
  return await Promise.race([
    request,
    new Promise((resolve, reject) => setTimeout(throws ? reject : resolve, ms))
  ]);
};

export {
  // datetime
  ConvertToDateTime,
  ConvertToDay,
  ConvertToHour,
  formatDateNow,
  ConvertToDate,
  secondsToHms,
  //
  ValidateEmail,
  isURL,
  isVideo,
  ifIphoneX,
  getStatusBarHeight,
  isIphoneX,
  getBottomSpace,
  //
  getQuestionLetter,
  renderHtml,
  //
  asyncWithTimeout,
  Routines,
  Events
};
