import React, { PureComponent } from 'react';
import {
  Image,
  RefreshControl,
  ScrollView,
  Text,
  View,
  TouchableOpacity, DeviceEventEmitter, Platform, Linking,
} from 'react-native';
import { withTranslation } from 'react-i18next';
import { Colors, Images } from 'app-assets';
import { connect } from 'react-redux';
import {
  ItemCategory,
} from 'app-component';
import { HttpAPI, Cache } from 'app-api';
import styles from './styles';
import IconI from 'react-native-vector-icons/Ionicons';
import LazyLoadingNoContainer from '../../component/common/lazy-listing-no-container';
import {Fade, Placeholder, PlaceholderMedia} from "rn-placeholder";
import {Events, Routines} from "../../common/util";
import {VALUES} from "../../../setup/config";
import {asyncCheckConnectionBind, checkConnectionUnbind} from "../../common";
import ErrorBox from "../../component/common/error-text";
import {CommonAppLayout, ContentMiddleLayout} from "app-component";
import {setLoading} from "../../actions/common";
import {setSelectedCategory} from "../../actions/category";

class HomeOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      dataOverview: {},
      userCapabilities: [],
      // dataNewCourse: [],
      // topCourseWithStudent: [],
      loading1: true,
      // loading2: true,
      // loading3: true,
    };
  }

  async componentDidMount() {
    asyncCheckConnectionBind(this);

    const {user, navigation} = this.props;
    if (user && user.info?.id) {
      const capabilityData = await Routines.getUserCapabilities(this.props, false, user.info.id);
      this.setState({userCapabilities: capabilityData});
    }

    Events.onCustom(this, 'navigationFocus', () => navigation.addListener('focus', async () => {
      this.reloadUserCapabilities(false).catch(e => console.warn('Refresh caps routine failed: ', e));
    }));

    Events.on(this, 'refresh_overview', this.refreshOverview);
    await this.onGetData();
  }

  async reloadUserCapabilities(waiting=true) {
    const {user, dispatch} = this.props;
    if (user && user.token) {
      if (waiting) dispatch(setLoading(true));
      const data = await Routines.getUserCapabilities(this.props, waiting);
      if (waiting) dispatch(setLoading(false));
      this.setState({userCapabilities: data});
    }
  }

  async componentWillUnmount() {
    checkConnectionUnbind(this);
    Events.off(this, 'refresh_overview');
    Events.off(this, 'navigationFocus');
  }

  async onGetData(forceReload=false) {
    // const param = {
    //   roles: ['lp_teacher', 'administrator'],
    // };

    const { user } = this.props;

    if (user?.overview) {
      HttpAPI.getOverview(user.overview).then((response) => {
        this.setState({
          dataOverview: response?.responseData || {},
        });
      });
    }
    // HttpAPI.topCoursesWithStudent().then((response) => {
    //   this.setState({
    //     topCourseWithStudent: response?.responseData,
    //     loading2: false,
    //   });
    // });
    // HttpAPI.newCourses().then((response) => {
    //   this.setState({
    //     dataNewCourse: response?.responseData,
    //     loading3: false,
    //   });
    // });

    Routines.getCourseCategoryMeta(this.props, forceReload).then((response) => {
      this.setState({categories: response, loading1: false});
    });
  }

  categoryLoadingRenderer = (index) => {
    return (
        <View key={index} >
          <Placeholder style={{
            padding: 18,
            paddingBottom: 0,
            position: "relative"
          }} Animation={Fade}
                       Left={() => <PlaceholderMedia style={{width : "100%", borderRadius: 5, height: 180,}}/>}>
          </Placeholder>
        </View>
    );
  };

  selectCategory = async (category) => {
    if (category.slug) {
      await this.props.dispatch(setSelectedCategory(category.slug));
      Cache.set('homeShowDetails', true, this.props, 'cached', VALUES.EXPIRY_TSTAMP.NEVER);
    } else {
      //todo select OSP or the first one
      Cache.set('homeShowDetails', false, this.props, 'cached', VALUES.EXPIRY_TSTAMP.NEVER);
    }
  };

  refreshOverview = async () => {
    const { user } = this.props;

    if (user?.overview) {
      const response = await HttpAPI.getOverview(user.overview);
      this.setState({ dataOverview: response?.responseData || {} });
    }
  };

  onBack = () => {
    this.autoGoBack();
  };

  onRefresh = async () => {
    this.setState({
      refreshing: true,
      loading1: true,
      loading2: true,
      loading3: true,
    });
    await this.onGetData(true);
    this.setState({ refreshing: false });
  };

  render() {
    const {
      dataOverview,
      // topCourseWithStudent,
      // dataNewCourse,
      refreshing,
      categories,
      loading1,
      // loading2,
      // loading3
    } = this.state;

    const { t, navigation, user } = this.props;

    console.log(categories)
    const capabilities = this.state.userCapabilities,
        premiums = capabilities?.roles !== undefined ?
            Object.keys(capabilities.roles).filter(key => key.includes("premium")) : [];

    return (
        <CommonAppLayout color="white">
          <ScrollView
              refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={this.onRefresh}
                />
              }
              // style={{height: 5000}}
              showsVerticalScrollIndicator={Platform.OS === "web"}
              contentContainerStyle={{ paddingBottom: 50 }}
          >
            <ContentMiddleLayout>
              <View style={styles.header}>
                <Image source={Images.LogoSchool} style={styles.iconHome} />

                {user?.token ? (
                    <View
                        style={{
                          paddingHorizontal: 16,
                          marginTop: 16,
                        }}
                    >
                      <TouchableOpacity
                          onPress={() => this.autoNavigate('ProfileStackScreen')}
                          style={{ flexDirection: 'row' }}
                      >
                        <IconI name="person-circle-outline" size={20} color="black"/>
                        <Text style={styles.fullname} numberOfLines={1} lineBreakMode={"head"}>{user?.info?.name}</Text>
                      </TouchableOpacity>
                    </View>
                ) : (
                    <View style={styles.loginRegister}>
                      <TouchableOpacity
                          onPress={() => this.autoNavigate('LoginScreen')}
                      >
                        <Text style={styles.loginRegisterText}>{t('login')}</Text>
                      </TouchableOpacity>
                      <Text style={styles.loginRegisterIcon}>|</Text>
                      <TouchableOpacity
                          onPress={() => this.autoNavigate('RegisterScreen')}
                      >
                        <Text style={styles.loginRegisterText}>{t('register')}</Text>
                      </TouchableOpacity>
                    </View>
                )}
              </View>

              <View style={styles.viewList}>
                <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginRight: 15,
                    }}
                >
                  <Text style={styles.titleList}>{t('home.category')}</Text>
                </View>
                {
                    Platform.OS === "web" && <TouchableOpacity
                        onPress={() => Linking.openURL(VALUES.SITE_URL)}
                        style={{marginTop: 15, marginLeft: 20, zIndex: 100, right: 20, top: 0, position: "absolute"}}
                        hitSlop={{ top: 30, bottom: 30, left: 30, right: 50 }}>
                      <Text
                          style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'Poppins', paddingHorizontal: 18,
                            paddingVertical: 7, borderRadius: 7,
                            color: Colors.scholastik.textPrimary, backgroundColor: Colors.scholastik.background}}>
                        {t('homeButton')}
                      </Text>
                    </TouchableOpacity>
                }
                <View style={{
                  alignSelf: 'center',
                  width: '100%',
                  maxWidth: 520
                }}>
                  {!loading1 && (categories?.length > 0 ? (categories.map(item =>
                      <ItemCategory item={item} setCategory={this.selectCategory} key={item.id} capitalize premiumList={premiums} />)) : (
                      this.state.icReachable ? <ErrorBox body={t('errors.dataGeneral')} level={ErrorBox.Level.WARN}/> :
                          <ErrorBox title={t('errors.network.title', {domain: VALUES.DOMAIN_NAME})} body={t('errors.network.body')}/>
                  ))}
                  {loading1 && <LazyLoadingNoContainer visible={loading1} renderer={this.categoryLoadingRenderer} />}
                </View>
              </View>

              {/*todo employ in the future - active course overview{user?.token && dataOverview?.id && (*/}
              {/*    <View style={styles.overview}>*/}
              {/*      <Text style={styles.overTitle}>{t('home.overview.title')}</Text>*/}
              {/*      <View*/}
              {/*          style={{*/}
              {/*            flexDirection: 'row',*/}
              {/*            alignItems: 'center',*/}
              {/*            marginTop: 16,*/}
              {/*          }}*/}
              {/*      >*/}
              {/*        <ProgressCircle*/}
              {/*            widthX={77}*/}
              {/*            progress={*/}
              {/*                Math.round(dataOverview.course_data?.result?.result) / 100*/}
              {/*            }*/}
              {/*            strokeWidth={8}*/}
              {/*            backgroundColor="#F6F6F6"*/}
              {/*            progressColor="#958CFF"*/}
              {/*        />*/}
              {/*        <View style={{ marginLeft: 24 }}>*/}
              {/*          <View style={styles.viewItem}>*/}
              {/*            <Image*/}
              {/*                source={Images.iconLession}*/}
              {/*                style={styles.iconItem}*/}
              {/*            />*/}
              {/*            <View>*/}
              {/*              <Text style={styles.txtItem}>{t('lesson')}</Text>*/}
              {/*              <View style={styles.line}>*/}
              {/*                <View*/}
              {/*                    style={[*/}
              {/*                      styles.progress,*/}
              {/*                      {*/}
              {/*                        width: `${*/}
              {/*                            (dataOverview.course_data?.result?.items?.lesson*/}
              {/*                                    ?.completed /*/}
              {/*                                dataOverview.course_data?.result?.items*/}
              {/*                                    ?.lesson?.total) **/}
              {/*                            100*/}
              {/*                        }%`,*/}
              {/*                        backgroundColor: '#FFD336',*/}
              {/*                      },*/}
              {/*                    ]}*/}
              {/*                />*/}
              {/*              </View>*/}
              {/*            </View>*/}
              {/*          </View>*/}
              {/*          <View style={styles.viewItem}>*/}
              {/*            <Image source={Images.iconQuiz} style={styles.iconItem} />*/}
              {/*            <View>*/}
              {/*              <Text style={styles.txtItem}>{t('quiz')}</Text>*/}
              {/*              <View style={styles.line}>*/}
              {/*                <View*/}
              {/*                    style={[*/}
              {/*                      styles.progress,*/}
              {/*                      {*/}
              {/*                        width: `${*/}
              {/*                            (dataOverview.course_data?.result?.items?.quiz*/}
              {/*                                    ?.completed /*/}
              {/*                                dataOverview.course_data?.result?.items?.quiz*/}
              {/*                                    ?.total) **/}
              {/*                            100*/}
              {/*                        }%`,*/}
              {/*                        backgroundColor: '#41DBD2',*/}
              {/*                      },*/}
              {/*                    ]}*/}
              {/*                />*/}
              {/*              </View>*/}
              {/*            </View>*/}
              {/*          </View>*/}

              {/*          {dataOverview.course_data?.result?.items?.assignment?.total >*/}
              {/*              0 && (*/}
              {/*                  <View style={styles.viewItem}>*/}
              {/*                    <Image*/}
              {/*                        source={Images.iconAssignment}*/}
              {/*                        style={styles.iconItem}*/}
              {/*                    />*/}
              {/*                    <View>*/}
              {/*                      <Text style={styles.txtItem}>{t('assignment')}</Text>*/}
              {/*                      <View style={styles.line}>*/}
              {/*                        <View*/}
              {/*                            style={[*/}
              {/*                              styles.progress,*/}
              {/*                              {*/}
              {/*                                width: `${*/}
              {/*                                    (dataOverview.course_data?.result?.items*/}
              {/*                                            ?.assignment?.completed /*/}
              {/*                                        dataOverview.course_data?.result?.items*/}
              {/*                                            ?.assignment?.total) **/}
              {/*                                    100*/}
              {/*                                }%`,*/}
              {/*                                backgroundColor: '#958CFF',*/}
              {/*                              },*/}
              {/*                            ]}*/}
              {/*                        />*/}
              {/*                      </View>*/}
              {/*                    </View>*/}
              {/*                  </View>*/}
              {/*              )}*/}
              {/*        </View>*/}
              {/*      </View>*/}
              {/*      <TouchableOpacity*/}
              {/*          onPress={() =>*/}
              {/*              this.autoNavigate('CoursesDetailsScreen', {*/}
              {/*                courseId: dataOverview,*/}
              {/*              })*/}
              {/*          }*/}
              {/*          style={styles.container}*/}
              {/*      >*/}
              {/*        <Text*/}
              {/*            numberOfLines={1}*/}
              {/*            style={[styles.overTitle, { marginTop: 30 }]}*/}
              {/*        >*/}
              {/*          {dataOverview?.name}*/}
              {/*        </Text>*/}
              {/*        <Text style={styles.txt1}>*/}
              {/*          {dataOverview?.sections.length}{' '}*/}
              {/*          {dataOverview?.sections.length > 1*/}
              {/*              ? t('home.overview.sections').toUpperCase()*/}
              {/*              : t('home.overview.section').toUpperCase()}*/}
              {/*        </Text>*/}
              {/*      </TouchableOpacity>*/}
              {/*    </View>*/}
              {/*)}*/}

              {/*todo popular etc. courses commented out{!loading2 && topCourseWithStudent && topCourseWithStudent.length > 0 && (*/}
              {/*  <View style={styles.viewList}>*/}
              {/*    <Text style={styles.titleList}>{t('home.popular')}</Text>*/}
              {/*    <PopularCourses*/}
              {/*      navigation={navigation}*/}
              {/*      contentContainerStyle={{ paddingHorizontal: 16 }}*/}
              {/*      data={topCourseWithStudent}*/}
              {/*      horizontal*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*)}*/}
              {/*{loading2 && (*/}
              {/*  <View style={styles.viewList}>*/}
              {/*    <Text style={styles.titleList}>{t('home.popular')}</Text>*/}
              {/*    <LazyLoading visible={loading2} horizontal />*/}
              {/*  </View>*/}
              {/*)}*/}
              {/*{!loading3 && dataNewCourse?.length > 0 && (*/}
              {/*  <View style={styles.viewList}>*/}
              {/*    <Text style={styles.titleList}>{t('home.new')}</Text>*/}
              {/*    <PopularCourses*/}
              {/*      navigation={navigation}*/}
              {/*      contentContainerStyle={{ paddingHorizontal: 16 }}*/}
              {/*      data={dataNewCourse}*/}
              {/*      horizontal*/}
              {/*    />*/}
              {/*  </View>*/}
              {/*)}*/}
              {/*{loading3 && (*/}
              {/*  <View style={styles.viewList}>*/}
              {/*    <Text style={styles.titleList}>{t('home.new')}</Text>*/}
              {/*    <LazyLoading visible={loading3} horizontal />*/}
              {/*  </View>*/}
              {/*)}*/}
              {/* <View style={styles.viewList}>
            <Text style={styles.titleList}>Upcoming Courses</Text>
            <UpcomingCourses
              navigation={navigation}
              contentContainerStyle={{ paddingHorizontal: 16 }}
              data={dataUpcomimg}
              horizontal
            />
          </View> */}
            </ContentMiddleLayout>
          </ScrollView>
        </CommonAppLayout>
    );
  }
}
const mapStateToProps = ({ user, cached, category }) => ({
  user, cached, category
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(HomeOverview));
