import {createStackNavigator} from "@react-navigation/stack";
import React from "react";
import Subscription from "../../../screens/subscribe";
import CoursesDetails from "../../../screens/courses-details";
import LearningRouter from "./learning/learning-router";

const CourseStack = createStackNavigator();
export default function CourseRouter() {
    return (
        <CourseStack.Navigator
            initialRouteName="CoursesDetailsScreen"
            screenOptions={{
                cardStyle: {flex: 1},
                gesturesEnabled: false,
                headerShown: false,
                header: null,
            }}>
            <CourseStack.Screen name="Subscription" component={Subscription}
                                options={{ headerShown: false, title: 'Prémiový účet' }}/>
            <CourseStack.Screen name="CoursesDetailsScreen" component={CoursesDetails}
                                options={{ headerShown: false, title: 'Detaily kurzu' }}/>
            <CourseStack.Screen
                name="LearningStackScreen"
                component={LearningRouter}
                options={{ headerShown: false, title: 'Test' }}
            />
        </CourseStack.Navigator>
    );
}