import * as React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Colors} from "app-assets";

class IconButton extends React.Component {

    render() {
        const {
            title, body, icon, IconComponent, titleStyle, bodyStyle, containerStyle,
            onPress, onPressIn, onPressOut, disabled
        } = this.props,
            iconStyle = this.props.iconStyle || {},
            handler = onPress || onPressIn || onPressOut;

        if (IconComponent && !icon) {
            throw "Invalid icon setup: specify also icon property for icon rendering.";
        }

        return (
            <TouchableOpacity
                onPress={handler}
                disabled={disabled}
                style={[styles.container, containerStyle]}
            >
                {typeof icon === "string" && IconComponent &&
                    <View style={[styles.iconContainer, iconStyle]}>
                        <IconComponent name={icon}
                                       size={iconStyle.fontSize || iconStyle.size || 25}
                                       color={iconStyle.color || Colors.scholastik.darkGray}/>
                    </View>}

                <View style={{flexDirection: 'column'}}>
                    {typeof title === "string" && title &&
                        <Text style={[styles.txt1, titleStyle]}>{title}</Text>}
                    {typeof body === "string" && body &&
                        <Text style={[styles.txt2, bodyStyle]}>{body}</Text>}
                </View>
            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 15,
    },
    iconContainer: {
        width: 50,
        height: 50,
        borderRadius: 12,
        backgroundColor: '#4c4c4c',
        alignItems: 'center',
        justifyContent: 'center',
    },
    txt1: {
        fontFamily: 'Poppins-Medium',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 24,
        marginLeft: 20,
        color: Colors.scholastik.textPrimary
    },
    txt2: {
        fontFamily: 'Poppins-Medium',
        fontSize: 8,
        lineHeight: 9,
        marginLeft: 20,
        maxWidth: '75%',
        color: Colors.scholastik.textSecondary
    }
});

export default IconButton;
