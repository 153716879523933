/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, {Component} from 'react';
import {
    Text,
    View,
    Image,
    TouchableOpacity,
    DeviceEventEmitter,
    RefreshControl,
    Platform,
} from 'react-native';
import {Alert, BackHandler} from "proxy";
import {withTranslation} from 'react-i18next';
import {HttpAPI, Cache} from 'app-api';
import {Images} from 'app-assets';
import IconI from 'react-native-vector-icons/Ionicons';
import IconF from 'react-native-vector-icons/Feather';
import {connect} from 'react-redux';
import {RenderDataHTML, Web} from 'app-component';
import {KeyboardAwareScrollView} from 'proxy';
import {VALUES} from 'app-config';
import styles from './styles';
import {setLoading, showLoading} from '../../actions/common';
import {saveCourse} from '../../actions/course';
import {setOverview} from '../../actions/user';
import CourseSectionType from "./course-section-type";
import {Routines} from "../../common";
import {Events} from "../../common/util";
import Toast from "react-native-toast-message";
import InternetConnectionErrorView from "../../component/common/connection-error-view";
import {Colors} from 'app-assets';
// import {renderFakeTabBar} from "../../navigations/home-tabbar-common";
import {CommonAppLayout, ContentMiddleLayout, TallHeader} from "../../component";
import {renderFakeTabBar} from "../../navigations/home-tabbar-common";

class CoursesDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSections: [0],
            // review: null,
            // reviewMessage: '',
            // isWishlist: false,

            titleRating: '',
            contentRating: '',
            starRating: 5,
            hiddenBottom: false,
            selectedItemId: 0,
            hasPremium: undefined,
            offers: undefined,
        };
        this.lastRefresh = 0;
    }

    async componentDidMount() {
        const {navigation} = this.props;

        BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        Events.on(this, 'loadCourseDetail', this.refreshData);
        Events.on(this, 'keyboardDidShow', this._keyboardDidShow);
        Events.on(this, 'keyboardDidHide', this._keyboardDidHide);
        const self = this;
        Events.onCustom(this, 'navigationFocus', () => navigation.addListener('focus', () => {
            self.init();
        }));
    }

    async init() {
        const {t, dispatch, route, network, course} = this.props,
            _this = this;

        if (course?.id && Date.now() - this.lastRefresh > 2000) {
            // If at least two seconds elapsed and we refocused screen, fetch data without blocking UI
            dispatch(setLoading(false));
            await this.getData(course.data.id, false);
            return;
        }

        dispatch(showLoading(true));
        let hasPremium,
            courseId = route.params?.courseId;

        if (!courseId) {
            dispatch(setLoading(false));
            this.autoGoBack();
            return;
        }

        let courseData = await this.getData(courseId, false);
        if (!courseData?.id) {
            //TODO might not be valid, but if missing ID we assume it did no laod succesfully and thus not logged in...
            this.autoNavigate('LoginScreen',  { message: this.props.t('errors.notLoggedIn.doLogin')});
            return;
        }

        await Routines.performNecessaryUserChecksBeforeCourseStart(this.props, courseData,
            async (sub) => {
                //no-op
            }, (sub, premiums) => {
                _this.autoReplace('Subscription', {
                    requiredPremiums: premiums,
                    courseInfo: courseData
                });
            }, (sub, premiums) => {
                Alert.alert(
                    t('errors.subscription.title'),
                    t('errors.subscription.body')
                    + courseData.categories.map(c => c.name?.toUpperCase()).join(", ")
                    + (Platform.OS === 'android' ? '. ' + t('errors.subscription.additional') : ''),
                );
                _this.autoReplace('Subscription', {
                    requiredPremiums: premiums,
                    courseInfo: courseData
                });
            }
        ).then(premium => {
            hasPremium = premium;
            dispatch(showLoading(false));
        }).catch(e => {
            console.error('Course Details WillMountERR:', e);
            Alert.alert(
                t('errors.titleGeneral'),
                network.connection ? t('errors.network.content') : t('errors.dataGeneral')
            );
            dispatch(showLoading(false));
            this.autoGoBack();
        });

        this.setState({hasPremium: hasPremium});
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(saveCourse(null));
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
        Events.off(this, 'loadCourseDetail');
        Events.off(this, 'keyboardDidShow');
        Events.off(this, 'keyboardDidHide');
        Events.off(this, 'navigationFocus');
    }

    _keyboardDidShow = () => {
        this.setState({
            hiddenBottom: true,
        });
    };

    _keyboardDidHide = () => {
        this.setState({
            hiddenBottom: false,
        });
    };

    refreshData = async () => {
        const {course} = this.props;

        this.setState({
            refreshing: true,
        });
        await this._fetchData(course.data.id);
        // this.sectionTypeRendererRef?.current?.parentRefreshed();
        this.setState({refreshing: false});
    };

    async getData(courseId, withLoading = true) {
        const {dispatch} = this.props;
        if (withLoading) await dispatch(showLoading(true));
        const result = await this._fetchData(courseId);
        dispatch(setOverview(courseId));
        if (withLoading) await dispatch(showLoading(false));
        return result;
    }

    async _fetchData(courseId) {
        const {dispatch} = this.props;
        const response = await HttpAPI.courseDetail(courseId).then(r => r?.responseData || {});
        dispatch(saveCourse(response));
        this.lastRefresh = Date.now();
        return response;
    }

    handleBackPress = () => {
        DeviceEventEmitter.emit('refresh_overview');
        this.autoGoBack();
        return true;
    };

    goBack = () => {
        DeviceEventEmitter.emit('refresh_overview');
        this.autoGoBack();
    };

    onNavigateLearning = (item, index, forceStart=false) => {
        const {course} = this.props;
        const params = {
            courseId: course.data.id,
            sectionType: item.type,
            sectionId: item.id,
            questionIndex: "",
            start: forceStart
        };
        if (forceStart) {
            params.start = true;
        }
        this.autoNavigate('LearningScreen', params);
    };

    // renderItemFilter = ({ item }) => {
    //   return (
    //     <TouchableOpacity
    //       style={{
    //         height: 30,
    //         paddingHorizontal: 19,
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         backgroundColor: Colors.scholastik.white,
    //         borderRadius: 60,
    //         borderWidth: 1,
    //         borderColor: '#EBEBEB',
    //         marginRight: 10,
    //       }}
    //     >
    //       <Text style={styles.txtItemFilter}>{item.Name}</Text>
    //     </TouchableOpacity>
    //   );
    // };

    // renderItemRating = () => {
    //   const { review } = this.state;
    //   if (!review) return null;
    //   return review?.reviews.reviews.map((item, i) => (
    //     <View key={String(i)}>
    //       <View
    //         style={{
    //           flexDirection: 'row',
    //           alignItems: 'center',
    //           marginTop: 14,
    //         }}
    //       >
    //         {/* <Image
    //           style={styles.imgAvatar}
    //           source={{
    //             uri: 'https://static.remove.bg/remove-bg-web/71dbdf11b48cb655eefe2f609ad67295258ae141/assets/start-0e837dcc57769db2306d8d659f53555feb500b3c5d456879b9c843d1872e7baa.jpg',
    //           }}
    //         /> */}
    //         <View style={{ alignItems: 'flex-start' }}>
    //           <Text style={[styles.txtTitle, { marginTop: 0 }]}>
    //             {item.display_name}
    //           </Text>
    //           <Rating
    //             ratingCount={5}
    //             imageSize={12}
    //             readonly
    //             ratingColor="#FBC815"
    //             startingValue={Number(item.rate)}
    //           />
    //         </View>
    //       </View>
    //       <Text style={styles.titleRating}> {item.title}</Text>
    //       <Text style={[styles.txtOverview, { marginTop: 5 }]}>
    //         {item.content}
    //       </Text>
    //     </View>
    //   ));
    // };

    renderHeaderSession = (section, index, isActive) => {
        return (
            <View key={String(index)}>
                <View
                    style={[styles.subSectionTitle, {marginTop: 8, marginBottom: 11}]}
                >
                    <View style={styles.subSectionTitle}>
                        <IconI name={isActive ? 'caret-up' : 'caret-down'} size={15}/>
                        <Text numberOfLines={1} style={styles.txtSubSection}>
                            {section.title}
                        </Text>
                    </View>
                    <Text style={styles.txtLength}>{section.items.length}</Text>
                </View>
            </View>
        );
    };

    renderContent = (section, index) => {
        const {course, user} = this.props;
        const data = course?.data;
        const {items} = section;
        return (
            <View>
                {items.map((item, i) => (
                    <TouchableOpacity
                        key={String(i)}
                        onPress={() => this.onNavigateLearning(item, index)}
                        style={[
                            styles.subSectionTitle,
                            {marginBottom: 5, marginLeft: 24},
                        ]}
                        disabled={
                            !user?.token ||
                            (data?.course_data.status !== 'enrolled' &&
                                data?.course_data.status !== 'finished' &&
                                !item.preview)
                        }
                    >
                        <View style={styles.subSectionTitle}>
                            {item.type === 'lp_lesson' && (
                                <IconF name="book" color="#4E4E4E" size={14}/>
                            )}
                            {item.type === 'lp_quiz' && (
                                <IconF name="help-circle" color="#4E4E4E" size={14}/>
                            )}
                            {item.type === 'lp_assignment' && (
                                <IconF name="file" color="#4E4E4E" size={14}/>
                            )}
                            <Text numberOfLines={1} style={styles.txtItemLession}>
                                {item.title}
                            </Text>
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            {item.preview && item.status !== 'completed' && (
                                <IconI name="eye-outline" style={styles.iconPreview}/>
                            )}
                            {item.locked && (
                                <IconI name="lock-closed" color="#4E4E4E" size={16}/>
                            )}
                            {item.duration !== '' && (
                                <Text style={styles.totalHours}>{item.duration}</Text>
                            )}
                            {item.status === 'completed' && (
                                <IconI name="checkmark-circle-outline" style={styles.iconPreview}/>
                            )}
                        </View>
                    </TouchableOpacity>
                ))}
            </View>
        );
    };

    onNavigateAdvanced = async (section, desiredType) => {
        let itemRedirect = section.items.find((item) => item.status !== 'completed' && item.type === desiredType)
            || section.items.find((item) => item.type === desiredType);
        //todo find first unanswered quiz and move to that index
        if (!itemRedirect) {
            //todo
            console.error("Not handled: missing item redirect in advanced navigation!");
        }
        this.onNavigateLearning(itemRedirect || section.items.find((item) => item.type === 'lp_quiz'), 0);
    };

    onStart = async () => {
        const {course} = this.props;
        const data = course?.data;

        if (data.sections.length > 0 && data.sections[0].items.length > 0) {
            let itemRedirect = null;

            data.sections.forEach((section) => {
                if (!itemRedirect) {
                    itemRedirect = section.items.find(
                        (item) => item.status !== 'completed'
                    );
                }
            });

            this.onNavigateLearning(itemRedirect || data.sections[0].items[0], 0);
        }
    };

    onEnroll = async () => {
        const {dispatch, user, course} = this.props;

        if (!user?.token) {
            return this.notLoggedIn();
        }

        dispatch(showLoading());
        const param = {
            id: course.data.id,
        };

        try {
            // this.setState({ refreshing: true });
            const response = await HttpAPI.enroll(param).then(r => r?.responseData);
            dispatch(showLoading(false));
            if (response.status === 'success') {
                DeviceEventEmitter.emit('loadMyCourse');
                await this.getData(course.data.id);
            } else {
                Alert.alert(response.message);
            }
        } finally {
            this.setState({refreshing: false});
        }
    };

    onRetake = async () => {
        const {t, dispatch, course} = this.props;
        dispatch(showLoading());
        const param = {
            id: course.data.id,
        };

        const response = await HttpAPI.retakeCourse(param).then(r => r?.responseData);
        dispatch(showLoading(false));
        if (response.status === 'success') {
            Alert.alert(t('singleCourse.retakeNotice'));
            await this.getData(course.data.id);
            DeviceEventEmitter.emit('loadMyCourse');
        } else {
            Alert.alert(response.message);
        }
    };

    // onToggleWishlish = async () => {
    //   const { dispatch, course, user } = this.props;
    //
    //   if (!user?.token) {
    //     return this.notLoggedIn();
    //   }
    //
    //   const { isWishlist } = this.state;
    //   const data = course?.data;
    //   const param = {
    //     id: data.id,
    //   };
    //   dispatch(showLoading());
    //   const response = await HttpAPI.addRemoveWishlist(param).then(r => r?.responseData);
    //   dispatch(showLoading(false));
    //   if (response.status === 'success') {
    //     this.setState({ isWishlist: !isWishlist });
    //     dispatch(saveDataWishlist(response?.data?.items || []));
    //   } else {
    //     Alert.alert(response.message);
    //   }
    // };

    notLoggedIn = () => {
        const {t} = this.props, _this = this;
        return Alert.alert(t('alert.notLoggedIn'), t('alert.loggedIn'), [
            {
                text: t('alert.cancel'),
                onPress: () => {
                },
                style: 'cancel',
            },
            {
                text: t('alert.btnLogin'),
                onPress: () => _this.redirectLogin()
            },
        ]);
    };

    redirectLogin() {
        this.autoNavigate('LoginScreen', {
            screen: 'CoursesDetailsScreen',
            courseId: this.props.course.data.id
        });
    };

    renderBottomBarAdditionalInfo(data, isFinished, canRetake) {
        const {t} = this.props;

        return <>
            {isFinished && data?.course_data?.graduation && (
                <View
                    style={{
                        marginLeft: 10,
                        flex: 1,
                        paddingVertical: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        flexDirection: 'row',
                    }}
                >
                    <Text
                        style={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: 14,
                            lineHeight: 21,
                            color:
                                data?.course_data?.graduation === 'passed'
                                    ? '#25C717'
                                    : '#F66',
                            fontWeight: '500',
                        }}
                    >
                        {data?.course_data?.graduation === 'passed' ? (
                            <>
                                <IconF name="check" color="#25C717" size={14} />{' '}
                                {t('singleCourse.passed')}
                            </>
                        ) : (
                            <>
                                <IconF name="x" color="#F66" size={14} />{' '}
                                {t('singleCourse.failed')}
                            </>
                        )}
                    </Text>
                </View>

                // renderFakeTabBar(this.props.navigation)
            )}
            {isFinished && canRetake && (
                <TouchableOpacity
                    style={styles.btnAddToCart}
                    onPress={this.onRetake}
                >
                    <Text style={styles.txtAddToCart}>
                        {t('singleCourse.btnRetake')}
                    </Text>
                </TouchableOpacity>
            )}
            {isFinished && !canRetake && (
                <View
                    style={{
                        marginLeft: 10,
                        flex: 1,
                        paddingVertical: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        flexDirection: 'row',
                    }}
                >
                    <Text
                        style={{
                            fontFamily: 'Poppins-Medium',
                            fontSize: 14,
                            lineHeight: 21,
                            color: Colors.scholastik.textPrimary,
                            fontWeight: '500',
                        }}
                    >
                        {t('singleCourse.finished')}
                    </Text>
                </View>
                // renderFakeTabBar(this.props.navigation)
            )}
        </>;
    }

    render() {
        const {
            refreshing,
            // activeSections,
            // isWishlist,
            // review,
            // reviewMessage,
            hasPremium,
        } = this.state;
        const {t} = this.props;

        let hiddenBottom = this.state.hiddenBottom;
        if (hasPremium === undefined) {
            //still checking, return white background and wait
            return (<View style={{width: '100%', height: '100%'}}/>);
        }

        let categoryName = Cache.readTypeSafe('-', this.props, 'cached', 'activeCategory', 'name').toUpperCase();

        if (!hasPremium && Platform.OS === 'android') {
            return (<View style={[styles.paddingTopStatusBar, {width: '100%', height: '100%'}]}>
                <Web href={VALUES.SITE_URL + "/priprava"} onLoadEnd={() => Toast.show({
                    type: 'info',
                    text1: t('premium.required', {plan: categoryName}),
                    text2: t('premium.requiredDescription'),
                })}/>
                <TouchableOpacity
                    style={[styles.buttonBackAbsolute, {
                        backgroundColor: Colors.scholastik.purple,
                        padding: 12,
                        borderRadius: 24
                    }]}
                    onPress={this.goBack}
                    hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
                >
                    <Image source={Images.iconBack} style={[styles.iconBack]}/>
                </TouchableOpacity></View>);
        }

        const {course} = this.props;
        const data = course.data;

        let isFinished, canRetake, isFree, isInProgress, isPurchased, image;
        if (data?.course_data === undefined) {
            hiddenBottom = true;
        } else {
            isFinished = data.course_data.status === 'finished';
            canRetake = !data.can_retake;
            isFree = data.price <= 0;
            isInProgress = data.course_data.status === 'enrolled';
            isPurchased = data.course_data.status === 'purchased';
            // image = typeof data.image === "string" && !data.image.includes('learnpress/assets/images/placeholder') ?
            //     data.image : undefined;
        }

        return (
            <CommonAppLayout
                color={Colors.scholastik.purple}>

                <TallHeader onBack={() => this.goBack()}>
                    <Text numberOfLines={2} style={styles.title}>
                        {data?.name || t('singleCourse.title')}
                    </Text>
                </TallHeader>

                {data?.course_data === undefined ? (<View
                        style={{
                            backgroundColor: Colors.scholastik.purple
                        }}>
                        <InternetConnectionErrorView onPress={this.refreshData}/>
                    </View>) :
                    (<KeyboardAwareScrollView
                        removeClippedSubviews={false}
                        showsVerticalScrollIndicator={Platform.OS === "web"}
                        keyboardShouldPersistTaps="handled"
                        contentContainerStyle={{minHeight: '100%',
                            backgroundColor: Colors.scholastik.background, paddingBottom: 75}}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={this.refreshData}
                            />
                        }
                    >
                        <ContentMiddleLayout
                            style={{height: 'auto'}}
                            containerStyle={{
                            height: 'auto',
                            backgroundColor: Colors.scholastik.background, paddingTop: 10
                        }}>

                            {/*<View*/}
                            {/*    style={{*/}
                            {/*        flexDirection: 'row',*/}
                            {/*        alignItems: 'center',*/}
                            {/*        justifyContent: 'space-between',*/}
                            {/*    }}*/}
                            {/*>*/}
                                {/*<View style={{ flexDirection: 'row', alignItems: 'center' }}>*/}
                                {/*  <Image source={Images.iconClock} style={styles.icon} />*/}
                                {/*  <Text style={styles.txt3}>{data?.duration}</Text>*/}
                                {/*  {data &&*/}
                                {/*    data?.count_students !== '' &&*/}
                                {/*    data?.count_students > 0 && (*/}
                                {/*      <View*/}
                                {/*        style={{ flexDirection: 'row', alignItems: 'center' }}*/}
                                {/*      >*/}
                                {/*        <Image source={Images.iconStudent} style={styles.icon1} />*/}
                                {/*        <Text style={styles.txt3}>{data?.count_students}</Text>*/}
                                {/*      </View>*/}
                                {/*    )}*/}
                                {/*</View>*/}
                                {/*{data?.on_sale ? (*/}
                                {/*    <>*/}
                                {/*        {data?.sale_price_rendered ? (*/}
                                {/*            <Text style={styles.price}>*/}
                                {/*                {data.sale_price_rendered}*/}
                                {/*            </Text>*/}
                                {/*        ) : (*/}
                                {/*            <Text style={styles.price}>${data.sale_price}</Text>*/}
                                {/*        )}*/}
                                {/*        {data?.origin_price_rendered ? (*/}
                                {/*            <Text style={styles.oldPrice}>*/}
                                {/*                {data.origin_price_rendered}*/}
                                {/*            </Text>*/}
                                {/*        ) : (*/}
                                {/*            <Text style={styles.oldPrice}>${data.origin_price}</Text>*/}
                                {/*        )}*/}
                                {/*    </>*/}
                                {/*) : (data?.price > 0 && (*/}
                                {/*    <>*/}
                                {/*        {data?.price_rendered ? (*/}
                                {/*            <Text style={styles.price}>{data?.price_rendered}</Text>*/}
                                {/*        ) : (*/}
                                {/*            <Text style={styles.price}>${data?.price}</Text>*/}
                                {/*        )}*/}
                                {/*    </>*/}
                                {/*))}*/}
                            {/*</View>*/}
                            {data?.content !== undefined && data.content !== "" && (
                                <View style={{padding: 16}}>
                                    <Text style={styles.txtTitle}>
                                        {t('singleCourse.overview')}
                                    </Text>
                                    <RenderDataHTML html={data?.content} options={{dispatch: this.props.dispatch}}/>
                                </View>
                            )}

                            <Text style={[styles.txtTitle, {paddingHorizontal: 16}]}>
                                {t('singleCourse.curriculum')}</Text>

                            {/*{data && data.sections.length > 0 ? (*/}
                            {/*  <Accordion*/}
                            {/*    sections={data.sections}*/}
                            {/*    underlayColor="transpation"*/}
                            {/*    activeSections={activeSections}*/}
                            {/*    renderHeader={this.renderHeaderSession}*/}
                            {/*    renderContent={this.renderContent}*/}
                            {/*    onChange={(value) => {*/}
                            {/*      this.setState({ activeSections: value });*/}
                            {/*    }}*/}
                            {/*  />*/}
                            {/*) : (*/}
                            {/*  <Text style={{ fontFamily: 'Poppins' }}>*/}
                            {/*    {t('singleCourse.curriculumEmpty')}*/}
                            {/*  </Text>*/}
                            {/*)}*/}

                            {data && data.sections?.map(section => {  //todo preview means no disabled!, todo show warn if empty
                                return <CourseSectionType key={section.id}
                                    // ref={this.sectionTypeRendererRef}
                                                          section={section}
                                                          isDisabled={!isInProgress}
                                                          selectedItemId={this.state.selectedItemId}
                                                          styled={{marginTop: 10}}
                                                          onNavigateLearning={this.onNavigateLearning}
                                                          onSetSelectedId={(id) => this.setState({selectedItemId: id})}
                                />
                            })
                            }
                        </ContentMiddleLayout>
                    </KeyboardAwareScrollView>)
                }

                {!hiddenBottom &&
                    (isInProgress ? (
                            // <View style={styles.bottom}>
                            //         <TouchableOpacity
                            //         style={styles.btnAddToCart}
                            //         onPress={this.onStart}
                            //     >
                            //         <Text style={styles.txtAddToCart}>
                            //             {t('singleCourse.btnContinue')}
                            //         </Text>
                            //         <IconI
                            //             name="arrow-forward-outline"
                            //             style={{color: Colors.scholastik.purple, fontSize: 20, marginLeft: 16}}
                            //         />
                            //         </TouchableOpacity>
                            //
                            //     {this.renderBottomBarAdditionalInfo(data, isFinished, canRetake)}
                            // </View>

                            // <View style={{
                            //     margin: 0,
                            //     flex: 1,
                            //     backgroundColor: '#f7f7f7',
                            //     paddingVertical: 16,
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            //     flexDirection: 'row',
                            // }}>
                            //     {renderFakeTabBar(this.props.navigation)}
                            // </View>

                             <></>

                        ) : isPurchased ? /*TODO: test different course states (not used usually, but could occur) */(
                            <ContentMiddleLayout style={styles.bottom} containerStyle={{
                                backgroundColor: Colors.scholastik.background,
                                height: 'auto'}}>
                                <TouchableOpacity
                                    style={styles.btnAddToCart}
                                    onPress={this.onEnroll}
                                >
                                    <Text style={styles.txtAddToCart}>
                                        {t('singleCourse.btnStartNow')}
                                    </Text>
                                </TouchableOpacity>
                                {this.renderBottomBarAdditionalInfo(data, isFinished, canRetake)}
                            </ContentMiddleLayout>
                        ) : !isFree && !data?.course_data.status ? /*TODO: test different course states (not used usually, but could occur) */(
                            <>
                                {/* <View style={styles.bottom}><TouchableOpacity todo need for warn info placeholder, or redirect */}
                                {/*  style={styles.btnAddToCart}*/}
                                {/*  onPress={() => this.restorePurchases(String(data.id))}*/}
                                {/*>*/}
                                {/*  <Text style={styles.txtAddToCart}>*/}
                                {/*    {t('singleCourse.btnRestore')}*/}
                                {/*  </Text>*/}
                                {/*</TouchableOpacity>*/}
                                {/*<TouchableOpacity*/}
                                {/*  style={styles.btnAddToCart}*/}
                                {/*  onPress={() => this.addToCard(String(data.id))}*/}
                                {/*>*/}
                                {/*  <Text style={styles.txtAddToCart}>*/}
                                {/*    {t('singleCourse.btnAddToCart')}*/}
                                {/*  </Text>*/}
                                {/*</TouchableOpacity></View>*/}
                            </>
                        ) : isFree && !isFinished && /*TODO: test different course states (not used usually, but could occur) */(
                        <ContentMiddleLayout style={styles.bottom} containerStyle={{
                            backgroundColor: Colors.scholastik.background,
                            maxWidthMargin: 50,
                            height: 'auto'}}>
                            <TouchableOpacity
                                style={styles.btnAddToCart}
                                onPress={this.onEnroll}
                            >
                                <Text style={styles.txtAddToCart}>
                                    {t('singleCourse.btnStartNow')}
                                </Text>
                            </TouchableOpacity>
                            {this.renderBottomBarAdditionalInfo(data, isFinished, canRetake)}
                            </ContentMiddleLayout>
                        )
                    )
                }
            </CommonAppLayout>
        );
    }
}

const mapStateToProps = ({course, wishlist, user, cached, subscription, network, category}) => ({
    course,
    wishlist,
    user,
    cached,
    subscription,
    network,
    category
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CoursesDetails));
