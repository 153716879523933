import Types from './types';

/**
 * Save question pin data
 * @param question contains quizId, id, status, quizName, order
 * @returns {{question: Object, type: string}}
 */
export const saveQuestionStatus = (question: Object) => ({
  type: Types.PINS_CACHE,
  question
});

/**
 * Save question pin data
 * @param questions array of pin data
 * @returns {{questions: Array, type: string}}
 */
export const updatePinsData = (questions: Array) => ({
  type: Types.PINS_UPDATE,
  questions,
});

/**
 * Save question pin data
 * @param data object (map) of quiz id to its parent metadata
 * @returns {{data: Object, type: string}}
 */
export const updatePinsMetaData = (data: Object) => ({
  type: Types.PINS_METADATA,
  data,
});


/**
 * Remove quiz pin
 * @param id
 * @returns {{id: number, type: string}}
 */
export const deleteQuestionStatus = (id: number) => ({
  type: Types.PINS_DELETE,
  id
});

/**
 * Remove quiz pin
 * @param id
 * @returns {{id: number, type: string}}
 */
export const eraseQuestionStats = () => ({
  type: Types.PINS_ERASE
});

