/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  View,
} from 'react-native';

class Loading extends Component {

  render() {
    return <View style={{
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
      ...StyleSheet.absoluteFillObject,
      flex: 1,
      width: '100%',
      height: '100%'}}>
      <ActivityIndicator size="large" color="white" />
    </View>;
  }
}


export default Loading;
