import React, { PureComponent } from 'react';
import {
  Image,
  Keyboard, Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {Alert, BackHandler} from "proxy"
import { withTranslation } from 'react-i18next';
import { Colors, Images } from 'app-assets';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/Ionicons';
import { KeyboardAwareScrollView } from 'proxy';
import { HttpAPI, setToken } from 'app-api';
import { ValidateEmail, Routines } from 'app-common';
import styles from './styles';
import { setLoading } from '../../actions/common';
import {VALUES} from "../../../setup/config";
import {CommonAppLayout, ContentMiddleLayout} from "app-component";

const sleep = ms => new Promise(r => setTimeout(r, ms));

class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCheck: false,
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }

  handleBackPress = () => {
    this.onBack(); // works best when the goBack is async
    return true;
  };

  onBack = () => {
    this.autoGoBack();
  };

  onValidate = () => {
    const { isCheck } = this.state;
    if (!isCheck) return false;
    return true;
  };

  validate() {
    const { t } = this.props;
    const { email, username, password, confirmPassword, isCheck } = this.state;
    if (!username || username.length === 0) {
      Alert.alert('', t('registerScreen.usernameEmpty'));
      this.username.focus();
      return false;
    }
    if (!email || email.length === 0) {
      Alert.alert('', t('registerScreen.emailEmpty'));
      this.email.focus();
      return false;
    }
    if (!ValidateEmail(email)) {
      Alert.alert('', t('registerScreen.validEmail'));
      this.email.focus();
      return false;
    }
    if (!password || password.length === 0) {
      Alert.alert('', t('registerScreen.passwordEmpty'));
      this.password.focus();
      return false;
    }
    if (!confirmPassword || confirmPassword.length === 0) {
      Alert.alert('', t('registerScreen.confirmPasswordEmpty'));
      this.confirmpassword.focus();
      return false;
    }
    if (password !== confirmPassword) {
      Alert.alert('', t('registerScreen.incorrectPassword'));
      this.confirmpassword.focus();
      return false;
    }
    if (!isCheck) {
      Alert.alert('', t('registerScreen.termAndConditionEmpty'));
      return false;
    }

    return true;
  }

  register = async () => {
    if (!this.validate()) {
      return;
    }
    const { t, dispatch, navigation } = this.props;

    if (!this.onValidate) return;

    Keyboard.dismiss();

    dispatch(setLoading(true));


    const { email, username, password, confirmPassword } = this.state;
    const params = {
      email: String(email).trim(),
      username: String(username).trim(),
      password: String(password).trim(),
      confirm_password: confirmPassword,
    };
    const response = await HttpAPI.register(params).then(r => r?.responseData);


    if (response?.token) {
      try {
        await Routines.logout(this.props);

        // Customer yet did not pay for optimization
        await sleep(200);

        const login = await HttpAPI.login({
          username: params.username,
          password: params.password
        }).then(r => r?.responseData);

        if (!login) {
          Alert.alert(t('registerScreen.failedToRegister'), t('registerScreen.unknownError'));
          return;
        }

        await Routines.afterLogin(this.props, login);
        this.autoGoHome();

      } finally {
        dispatch(setLoading(false));
      }
      return;
    }

    dispatch(setLoading(false));

    if (response?.message) {
      Alert.alert(t('registerScreen.failedToRegister'), response?.message);
    } else {
      Alert.alert(t('registerScreen.failedToRegister'), t('registerScreen.unknownError'));
    }
  };

  showConditions() {
    const { navigation } = this.props;
    this.autoNavigate('WebScreen', {href: VALUES.ZASADY});
  }

  render() {
    const { t } = this.props;
    const { isCheck, email, username, password, confirmPassword } = this.state;

    return (
        <CommonAppLayout
            edges={['right', 'left', 'top']}
            color={Colors.scholastik.white}>
        <KeyboardAwareScrollView
          contentContainerStyle={styles.container}
          keyboardShouldPersistTaps="always"
        >
          <ScrollView
            showsVerticalScrollIndicator={Platform.OS === "web"}
            bounces={false}
            contentContainerStyle={{paddingBottom: 50}}
            keyboardShouldPersistTaps="handled"
          >
            <ContentMiddleLayout containerStyle={{ paddingHorizontal: 46,
              marginTop: 35, marginBottom: 80, height: "auto"}}>
              <TouchableOpacity
                  style={{ marginLeft: 10, width: 50, position: "absolute" }}
                  onPress={this.onBack}
              >
                <Image source={Images.iconBack} style={styles.iconBack} />
              </TouchableOpacity>
              <View style={styles.viewLogo}>
                <Image source={Images.LogoSchool} style={styles.logo} />
                <Text style={styles.title}>{t('registerScreen.title')}</Text>
              </View>
              <View
                style={[
                  styles.viewInput,
                  username.length > 0
                    ? { borderWidth: 2, borderColor: Colors.scholastik.textPrimary }
                    : {},
                ]}
              >
                <TextInput
                  ref={(ref) => {
                    this.username = ref;
                  }}
                  placeholder={t('registerScreen.usernamePlaceholder')}
                  placeholderTextColor="#9E9E9E"
                  style={styles.textInput}
                  autoCapitalize="none"
                  autoCorrect={false}
                  onChangeText={(value) => this.setState({ username: value })}
                />
                {username.length > 0 && (
                  <Image source={Images.icEnterUsername} style={styles.icEnter} />
                )}
              </View>
              <View
                style={[
                  styles.viewInput,
                  email.length > 0 ? { borderWidth: 2, borderColor: Colors.scholastik.textPrimary } : {},
                ]}
              >
                <TextInput
                  ref={(ref) => {
                    this.email = ref;
                  }}
                  autoCapitalize="none"
                  autoCorrect={false}
                  placeholder={t('registerScreen.emailPlaceholder')}
                  placeholderTextColor="#9E9E9E"
                  style={styles.textInput}
                  onChangeText={(value) => this.setState({ email: value })}
                />
                {email.length > 0 && (
                  <Image source={Images.icEnterEmail} style={styles.icEnter} />
                )}
              </View>
              <View
                style={[
                  styles.viewInput,
                  password.length > 0
                    ? { borderWidth: 2, borderColor: Colors.scholastik.textPrimary }
                    : {},
                ]}
              >
                <TextInput
                  ref={(ref) => {
                    this.password = ref;
                  }}
                  secureTextEntry={!this.state.showPassword}
                  placeholder={t('registerScreen.passwordPlaceholder')}
                  placeholderTextColor="#9E9E9E"
                  style={styles.textInput}
                  value={password}
                  onChangeText={(value) => this.setState({ password: value })}
                />
                {password.length > 0 && (
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  >
                    <Image
                      source={Images.icEnterPassword}
                      style={styles.icEnter}
                    />
                  </TouchableOpacity>
                )}
              </View>
              <View
                style={[
                  styles.viewInput,
                  confirmPassword.length > 0
                    ? { borderWidth: 2, borderColor: Colors.scholastik.textPrimary }
                    : {},
                ]}
              >
                <TextInput
                  ref={(ref) => {
                    this.confirmpassword = ref;
                  }}
                  placeholder={t('registerScreen.confirmPasswordPlaceholder')}
                  placeholderTextColor="#9E9E9E"
                  style={styles.textInput}
                  secureTextEntry={!this.state.showConfirmPassword}
                  value={confirmPassword}
                  onChangeText={(value) =>
                    this.setState({ confirmPassword: value })
                  }
                />
                {confirmPassword.length > 0 && (
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({
                        showConfirmPassword: !this.state.showConfirmPassword,
                      })
                    }
                  >
                    <Image
                      source={Images.icEnterPassword}
                      style={styles.icEnter}
                    />
                  </TouchableOpacity>
                )}
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <TouchableOpacity
                  onPress={() => this.setState({ isCheck: !isCheck })}
                >
                  <Icon
                    name={!isCheck ? 'stop-outline' : 'checkbox-outline'}
                    style={styles.iconCheck}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.showConditions()}
                >
                  <Text style={[styles.txtAccept, {textDecorationLine: 'underline'}]}>
                    {t('registerScreen.termAndCondition')}
                  </Text>
                </TouchableOpacity>

              </View>
              <TouchableOpacity style={styles.btnSubmit} onPress={this.register}>
                <Text style={styles.txtSubmit}>
                  {t('registerScreen.btnSubmit')}
                </Text>
              </TouchableOpacity>
            </ContentMiddleLayout>
          </ScrollView>
        </KeyboardAwareScrollView>
        </CommonAppLayout>
    );
  }
}
const mapStateToProps = ({ network, user }) => ({
  network, user
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Register));
