import { StyleSheet, Dimensions, Platform } from 'react-native';
import { getStatusBarHeight, ifIphoneX } from 'app-common';
import {Colors} from "app-assets";

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height;

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.scholastik.white,
    flex: 1,
    zIndex: 1,
  },
  header: {
    paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() : 0,
    marginTop: 20,
    // paddingHorizontal: 16,
  },
  header1: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  viewInput: {
    borderRadius: 12,
    flex: 1,
    height: 40,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F3F3F3',
    marginRight: 8,
  },
  inputSearch: {
    flex: 1,
    fontFamily: 'Poppins',
    fontSize: 10,
    lineHeight: 15,
    color: '#000',
  },
  txtPhone: {
    marginLeft: 14,
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: 21,
    color: '#000',
  },
  iconMenu: {
    height: 22,
    width: 22,
    resizeMode: 'contain',
    tintColor: '#000',
  },
  iconBack: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    tintColor: '#000',
  },
  icon: {
    height: 15,
    width: 15,
    resizeMode: 'contain',
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 20,
  },
  subTitle: {
    fontFamily: 'Poppins-ExtraLight',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 15,
    flexWrap: 'wrap',
    marginRight: 40,
    marginTop: 5
  },
  btn: {
    flexDirection: 'row',
    position: 'absolute',
    ...ifIphoneX(
      {
        bottom: 50,
        right: 16,
      },
      {
        bottom: 16,
        right: 16,
      }
    ),

    justifyContent: 'center',
    alignItems: 'center',
  },
  txtBtn: {
    fontFamily: 'Poppins-Medium',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 24,
    marginRight: 9,
  },

  childTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: 27,
    marginTop: 34,
  },
  txtTime: {
    fontWeight: '500',
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 20,
    color: '#FF2E2E',
    marginLeft: 9,
  },
  contentMenu: {
    marginHorizontal: 16,
  },
  bottomBar: {
    elevation: 11,
    zIndex: 11,
    position: 'absolute',
    height: 70,
    width: '100%',
    backgroundColor: Colors.scholastik.white,
    bottom: 0,
  },
  buttonAnswer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
    height: 50,
    borderColor: Colors.scholastik.purple,
    borderWidth: 2,
    marginHorizontal: 22,
    marginVertical: 10
  },
  btnPage: {
    width: 25,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E4E4E4',
    marginHorizontal: 3,
  },
  txtPage: {
    fontFamily: 'Poppins-Medium',
    fontSize: 10,
    lineHeight: 20,
    color: '#000',
    fontWeight: '500',
  },
  viewPage: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 23,
  },
  flatPage: {
    flex: 1,
    alignSelf: 'center',
    marginHorizontal: 40,
  },
  flatPageContainer: {
    // width: deviceWidth,
  },
  viewQuestion: {
    marginTop: 23,
  },
  itemQuestion: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.scholastik.gray,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 8,
    marginVertical: 4,
  },
  txtItemQuestionIcon: {
    fontFamily: 'Poppins',
    fontSize: 15,
    lineHeight: 19,
    marginLeft: 9,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  txtItemQuestion: {
    fontFamily: 'Poppins',
    fontSize: 13,
    lineHeight: 19,
    marginLeft: 9,
    paddingRight: 55,
  },
  txt1: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    lineHeight: 24,
    color: '#D03838',
    fontWeight: '500',
    marginBottom: 20,
  },
  txt2: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 13,
    lineHeight: 19,
    color: '#000',
    fontWeight: '600',
  },
});
