import React, {Component} from 'react';
import {
    Linking,
} from 'react-native';

import {withTranslation} from 'react-i18next';
import {VALUES} from "../../../setup/config";
import {CommonAppLayout, ContentMiddleLayout} from "../../component";

class SubscriptionScreen extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Linking.openURL(VALUES.SITE_URL + '/studijni-plany');
        this.autoGoHome();
    }

    render() {
        return <CommonAppLayout>
            <ContentMiddleLayout>

            </ContentMiddleLayout>
        </CommonAppLayout>;
    }
}


export default withTranslation()(SubscriptionScreen);