import App from './src';

import * as Font from 'expo-font';
import { Linking } from 'react-native'
import {setConst, VALUES} from "./setup/config";

let customFonts = {
    'Poppins': require('./setup/assets/fonts/Poppins.ttf'),
    'Poppins-Black': require('./setup/assets/fonts/Poppins-Black.ttf'),
    'Poppins-BlackItalic': require('./setup/assets/fonts/Poppins-BlackItalic.ttf'),
    'Poppins-Bold': require('./setup/assets/fonts/Poppins-Bold.ttf'),
    'Poppins-BoldItalic': require('./setup/assets/fonts/Poppins-BoldItalic.ttf'),
    'Poppins-ExtraBold': require('./setup/assets/fonts/Poppins-ExtraBold.ttf'),
    'Poppins-ExtraBoldItalic': require('./setup/assets/fonts/Poppins-ExtraBoldItalic.ttf'),
    'Poppins-ExtraLight': require('./setup/assets/fonts/Poppins-ExtraLight.ttf'),
    'Poppins-ExtraLightItalic': require('./setup/assets/fonts/Poppins-ExtraLightItalic.ttf'),
    'Poppins-Italic': require('./setup/assets/fonts/Poppins-Italic.ttf'),
    'Poppins-Light': require('./setup/assets/fonts/Poppins-Light.ttf'),
    'Poppins-LightItalic': require('./setup/assets/fonts/Poppins-LightItalic.ttf'),
    'Poppins-Medium': require('./setup/assets/fonts/Poppins-Medium.ttf'),
    'Poppins-MediumItalic': require('./setup/assets/fonts/Poppins-MediumItalic.ttf'),
    'Poppins-Regular': require('./setup/assets/fonts/Poppins-Regular.ttf'),
    'Poppins-SemiBold': require('./setup/assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-SemiBoldItalic': require('./setup/assets/fonts/Poppins-SemiBoldItalic.ttf'),
    'Poppins-Thin': require('./setup/assets/fonts/Poppins-Thin.ttf'),
    'Poppins-ThinItalic': require('./setup/assets/fonts/Poppins-ThinItalic.ttf'),
};

App.onBeforeStart(async () => {
    //must be dynamic based on the domain
    const rootUrl = await Linking.getInitialURL() || "";
    const url = new URL(rootUrl);

    const regex = /^(www\.)?app\./i;
    let hostname = url.hostname.replace(regex, '');
    if (__DEV__) {
        hostname += ':5000';
    } else if (hostname === url.hostname) {
        Linking.openURL('https://scholastik.cz');
        //todo redirect
        throw "Invalid deployment: required application domain!";
    }
    const apiUrl = url.protocol + '//' + hostname;
    console.log("Deployed on", apiUrl);

    // const hostname = url.hostname;
    // const apiUrl = url.protocol + '//' + hostname;

    setConst('SITE_URL', apiUrl);
    setConst('SCHEME', hostname.split('.').reverse().join('.'));
    setConst('SCHEME_CTX', hostname);
    setConst('DOMAIN_NAME', hostname);
    setConst('ZASADY', VALUES.SITE_URL + '/zasady-ochrany/');
    setConst('OBCHODNI', VALUES.SITE_URL + '/obchodni-podminky/');
});

App.onStart(async() => {
    await Font.loadAsync(customFonts);
})
export default App;