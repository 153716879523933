import React, {Component} from 'react';
import {Platform, ScrollView, Text, TouchableOpacity, View,} from 'react-native';
import {Alert} from "proxy";
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import styles from './styles';
import {Colors} from 'app-assets';
import {showLoading} from "../../actions/common";
import {Events, Routines} from "../../common/util";
import {VALUES} from "../../../setup/config";
import PlainButton from "../../component/common/plainButton";
import {CommonAppLayout, ContentMiddleLayout, FullScreenLayout} from "app-component";
import {Cache} from "app-api";
import IconM from "react-native-vector-icons/MaterialCommunityIcons";
import ErrorView from "../../component/common/error-view";
import {CategoryHeader} from "../../component/categoryHeader";
import {setSelectedCategory} from "../../actions/category";

class Start extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allData: [],
            toStartData: [],
            categoryData: [],
        };
    }

    filterDataByActiveCategory() {
        const activeCategory = this.props.category.activeCategorySlug;
        return this.state.allData.filter(x => {
            const categoryData = x.courseData?.categories;
            if (categoryData) {
                return categoryData.some(y => y.slug === activeCategory);
            }
            // unknown are shown too :/
            return true;
        });
    }

    async refreshData() {
        const categoryData = await Routines.getCourseCategoryMeta(this.props);

        const lastViewed = Cache.get('lastViewedCourseStackParams', [], this.props,
            'cached', VALUES.EXPIRY_TSTAMP.NEVER);
        this.setState({
            allData: lastViewed,
            toStartData: this.filterDataByActiveCategory(lastViewed),
            categoryData: categoryData,
        });
    }

    componentDidMount() {
        const {navigation} = this.props;
        Events.onCustom(this, 'navigationFocus',
            () => navigation.addListener('focus', this.refreshData.bind(this)));
        this.refreshData();
    }

    componentWillUnmount() {
        Events.off(this, 'navigationFocus');
    }

    async start(item, delay = 1000) {
        if (delay > 0) {
            const self = this;
            setTimeout(() => self.redirect(item), delay);
        }
        this.redirect(item);
    }

    redirect(activeInstance) {
        const {t, navigation, dispatch} = this.props,
        _this = this;

        Routines.performNecessaryUserChecksBeforeCourseStart(this.props, activeInstance.courseData,
            async (sub) => {
                _this.autoNavigate('LearningScreen',{
                    //todo rename to actionveInstance.course
                    courseId: activeInstance.courseData.id,
                    //todo rename to activeInstance.section
                    sectionType: activeInstance.item.type,
                    sectionId: activeInstance.item.id,
                    questionIndex: null
                });
            }, (sub, premiums) => {
                _this.autoNavigate('Subscription', {
                    requiredPremiums: premiums
                });
            }, (sub, premiums) => {
                Alert.alert(
                    t('errors.subscription.title'),
                    t('errors.subscription.body')
                    + activeInstance.courseData.categories.map(c => c.name?.toUpperCase()).join(", ")
                    + (Platform.OS === 'android' ? '. ' + t('errors.subscription.additional') : ''),
                );
            }
        ).catch(e => {
            console.error('Start WillMountERR:', e);
            Alert.alert(
                t('errors.titleGeneral'),
                t('errors.dataGeneral'),
            );
            dispatch(showLoading(false));
            this.autoGoBack();
        });
    }

    handleBackPress = () => {
        return false;
    };

    renderStartItemCategory(category, index) {
        return <Text
            key={index}
            style={{
                fontFamily: 'Poppins', fontSize: 13, paddingRight: 5,
                paddingLeft: 15,
                paddingVertical: 3, color: Colors.scholastik.darkGrayText, textTransform: "capitalize"
            }}>{category.name}
        </Text>;
    }

    renderStartItem(item, index) {
        const date = new Date(item.timestamp),
            time = date.getHours() + ":" + date.getMinutes() + ", " + date.toDateString();
        const name = item.item?.name || item.item?.title;
        const courseName = item.courseData?.name || item.courseData?.title;

        return <TouchableOpacity
            style={{
                marginHorizontal: 30,
                backgroundColor: Colors.scholastik.white,
                borderRightColor: Colors.scholastik.purple,
                borderRightWidth: 3,
                borderRadius: 14,
                minHeight: 50,
                marginVertical: 10,
                paddingVertical: 2,
            }
            }
            onPress={() => this.start(item, 0)}
            key={index}
        >
            <Text style={{
                fontFamily: 'Poppins', fontWeight: 'bold', paddingRight: 5, paddingVertical: 2,
                paddingLeft: 15, fontSize: 12, lineHeight: 20, color: Colors.scholastik.darkGrayText,
            }}>{time}</Text>
            {
                name !== undefined && <Text style={{
                    fontFamily: 'Poppins', fontWeight: 'bold', paddingRight: 5,
                    paddingLeft: 15,
                    paddingBottom: 10, fontSize: 16, lineHeight: 20, color: Colors.scholastik.purple,
                }}>{name}</Text>
            }
            {
                courseName !== undefined && <Text style={{
                    fontFamily: 'Poppins', paddingRight: 5,
                    paddingLeft: 15,
                    lineHeight: 16,
                    fontSize: 14
                }}>{courseName}
                </Text>
            }
            <View style={{display: "flex", flexDirection: "row"}}>
                {
                    item.courseData.categories && item.courseData.categories.map(
                        (item, index) => this.renderStartItemCategory(item, index))
                }
            </View>
        </TouchableOpacity>;
    }

    async onClickChooseCategory(slug) {
        const { dispatch } = this.props;
        if ((this.state.categoryData || []).find(x => x.slug === slug)) {
            await dispatch(setSelectedCategory(slug));
            const filteredData = this.filterDataByActiveCategory();
            this.setState({toStartData: filteredData})
        } else {
            console.warn("Attempt to set invalid slug!");
        }
    }

    render() {
        const {t, user, category} = this.props;
        const {toStartData, categoryData} = this.state;
        const data = Array.isArray(toStartData) ? toStartData : [];

        return <CommonAppLayout
            edges={['right', 'left', 'top']}
            color={Colors.scholastik.purple}>
            {
                user?.token ? (
                    <ScrollView
                        showsVerticalScrollIndicator={Platform.OS === "web"}
                        contentContainerStyle={{width: '100%', minHeight: '100%'}}>

                        <CategoryHeader
                            title={t('startScreen.chooseTitle')}
                            categories={categoryData}
                            selectedCategory={category.activeCategorySlug}
                            onSelect={slug => this.onClickChooseCategory(slug)}
                            navigation={this.props.navigation}
                        >
                        </CategoryHeader>

                        <ContentMiddleLayout containerStyle={{
                            backgroundColor: Colors.scholastik.background, paddingTop: 10}}>
                            {
                                data.length === 0 ? <ErrorView
                                    title={t('startScreen.noActiveAvailableTitle')}
                                    body={t('startScreen.noActiveAvailableBody')}
                                    titleStyle={{
                                        color: Colors.scholastik.textTertiary
                                    }}
                                    containerStyle={{height: 150, marginTop: 20}}
                                    IconComponent={IconM}
                                    icon="history"
                                    iconStyle={{color: Colors.scholastik.textTertiary}}
                                    bodyStyle={{color: Colors.scholastik.textTertiary}}
                                ></ErrorView> : data.map((item, index) => this.renderStartItem(item, index))
                            }
                        </ContentMiddleLayout>
                    </ScrollView>
                ) : <FullScreenLayout>
                    <Text style={[styles.title, {color: Colors.scholastik.textPrimary}]}>{this.props.t('startScreen.chooseTitle')}</Text>

                    <Text style={{fontFamily: 'Poppins-Medium', fontSize: 16, marginBottom: 15,
                        color: Colors.scholastik.darkGrayText}}> {t('needLogin')}</Text>
                    <View style={{flexDirection: "column", marginBottom: 30}}>

                        <PlainButton
                            onPress={() => this.autoNavigate('LoginScreen')}
                            title={t('login')}
                        />
                        <PlainButton
                            onPress={() => this.autoNavigate('RegisterScreen')}
                            title={t('register')}
                            containerStyle={{backgroundColor: Colors.scholastik.blue}}
                        />
                    </View>
                </FullScreenLayout>
            }
        </CommonAppLayout>;
    }
}

const mapStateToProps = ({course, user, cached, subscription, category}) => ({
    course,
    user,
    cached,
    subscription,
    category,
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Start));
