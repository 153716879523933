export default{
  login:'Přihlásit',
  register:'Zaregistrovat',
  logout:'Odhlásit se',
  lesson:'Sekce',
  course:'Kurz',
  quiz:'Kvíz',
  assignment:'Úkol',
  instructor:'Instruktor',
  dataNotFound:'Data nebyla nalezena.',
  needLogin:'Pro tuto akci se musíš přihlásit.',
  other: 'Ostatní',
  unknown: 'Neznámé',
  free:'ZDARMA',
  sale:'VÝPRODEJ',
  plan: 'Plán',
  delete: 'Smazat',
  durations:'Doba trvání: ',
  start:'START',
  back:'Zpět',
  homeButton: 'Zpátky na web',
  loading:'načítám...',
  feature:'Těšíme se na více funkcionality.',
  refresh: 'Zkusit znovu',
  canHelpIfNoWifi: 'Můžeš mít problémy s internetovým připojením a nebo je {{domain}} nedostupné.',
  canHelpUnknown: 'Problém může být na naší straně. Prosíme, zašli nám log chyby z profilu.',
  activePlan: 'Aktivní studijní plán',
  purchase: {
    errFinish: 'Nepodařilo se dokončit objednávku',
    errShow: 'Nelze načíst studijní plán',
    errShowDetails: "prosíme, zašli nám zprávu o chybě z profilu. Děkujeme."
  },
  premium: {
    title: 'Aktivní studijní plány',
    noAvailable: 'Nemáš žádné aktivní studijní plány.\n Aktualizovat svůj status můžeš zde:',
    refresh: 'ZKONTROLOVAT',
    required: 'Studijní plán {{plan}} není aktivní.',
    requiredDescription: 'Plán si můžeš zakoupit zde.',
  },
  alert: {
    logoutTxt:'Opravdu se chceš odhlásit?',
    ok:'OK',
    cancel:'Zrušit',
    notLoggedIn:'Nepřihlášen/a',
    loggedIn:'Prosím, přihlas se.',
    btnLogin:'Přihlásit se',
    restorePurchases:'Obnovit nákupy',
    restorePurchasesSuccess:'Nákupy byly úspěšně obnoveny.',
  },
  bottomNavigation: {
    subject:'předměty',
    courses:'nástěnka',
    history:'historie',
    profile:'profil',
  },
  loginScreen: {
    title:'Přihlásit se na účet',
    usernamePlaceholder:'Email / uživatelské jméno',
    passwordPlaceholder:'Heslo',
    btnLogin:'Přihlásit se',
    forgotPassword:'Zapomenuté heslo',
    registerText:"Ještě nemáš účet?",
    register:'Zaregistrovat',
    usernameEmpty:'Chybí přihlašovací jméno.',
    passwordEmpty:'Chybí heslo.',
    usernameNotCorrect:'Zadány neplatné údaje.',
    passwordNotCorrect:'Zadány neplatné údaje.',
    notFound:'Nenalezeno',
    userNotFound: 'Zadané údaje nesedí k žádnému účtu - opravdu máš účet na {{domain}}?',
    invalidCredentials: 'Zadané údaje nesedí k žádnému účtu - byly zadány správně (pro {{domain}})?',
    thirdPartyLoginScreen: 'Další možnosti přihlášení',
    fuckinApple: 'Přihlásit se přes {{domain}} \n Google | Apple ID | Facebook',
    thirdPartyLogin: 'Přihlásit se pomocí sociálních sítí',
    failedThirdPartyLogin: 'Nepodařilo se přihlásit přes {{domain}}. Prosím, zkus to znovu.',
    websiteNotAutoLoggedIn: 'Aplikace pro procvičování je umístěna na vlastním webu. Prosíme, přihlaš se.'
  },
  startScreen: {
    chooseTitle: 'Historie procvičování',
    noActiveAvailableTitle: 'V historii není žádný procvičovaný kurz',
    noActiveAvailableBody: 'Začni procvičovat nějaký z předmětů a zde se k poslednímu procvičovanému rychle můžeš vrátit.',
    generalErrorTitle: 'Něco se nepodařilo.',
    generalErrorTitleBody: 'Prosíme, vyber manuálně jeden z kruzů a začni procvičovat přes kartu \'předměty\'.',
    cancelledTitle: 'Načítání přerušeno',
    actionAbort: 'Zrušit',
    redirecting: 'Spouštím...',
    actionLoad: 'Pokračovat'
  },
  screen404: {
    title: 'Nenalezeno',
    details: 'Nepodařilo se najít obsah',
    accessDenied: 'Vypršel tvůj studijní plán!'
  },
  coursePreview: {
    short: 'ukázka',
    title: 'Ukázkové úlohy',
    unableToUsePin: 'Úlohy si lze označovat pouze s aktivním studijním plánem.',
    unableToEvaluate: 'Kvíz se dá vyhodnotit pouze s aktivním studijním plánem.',
    unableToGeneral: 'Akce se dá provést pouze s aktivním studijním plánem!.',
    availableStudyPlan: "Dostupné pouze pro studijní plány.",
    needToLogin: ' Akce vyžaduje přihlášení.',
    noAvailable: 'Žádné ukázky k dispozici',
    wantThis: 'Chci studijní plán',
    noAvailableDesc: 'Tento kurz neobsahuje žádné ukázkové úlohy. Vyzkoušej prosím jiný.',
  },
  registerScreen: {
    title:'Vytvořit účet',
    usernamePlaceholder:'Email / uživatelské jméno',
    emailPlaceholder:'E-mail',
    passwordPlaceholder:'Heslo',
    confirmPasswordPlaceholder:'Potvrdit heslo',
    termAndCondition:'Souhlasím se zásadami ochrany osobních údajů.',
    btnSubmit:'Vytvořit účet',
    usernameEmpty:'Musíš zadat uživatelské jméno.',
    emailEmpty:'E-mail je vyžadován.',
    validEmail:'E-mail je neplatný.',
    passwordEmpty:'Heslo je vyžadováno.',
    confirmPasswordEmpty:'Je vyžadováno potvrzení hesla.',
    incorrectPassword:'Nesprávné potvrzovací heslo!',
    termAndConditionEmpty:'Je nutné potvrdit souhlas se zásadami ochrany osobních údajů.',
    failedToRegister: 'Registrace se nezdařila.',
    unknownError: 'Neznámá chyba. Prosíme, napište nám o problému. Děkujeme.',
  },
  board: {
    title: 'Nástěnka',
    noPins: 'Nemáš žádné připnuté otázky',
    noPinsDesc: 'Použij ikonu připínáčku při řešení úloh pro jejich zapamatování. Pokud ses právě přihlásil/a, počkej chvíli prosím pro načtení.',
    loading: 'Načítám...',
    alert: {
      removePin: 'Opravdu odepnout?',
      cannotUndone: 'Otázka se již nebude zobrazovat na nástěnce',
      choose: 'Otázka číslo {{number}}',
      navigate: 'Zobrazit',
      remove: 'Odepnout',
      back: 'Zpět',
    }
  },
  home: {
    overview: {
      title:'Přehled aktivního kurzu',
      section:'Sekce',
      sections:'Sekce',
    },
    details: {
      titleMissingSubscription: 'Nemáš aktivní studijní plán!',
      detailsMissingSubscription: 'K dispozici máš ukázky úloh. Přístup ke všem úlohám, videokurzům a cvičným testům můžeš získat zde.',
      loginRequiredSubscription: 'Pro získání studijního plánu je nutné se přihlásit na účet {{domain}}',
      iWantThis: 'Chci na vysokou!'
    },
    category:'Kategorie testů',
    popular:'Populární kurzy',
    new:'Nové kurzy',
    countCourse:'{{count}} kurzů',
    countStudent:'{{count}} studentů',
  },
  courses: {
    title:'Kurzy',
    searching:'Vyhledávání {{keySearch}}',
    filters: {
      filter:'Filtr',
      default:'Výchozí',
      title:'Nadpis',
      sale:'Výprodej',
      popular:'Populární',
      newest:'Nejnovější',
      oldest:'Nejstarší',
    },
    types: {
      ready:'Začít',
      quiz:'Úlohy',
      theory:'Teorie'
    },
    sectionCount: 'Počet sekcí: {{count}}',
  },
  searchScreen: {
    placeholder:'Hledat...',
    title:'Poslední vyhledávání',
  },
  myCourse: {
    title:'Pokračovat v kurzu',
    filters: {
      filter:'Filtr',
      all:'All',
      passed:'Passed',
      inProgress:'In progress',
      failed:'Failed',
    },
    loading: 'Načítáme tvůj kurz...',
  },
  wishlist: {
    title:'Můj seznam přání',
  },
  profile: {
    title:'Profil',
  },
  settings: {
    title:'Nastavení',
    general:'Obecné',
    password:'Heslo',
    deleteAccount:'Vymazat účet',
    upload:'Nahrát',
    bio:'Životopisné údaje',
    firstName:'Jméno',
    enterPassword:'Zadej své heslo.',
    fillPasswords:'Prosím zadej své stávající, a také nové heslo.',
    noMatch:'Zadané nové heslo se neshoduje.',
    lastName:'Příjmení',
    nickName:'Přezdívka',
    save:'Uložit změny',
    saved: 'Změny byly uloženy',
    savePwd: 'Změnit heslo',
    currentPassword:'Současné heslo',
    newPassword:'Nové heslo',
    confirmNewPassword:'Potvrdit nové heslo',
    deleteAccountTitle:
        'Opravdu chceš vymazat svůj účet? Prosím, před smazáním účtu si přečti informace ohledně toho, jak to probíhá.',
    deleteAccountTitle2:'Účet',
    deleteAccountContent:
        'Tvůj účet bude vymazán z databáze. Všechny kurzy budou ze tvého účtu smazány.',
    deleteAccountWarn: 'Smazání účtu neslouží ke zrušení předplatného, zrušení musíte provést tam, kde jste předplatné získali: v {{platform}}, nebo na webu {{domain}}.',
    deleteAccountContent2:'Prosím, vyplň heslo pro potvrzení:',
    deleteAccountBtn:'Vymazat',
  },
  myOrders: {
    title:'Moje objednávky',
    order:'Objednávka',
    date:'Datum',
    status:'Stav',
    total:'Celkem',
  },
  instructorScreen: {
    title:'Instruktor',
    countCourse:'{{count}} kurzů',
    countStudent:'{{count}} studentů',
  },
  singleCourse: {
    title:'Jednotlivé kurzy',
    overview:'Přehled',
    curriculum:'Seznam lekcí',
    curriculumEmpty:'Seznam lekcí je prázdný.',
    instructor:'Instruktor',
    review:'Hodnocení',
    rating:'{{total}} hodnocení',
    leaveAReview:'Zanechat hodnocení',
    leaveAReviewDescription:
        'Prosím, uveď co nejvíce informací. Detailní hodnocení může pomoct ostatním.',
    reviewTitle:'Název',
    reviewTitleEmpty:'Prosím, vyplň název hodnocení',
    reviewContentEmpty:'Prosím, vyplň hodnocení',
    reviewRating:'Hodnocení',
    reviewContent:'Obsah',
    reviewSubmit:'Odeslat hodnocení',
    btnContinue:'Pokračovat',
    btnStartNow:'Začít nyní',
    btnRepeat: 'Začít nový pokus',
    btnAddToCart:'Přidat do košíku',
    btnRestore:'Obnovit',
    btnRetake:'Začít znovu',
    btnRetake2: 'Vyhodnocení',
    btnFixFail: 'Vyhodnocení',
    retakeNotice:'',
    passed:'Prospěl/a',
    failed:'Neprospěl/a',
    finished:'Dokončeno',
    average:'Průměr:',
    inProgressWarn:'Test s časovým limitem právě běží!',
    outOfTimeWarn:'Časový limit vypršel!',
    outOfTimeWarnContinue: 'Budeš přesměrován/a na výsledky.',
    outOfTimeButton: 'Vyhodnotit',
    purchaseNotCompleted:
        'Tvůj nákup nebyl dokončen, zkus to prosím znovu později.',
    restoreCourseNotPurchase:'Tento kurz není možné obnovit.',
    itemDuration: 'Časový limit:',
    noLimit: 'není',
    notAvailable: '-',
    remaining: ' (zbývá {{remaining}})',
    precentilNotAvailableHint: '(musíš vyhodnotit kvíz)',
    percentil: 'Percentil:',
    progress: 'Splněno: ',
    bestShown: '(nejlepší výsledek)',
    latestShown: '(nejnovější výsledek)',
    solutions: 'Počet řešitelů:',
    mark: 'Procentuální úspěšnost:',
    noData: 'Vypadá to, že hledaný obsah není dostupný.',
    noDataDetails: 'Zkus prosím aktualizovat stránku. Pokud problém přetrvává, kontaktuj podporu.',
  },
  forgot: {
    title:'Zapomenuté heslo',
    description:
        'Prosím, vyplň svůj e-mail. Kód pro vytvoření nového hesla ti přijde do e-mailu.',
    emailPlaceholder:'Tvůj e-mail',
    btnSubmit:'Obnovit heslo',
    invalidMail: 'Prosím, zadej platnou emailovou adresu.',
    pleaseWait: 'Doručení emailu může trvat i několik minut.',
    unknownError: 'Obnovení hesla selhalo.',
    unknownErrorDetails: 'Prosíme, napiš nám na {{email}}'
  },
  subscriptionScreen: {
    title: 'Studijní plány',
    purchase: 'Koupit',
    subtitle: 'Pro přijímačky',
    contains: 'Předplatné obsahuje:',
    privacy: "zásady ochrany",
    eula: "obchodní podmínky",
    forMonth: "na měsíc",
    forWeek: "na týden",
    forDay: "na den",
    unknownDuration: "pro více informací klikni zde",
    other: 'Další studijní plány',
    noAvailable: 'Pro tento kurz není teď bohužel dostupný žádný studijní plán.\n Je pravděpodobné, že selhala komunikace - prosíme, zkus seznam načíst znovu aktualizací stránky nebo restartováním aplikace. Zkontroluj také, že máš přístup k internetu.',
    restoreAppStore: 'Máš aktivní předplatné v AppStore? Obnovit.'
  },
  products: {
    products: {
      //can contain arbitrary product keys
    },
    any: {
      bullet1: 'Průvodce přípravou: chemie, fyzika a biologie',
      bullet2: 'Stovky řešených úloh z každého předmětu',
      bullet3: 'Testy s vyhodnocením a percentilem',
      bullet4: 'Synchronizace s webovým rozhraním',
    },
  },
  learningScreen: {
    finishCourse:'Ukončit kurz',
    finishCourseAlert: {
      title:'Ukončit kurz',
      description:'Opravdu chceš ukončit tento kurz?',
      cancel:'Zrušit',
      ok:'OK',
    },
    lesson: {
      btnComplete:'Označit za naučené',
      goPractice: 'Přejít na úlohy',
      completed:'Tohle chápu',
      alert: {
        title:'Dokončeno',
        ok:'OK',
        cancel: 'Zpět',
        returnBack: 'Zpět na seznam sekcí'
      },
      submitDo: 'Už to umím',
      submit: 'Opravdu označit?',
      submitMessage: 'Ujisti se, že teorii rozumíš vyřešením několika otázek.',
      noQuizzesAvailable: 'Pro tuto sekci není k dispozici žádná sada otázek.',
    },
    comments: {
      mainHeading: "Komentáře",
    },
    quiz: {
      questionCount:'Počet otázek: {{count}}',
      passingGrade:'Limit pro splnění: {{grade}}%',
      empty:'Bohužel zde nejsou zatím žádné otázky na procvičování.',
      btnStart:'Začít',
      results: 'Vyhodnocení',
      result: {
        title:'Tvůj výsledek',
        failed:
            'Tvůj výsledek je {{result}}% (je požadováno {{grade}})',
        questions:'Otázky',
        correct:'Správně',
        percentil: 'Percentil',
        wrong:'Špatně',
        skipped:'Vynecháno',
        points:'Body celkem',
        negativePoints:'Záporné body',
        timespent:'Strávený čas',
        btnRetake:'Opakovat {{count}}',
        btnReview:'Vyhodnocení',
      },
      submit: 'VYHODNOTIT KVÍZ',
      submitSet: 'VYHODNOTIT SADU',
      timeRemaining:'zbývá',
      correct:'Správně',
      inCorrect:'Špatně',
      point:'{{point}} bodů',
      explanation:'VYSVĚTLENÍ',
      btnCheck:'ODPOVĚDĚT',
      expand:'více',
      hide:'méně',
      correctWas:'Správná odpověd je:',
      correctWere:'Správně bylo: ',
      checkAlert:'Musíš nejdříve vybrat jednu z odpovědí.',
      checkSubmitAlert:'Opravdu chceš vyhodnotit úlohu bez odpovědi?',
      questionAnswered:'Úloha již byla vyřešena.',
      btnFinish:'UKONČIT',
      finishTitle: 'Vyhodnotit kvíz',
      finishDescription: 'Opravdu chceš nechat vyhodnotit tento kvíz? Vyhodnocení resetuje odpovědi u otázek a ukáže celkové hodnocení oddílu.',
      finishTimedDescription: 'Opravdu chceš nechat vyhodnotit tento oddíl?',
      nextQuestion:'DALŠÍ ÚLOHA',
      nextQuestionShort:'DALŠÍ',
      prevQuestionShort:'PŘEDCHOZÍ',
      noNextQuestion:'Kvíz neobsahuje další otázky.',
      hint:'Nápověda',
      hintEmpty:'Nápověda není k dispozici.',
      videoSolution: 'Videořešení',
      percentil: "Ukazuje, kolik procent řešitelů mělo horší nebo stejný výsledek."
    },
    assignment: {
      title:'Úkol',
      acceptAllowed:'Povolené pokusy',
      durations:'Doba trvání',
      passingGrade:'Limit pro splnění',
      point:'{{point}} bod(ů)',
      start:'Start',
      timeRemaining:'Zbývající čas',
      overview:'Přehled:',
      contentTitle:'Proč to používáme?',
      attachmentFile:'Přílohy:',
      answer:'Odpověď:',
      chooseFile:'Vybrat soubory',
      nofile:'Nebyl vybrán soubor',
      chooseFileDescription:
          '(Maximální počet souborů, které můžeš nahrát: 2. Povoleny jsou pouze tyto typy souborů: jpg,txt,zip,pdf,doc,docx,ppt)',
      save:'ULOŽIT',
      send:'ODEVZDAT',
    },
  },
  messages: {
    courseNeedsLogin: 'Pro vstup do kurzu se prosím přihlaš na svůj {{domain}} účet.',
    purchaseNeedsLogin: 'Zakoupení studijního plánu vyžaduje aktivní přihlášení',
    noLongerCourseEligible: 'Tvůj studijní plán vypršel! Pro přístup k úlohám je zapotření aktivní studijní plán pro daný okruh studia.'
  },
  errors: {
    network: {
      title: 'Chyba komunikace s {{domain}}',
      body: 'Prosíme, zkontroluj své internetové připojení a zkus akci znovu.',
      general: 'Chybí internetové připojení...',
      subscription: 'Předplatné nelze načíst. Prosíme, zkontroluj své připojení k internetu.',
      content: 'Obsah nelze načíst. Prosíme, zkontroluj své připojení k internetu.'
    },
    access401: {
      title:'Obsah není dostupný',
      body:'Pokud si myslíš, že se jedná o chybu, napiš prosím na info@scholastik.cz',
    },
    notLoggedIn: {
      title: 'Obsah není dostupný',
      body: 'Akce vyžaduje přihlášený účet.',
      doLogin: 'Přihlášení vypršelo. Prosím, přihlaš se',
      timeout: 'Přihlášení vypršelo.',
      youWereLoggedOut: 'Byl/a jsi odhlášen/a.'
    },
    accessService: {
      title:'Obsah není dostupný',
      body:'Pravděpodobně se jedná o chybu, sdílej prosím chování aplikace přes \'Účet\' nebo napiš na info@scholastik.cz. Děkujeme',
    },
    subscription: {
      title: 'V aplikaci můžeš mít aktivní pouze jedno předplatné.',
      body: 'Pro přístup do kurzů prosím změň typ předplatného pro kategorii ',
      additional: 'Sdílené předplatné můžeš získat na našem webu.',
    },
    missingSubscription: {
      title: 'Po přístup potřebuješ mít aktivní studijní plán.',
      body: 'Předplatné můžeš získat v aplikaci nebo na našem webu.',
    },
    shareLogs: {
      title: 'Zařízení nepodporuje automatické odesílání emailů',
      body: 'Pokud chceš sdílet problémy s aplikací, napiš prosím na info@scholastik.cz. Děkujeme.',
      iosTitle: 'Nelze odeslat email',
      iosBody: 'Prosíme, přihlaš se prvně do aplikace iOS Mail. Poté půjde sdílet popis problému.',
    },
    comments: {
      failedToPost: 'Komentář se nepodařilo odeslat.',
      failedToDelete: 'Komentář se nepodařilo smazat.',
      failedToLoad: 'Komentáře se nepodařilo načíst. {{domain}} může být nedostupné.'
    },
    titleGeneral: 'Došlo k neznámé chybě.',
    titleGeneralSorry: 'Omlouváme se: došlo k neznámé chybě.',
    bodyGeneral: 'můžeš sdílet podrobnosti o chybě s vývojáři přes \'Účet\' nebo napiš na info@scholastik.cz. Děkujeme.',
    dataGeneral: 'Nepodařilo se zobrazit obsah.\nProsíme, zkontroluj připojení. Můžeš také zkusit restartovat aplikaci.',
  },
  comments: {
    leaveCommentHeading: "Přidat nový komentář:",
    loadingCommentsLabel: "Načítám...",
    loggedInUserIntro: "Jsi přihlášen jako ",
    nameInputLabel: "Jméno*:",
    emailInputLabel: "Email*:",
    commentInputLabel: "Komentář:",
    commentInputPlaceholder: "",
    submitButtonLabel: "Odeslat",
    reallyDelete: "Smazat",
    areYouSureDelete: "Chceš opravdu tento komentář smazat?",
    showChildComments: "Zobrazit {{count}} komentářů...",
    cancelLinkLabel: "Zrušit",
    commentsClosedNotice: 'Komentáře jsou uzamčeny',
    reply: 'Odpovědět',
    post: 'Odeslat'
  },
  warnings: {
    startCourse: {
      title: 'Nelze najít aktivní kruz',
      body: 'V poslední době jsi netrénoval/a žádný kruz, a nebo proběhla aktualizace aplikace. Vyber prosím kurz, který chceš trénovat.'
    },
  },
  contactUs: {
    thanks: 'Děkujeme za zpětnou vazbu',
    thanksDetails: 'Pokusíme se problém vyřešit co nejdříve.',
    messageBody: 'Dobrý den, \n\n (',
    subject: 'Aplikace {{platform}} - Podpora',
    buttonTitle: 'Kontaktovat podporu',
    buttonDescription:'Kontaktuj nás na info@oscio.cz, pokud máš nějké problémy. Rádi ti pomůžeme. Sdílením souhlasíš se zpracováním poskytnutých údajů.',
  },
  shareLogs: {
    thanks: 'Děkujeme za zpětnou vazbu',
    thanksDetails: 'Vývojáři se na problém podívají a v případě potřeby tě kontaktují.',
    messageBodyNoError: 'Dobrý den, posílám výpis (log) aplikace (verze je {{version}}, operační systém {{build}}). Výpis posílám protože:',
    messageBodyWithError: 'Dobrý den, aplikace přestala reagovat. Verze aplikace je {{version}}, operačního systému {{build}}. Posílám výpis (log) před pádem aplikace. Popis chyby: [prosíme, sděl nám akce, které vedly k pádu aplikace]',
    subject: 'Aplikace {{platform}}: Logy',
    buttonTitle: 'Sdílet logy aplikace',
    buttonDescriptionNoError:'Aplikace se nechová jak by měla? Sdílej s vývojáři data která pomohou vyřešit tvůj problém. Sdílením souhlasíš se zpracováním poskytnutých údajů.',
    buttonDescriptionWithError: 'Zašli nám, prosíme, na email příčinu chyby, ať to můžeme opravit. Sdílením souhlasíš se zpracováním poskytnutých údajů.',
  },
  shareMistakes: {
    thanks: 'Děkujeme za zpětnou vazbu',
    thanksDetails: 'Případné opravy zaneseme do systému co nejdříve.',
    messageBody: 'Dobrý den, myslím si, že modelová otázka {{name}} obsahuje chybu. [zadej prosím popis chyby]',
    subject: 'Aplikace {{platform}}: Chyba v zadání modelové otázky {{name}}',
    buttonTitle: 'Nahlásit chybu'
  },
};
