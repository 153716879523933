import React, { PureComponent } from 'react';
import {
   Platform,
} from 'react-native';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HomeDetails from './details'
import HomeOverview from "./overview";

class Home extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const category = this.props.cached?.homeShowDetails;
    const { navigation } = this.props;
    if (category) {
      return <HomeDetails navigation={navigation}/>;
    }
    return <HomeOverview navigation={navigation}/>;
  }
}
const mapStateToProps = ({ user, cached }) => ({
  user, cached
});
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home));
