import React from 'react';
import {
  // datetime
  ConvertToDateTime,
  ConvertToDay,
  ConvertToHour,
  formatDateNow,
  ConvertToDate,
  secondsToHms,
  //
  ValidateEmail,
  isURL,
  isVideo,
  ifIphoneX,
  getStatusBarHeight,
  isIphoneX,
  getBottomSpace,
  //
  //
  getQuestionLetter,
  renderHtml,
  Routines,
} from './util';
import Types from "../actions/types";
import NetInfo from "@react-native-community/netinfo";

const safeReadMeta = (from, field, defaultValue='', metaId=0) => {
  return from && from[field] && from[field].length > metaId ?
      from[field][metaId] : defaultValue;
};

const readPropOrThrow = (props, key, contextName, requiredType) => {
  if (!props) {
    throw `${contextName} props must be a valid React.Component.props object!`;
  }
  const stateContext = props[key];
  if (typeof stateContext !== requiredType) {
    console.log((new Error()).stack) //.split("\n")[2].trim().split(" ")[1]

    throw `${contextName} props needs mapped "${key}" property type of: "${requiredType}", got "${typeof stateContext}"!`;
  }
  return stateContext;
};

const readCacheTypeOrThrow = (key, contextName) => {
  const type = Types.CacheName[key];
  if (typeof type !== "string") {
    throw `${contextName}: store "${key}" does not support cache!`;
  }
  return type;
};

/**
 * React component subscribes to internet connection
 *  - it will receive state property 'icReachable' or property name of your choice
 *  - it must unsubscribe (checkConnectionUnbind) on before unmount event
 *  - connectionChanged is called if exists within the context
 * @param context react component
 * @param statePropName name of the state property to set
 * @returns {Promise<object>} connection state
 */
const checkConnectionBind = async (context, statePropName='icReachable') => {
  const result = await NetInfo.fetch();
  if (context.__netinfoListenerUnsubscriber) {
    console.warn("Subscribing to existing connection!");
  }
  context.__netinfoListenerUnsubscriber = NetInfo.addEventListener((state) => {
    if (state.isInternetReachable === null) return; //iOS receives null values at app start

    typeof context.connectionChanged === "function" && context.connectionChanged(state.isInternetReachable, state);
    context.setState({[statePropName] : state.isInternetReachable});
  });
  return result;
};
/**
 * Same as checkConnectionBind, but does not return a promise, instead, it
 * directly sets the state once finished. Returns immediately.
 *  - connectionChanged is called if exists within the context
 * @param context react component
 * @param statePropName name of the state property to set
 */
const asyncCheckConnectionBind = (context, statePropName='icReachable') => {
  checkConnectionBind(context, statePropName).then(state => {
    if (state.isInternetReachable === null) return; //iOS receives null values at app start

    typeof context.connectionChanged === "function" && context.connectionChanged(state.isInternetReachable, state);
    context.setState({[statePropName] : state.isInternetReachable});
  });
};
const checkConnectionUnbind = (context) => {
  context.__netinfoListenerUnsubscriber && context.__netinfoListenerUnsubscriber();
};

/**
 * Asynchronous setstate -> ability to await completion
 */
React.Component.prototype.setStateAsync = function (newState) {
  return new Promise(resolve => this.setState(newState, resolve))
};

export {
  // datetime
  ConvertToDateTime,
  ConvertToDay,
  ConvertToHour,
  formatDateNow,
  ConvertToDate,
  secondsToHms,
  //
  ValidateEmail,
  isURL,
  isVideo,
  ifIphoneX,
  getStatusBarHeight,
  isIphoneX,
  getBottomSpace,
  getQuestionLetter,
  renderHtml,
  //
  Routines,

  safeReadMeta,
  readPropOrThrow,
  readCacheTypeOrThrow,
  checkConnectionBind,
  asyncCheckConnectionBind,
  checkConnectionUnbind
};
