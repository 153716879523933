import React from 'react';
import {View, Image, Dimensions, TouchableOpacity, Platform} from 'react-native';

import { BottomTabItem } from 'app-component';
import { Colors, Images } from 'app-assets';
import i18next from "../common/translations";
import {faSchool} from "@fortawesome/free-solid-svg-icons/faSchool";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons/faPaperclip";
import {faCirclePlay} from "@fortawesome/free-solid-svg-icons/faCirclePlay";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {VALUES} from "../../setup/config";

const deviceWidth = Dimensions.get('window').width;

function renderBottomTabItem(icon, focused, iconStyle={}) {
    return <View style={{paddingVertical: 7}}>
        {focused && (
            <Image
                source={Images.iconTabActive}
                style={{
                    width: deviceWidth / 8,
                    height: 3,
                    position: 'absolute',
                    top: 0,
                    alignSelf: 'center',
                    tintColor: Colors.scholastik.blue,
                }}
                resizeMode="contain"
            />
        )}
        <BottomTabItem.Icon icon={icon} containerStyle={iconStyle}
                            tintColor={focused ? Colors.scholastik.blue : Colors.scholastik.darkGray} />
    </View>;
}

function renderBottomTabText(name, focused) {
    return <BottomTabItem.Title
        name={name}
        tintColor={focused ? Colors.scholastik.blue : Colors.scholastik.darkGray}
    />;
}

function getTabBarStyle(props=undefined) {

    return {
        height: 56 + (props ? props.insets.bottom : 0),
        borderWidth: 0,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        backgroundColor: Colors.scholastik.white,
        borderColor: 'transparent',
        shadowColor: Colors.scholastik.black,
        shadowOffset: {
            width: 0,
            height: -3,
        },
        safeAreaInset:{bottom: 'never', top: 'never'},
        shadowOpacity: 0.4,
        shadowRadius:-1,
        paddingTop: 0,
        right: 0,
        left: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        elevation: 10,
        maxWidth: VALUES.WINDOW_MAX_WIDTH,
        width: '100%'
    };
}

function renderFakeTabBarItem(navigation, focused, icon, name, navigationTarget) {
    return <TouchableOpacity
        onPress={() => {
            navigation.navigate('HomeTabScreen', { screen: navigationTarget });
        }}
        style={{flex:1}}
    >
        <View>
            {
                renderBottomTabItem(icon, focused, {paddingTop: Platform.OS === "web" ? 6 : 13})
            }
            <View style={{
                paddingVertical: Platform.OS === "web" ? 1 : 10
            }}>
                {
                    renderBottomTabText(name, focused)
                }
            </View>
        </View>
    </TouchableOpacity>;
}

//SubjectsRouter / Board / Start / ProfileStackScreen
function renderFakeTabBar(navigation, selectionName="Subjects") {
    return <View style={Platform.OS === "web" ?
        [getTabBarStyle({insets: {bottom: 0, top: 0, right: 0, left: 0}}), {
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 5,
            position: 'fixed',
            bottom: 0,
        }] : [getTabBarStyle({insets: {bottom: 25, top: 0, right: 0, left: 0}}), {
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: 5
        }]
    }>
        {
            renderFakeTabBarItem(navigation, selectionName==="Subjects",
                faSchool, i18next.t('bottomNavigation.subject'), 'Subjects')
        }
        {
            renderFakeTabBarItem(navigation, selectionName==="Board",
                faPaperclip, i18next.t('bottomNavigation.courses'), 'Board')
        }
        {
            renderFakeTabBarItem(navigation, selectionName==="Start",
                faCirclePlay, i18next.t('bottomNavigation.start'), 'Start')
        }
        {
            renderFakeTabBarItem(navigation, selectionName==="ProfileStackScreen",
                faUser, i18next.t('bottomNavigation.profile'), 'ProfileStackScreen')
        }
    </View>;
}

export {
    renderBottomTabItem,
    renderBottomTabText,
    getTabBarStyle,
    renderFakeTabBar
};

