/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import {
    Image,
    Text,
    View,
} from 'react-native';
import {withTranslation} from 'react-i18next';
import styles from './styles';
import {Images, Colors} from 'app-assets';
import {connect} from "react-redux";
import {Routines} from "app-common";
import PlainButton from "../../component/common/plainButton";
import {CommonAppLayout, FullScreenLayout} from "app-component";

class Screen404 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false,
            message: undefined
        };
    }

    componentDidMount() {
        const {user} = this.props,
            uID = user?.info?.id;
        if (uID) {
            Routines.getUserCapabilities(this.props, true, uID).then(caps => {
                console.log("404 refreshed user caps", caps);
            });
        } else {
            console.log("404 Screen User no ID, has token: ", !! user?.token, user?.info);
            this.setState({
                login: true
            })
        }
        this.setState({
           message: this.route?.params?.message
        });
    }

    handleBackPress() {
        this.autoGoHome();
        return true;
    }

    goBack() {
        this.autoGoHome();
    }

    render() {

        const {t} = this.props;

        return (
            <CommonAppLayout
                edges={['right', 'left', 'top']}
                color={Colors.scholastik.background}>
                    <FullScreenLayout>

                        <Image source={Images.bannerCourseDetail} style={styles.banner} />

                        <Text style={styles.title}>{this.props.t('screen404.title')}</Text>

                        <Text style={styles.notAvailableTitle}>
                            {this.state.message || t('screen404.details')}
                        </Text>

                        <View style={{flexDirection: "column", marginBottom: 30, marginTop: 40}}>
                            {
                                this.state.login && <PlainButton
                                    onPress={() => this.autoReplace('LoginScreen')}
                                    title={t('login')}
                                    containerStyle={{backgroundColor: Colors.scholastik.blue}}
                                />
                            }
                            <PlainButton
                                onPress={() => this.autoGoHome()}
                                title={t('back')}
                                containerStyle={{backgroundColor: Colors.scholastik.darkGrayText}}
                            />

                        </View>
                    </FullScreenLayout>
            </CommonAppLayout>);
    }
}

const mapStateToProps = ({user, cached}) => ({
    user, cached
});
const mapDispatchToProps = (dispatch) => ({dispatch});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Screen404));
