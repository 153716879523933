import { StyleSheet } from 'react-native';
import {Colors} from "app-assets";

export default StyleSheet.create({
  container: {
    borderRadius: 14,
    width: 220,
    marginRight: 8,
    height: 95,
    backgroundColor: Colors.scholastik.white,
    padding: 20,
    flexDirection: 'row',
    // marginVertical: 8,
    shadowColor: Colors.scholastik.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 10,
  },
  item: { marginHorizontal: 12, flex: 1 },
  smallContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  image: {
    width: 214,
    height: 134,
    borderRadius: 6,
    resizeMode: 'cover',
    // borderTopLeftRadius: 8,
    // borderTopRightRadius: 8,
  },
  price: {
    fontFamily: 'Poppins-Medium',
    fontSize: 10,
    lineHeight: 15,
    color: '#000',
    fontWeight: '500',
  },
  rate: {
    fontFamily: 'Poppins-Medium',
    fontSize: 10,
    lineHeight: 15,
    color: Colors.scholastik.white,
    fontWeight: '500',
    marginLeft: 3,
    marginTop: 2,
  },
  title: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 24,
    color: '#000',
    fontWeight: '500',
  },
  childTitle: {
    fontFamily: 'Poppins',
    fontSize: 9,
    lineHeight: 13,
    color: '#A8A8A8',
    marginTop: 2,
    marginRight: 10,
  },
  avatar: {
    width: 51,
    height: 51,
    borderRadius: 51 / 2,
    backgroundColor: '#e5e5e5',
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 10,
    resizeMode: 'contain',
    tintColor: '#D2D2D2',
  },
});
