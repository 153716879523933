/**
 * This reducer is not used in the root reducer, but can be used to
 * cache Type.CACHE_PROPERTY - implementation
 * @param state
 * @param action
 * @returns new object state
 */
const handlers = {
    save: (state, action) => {
        if (action.duration <= 0) {
            delete state[action.cacheKey];
            delete state['_mt__' + action.cachedKey];
            return {
                ...state
            };
        }
        return {
            ...state,
            [action.cachedKey]: action.cachedValue,
            ['_mt__' + action.cachedKey]: {
                duration: action.duration,
                tStamp: action.tStamp
            }
        };
    },

    erase: (state, action) => {
        let newState = {...state};
        for (let key in newState) {
            if (!key.startsWith('_mt__')) continue;
            let storeKey = key.substring(8, key.length);
            delete newState[storeKey];
            delete newState[key];
        }
        return newState;
    }
};

export default function (state, action) {
    let handler = handlers[action.commandType];
    if (handler) return handler(state, action);

    //default: return state, todo: somehow warn invalid store
    return state;
};