import AppNavigator from './router';
import {VALUES} from '../../setup/config'
import React from "react";
import {readPropOrThrow} from "../common";
import {CommonActions, StackActions} from "@react-navigation/native";

import {createNavigationContainerRef} from "@react-navigation/native";
export const navigationRef= createNavigationContainerRef();

export const getAppLinker = () => {
     const navigationLinking = {
        prefixes: [
            VALUES.SCHEME + '://' + VALUES.SCHEME_CTX,
            VALUES.DOMAIN_NAME
        ],
        config: {
            screens: {
                HomeTabScreen: {
                    initialRouteName: 'SubjectsStackScreen',
                    screens: {
                        SubjectsStackScreen: {
                            initialRouteName: 'Subjects',
                            screens: {
                                Subjects: {
                                    path: 'kurzy'
                                },
                                CourseStackScreen: {
                                    initialRouteName: 'CoursesDetailsScreen',
                                    screens: {
                                        CoursesDetailsScreen: {
                                            path: 'kurzy/:courseId',
                                            parse: {
                                                courseId: String
                                            }
                                        },
                                        Subscription: {
                                            path: 'studijni-plany'
                                        },
                                        LearningStackScreen: {
                                            initialRouteName: 'LearningScreen',
                                            screens: {
                                                LearningScreen: {
                                                    path: 'kurzy/:courseId/:sectionType/:sectionId?/:questionIndex?',
                                                    //type can be preview, in that case no sectionId exists
                                                    parse: {
                                                        courseId: String,
                                                        sectionType: String,
                                                        sectionId: String,
                                                        questionIndex: Number
                                                    }
                                                },
                                            }
                                        },
                                    }
                                },

                            }
                        },
                        Board: {
                            path: 'nastenka/:category?',
                            parse: {
                                category: String,
                            }
                        },
                        Start: {
                            path: 'start'
                        },
                        ProfileStackScreen: {
                            initialRouteName: 'ProfileScreen',
                            screens: {
                                ProfileScreen: {
                                    path: 'uzivatel/ucet'
                                },
                                SettingsScreen: {
                                    path: 'uzivatel/nastaveni'
                                },
                                LoginScreen: {
                                    // 1:1 mapping to learnpress token API
                                    // path: 'third-party-login/:status?/:token?/:user_id?/:user_login?/:user_email?/:user_display_name?',
                                    path: 'prihlaseni'
                                },
                                RegisterScreen: {
                                    path: 'registrace'
                                },
                                ForgotScreen: {
                                    path: 'zapomenute-udaje'
                                },
                            }
                        },

                    },
                },
                WebScreen: {
                    path: 'w/:url',
                    parse: {
                        url: String
                    }
                },
                Screen404: {
                    path: '404',
                },
            },
        },
    };

    React.Component.prototype.findNavigationPath = function (screenName, params) {
        function iterate(root, name) {
            if (name === screenName) {
                return {
                    screen: name,
                    params: params
                }
            }

            if (typeof root.screens === "object") {
                for (let sId in root.screens) {
                    const result = iterate(root.screens[sId], sId);
                    if (result) {
                        return {
                            screen: name,
                            params: result
                        };
                    }
                }
            }
            return false;
        }

        const path = iterate(navigationLinking.config, undefined);
        if (path?.params?.screen) {
            return path.params;
        }
        return undefined;
    };

    /**
     * Ability to detect navigation scheme automatically, isntead of retarded nest lists
     */
    React.Component.prototype.autoNavigate = function (screenName, params={}) {
        const navigation = readPropOrThrow(this.props, 'navigation', '::auto::navigation', 'object');
        const path = this.findNavigationPath(screenName, params);
        if (path?.screen) {
            navigation.navigate(path.screen, path.params);
            return true;
        }
        console.warn('Automated navigation failed! Path', path, "Target", screenName, params);
        return false;
    };

    React.Component.prototype.autoReplace = function (screenName, params={}) {
        const navigation = readPropOrThrow(this.props, 'navigation', '::auto::replace', 'object');
        const path = this.findNavigationPath(screenName, params);
        if (path?.screen) {
            navigation.replace(path.screen, path.params);
            return true;
        }
        console.warn('Automated nav-replace failed! Path', path, "Target", screenName, params);
        return false;
    };

    React.Component.prototype.autoGoHome = function () {
        const navigation = readPropOrThrow(this.props, 'navigation', '::auto::replace', 'object');
        navigation.dispatch(CommonActions.reset({
            index: 1,
            routes: [
                { name: 'HomeTabScreen' }
            ],
        }));
    };

    React.Component.prototype.autoGoBack = function () {
        const navigation = readPropOrThrow(this.props, 'navigation', '::auto::replace', 'object');
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            console.warn("Could not go back! Going home.", navigation);
            this.autoGoHome()
        }
    };

    return navigationLinking;
}

export function mockAutoNavigation(navigation, navigationType, ...args) {
    //mock react component props.navigation
    React.Component.prototype[navigationType].apply(
        {props: {navigation}, findNavigationPath: React.Component.prototype.findNavigationPath}, args);
}

export default AppNavigator;
