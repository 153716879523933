import Types from '../actions/types';
import sharedCacheReducer from './sharedCacheReducer'

const failed = (state = {}, action) => {
  switch (action.type) {
    case Types.CacheName.failed:
      delete action.type;
      return sharedCacheReducer(state, action);
    default:
      return state;
  }
};

export default failed;
